export function add_Cart_To_LocalStorage(identifier, cartArray, id) {
  let localData = JSON.parse(localStorage.getItem("openOrders"));

  if (localData === null) {
    localData = {};
  }
  //   cartArray = cartArray.map((cart) => (cart.identifier = identifier));
  for (let i = 0; i < cartArray.length; i++) {
    cartArray[i].identifier = identifier;
  }

  localData[id] = cartArray;

  localStorage.setItem("openOrders", JSON.stringify(localData));
}

export function get_local_order() {
  if (localStorage.getItem("openOrders")) {
    let local_order = JSON.parse(localStorage.getItem("openOrders"));
    return local_order;
  }
}

export function delete_Cart_From_LocalStorage(id) {
  let localData = JSON.parse(localStorage.getItem("openOrders"));
  delete localData[id];

  localStorage.setItem("openOrders", JSON.stringify(localData));
  return localData;
}
