import { Close, LocalOffer, Logout, Search } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Typography, Button, ButtonGroup, Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { Modal as MaterialModal, Input } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import POSCartItem from "../components/POSCartItem";
import POSCheckoutModal from "./POSCheckoutModal";
import POSItem from "../components/POSItem";
import { fetchPOSMenu } from "../redux/actions/pos";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import POSModal from "../components/POSModal";
import OpenOrders from "../components/OpenOrders";
import {
  add_Cart_To_LocalStorage,
  delete_Cart_From_LocalStorage,
  get_local_order,
} from "../helperFunctions/localStorage";
import CancelIcon from "@mui/icons-material/Cancel";
import "./POSPage.css";
import {
  createOrder,
  editOpenOrder,
  getOrderById,
} from "../redux/actions/orders";
import Invoice from "../components/Invoice/Invoice";
import KOT from "../components/KOT/KOT";
import { updateStatus } from "../redux/actions/orders";
import { order_status } from "../constants/orderStatus";
import { checkRoute } from "../helperFunctions/routeHandler";
import { useTranslation } from "react-i18next";
import CustomerDetailModal from "../components/CustomerDetailModal";

import { fetchAreas } from "../redux/actions/addressInfo";
import { getBusinessConfig } from "../redux/actions/businessConfig";
import { useAlert } from "react-alert";
import { DELETE_INVOICE } from "../redux/constants";
import { showPrice } from "../config/currency";
import useWindowSize from "../helperFunctions/useWindowSize";

function POSPage() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  let navigate = useNavigate();

  const [cart, setCart] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  console.log("deliveryCharges", deliveryCharges);
  const [orderTotal, setOrderTotal] = useState(0);
  const [showCheckout, setShowCheckout] = useState(false);
  const [createOrderId, setCreateOrderId] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [showKOT, setShowKOT] = useState(false);
  const [showOpenOrderModal, setShowOpenOrderModal] = useState(false);
  const [isModalClose, setIsModalClose] = useState(false);
  const [openOrderModalId, setOpenOrderModalId] = useState("");
  const [loading, setLoading] = useState({ type: "", loading: false });
  const [selectedCategory, setSelectedCategory] = useState("All");

  const pos_menu = useSelector((state) => state.posMenuReducer.pos_menu);
  const business_config = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  const invoiceData = useSelector((state) => state.invoiceReducer.invoice);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [localOrder, setLocalOrder] = useState({});
  const [selectLocalId, setSelectLocalId] = useState(null);
  const [selectLocalIdentifier, setSelectLocalIdentifier] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [openSearch, setOpenSearch] = useState("");
  const [searchItems, setSearchItems] = useState("");
  const [select_typeId, setSelected_typeId] = useState(null);
  const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);

  //Delivery Modal State
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contact, setContact] = useState(null);
  const [email, setEmail] = useState(null);
  const [city, setCity] = useState(null);
  const [area, setArea] = useState(null);
  const [completeAddress, setCompleteAddress] = useState(null);
  const [deliverOrderLoading, setDeliverOrderLoading] = useState(false);
  const [generateKOT, setGenerateKOT] = useState(true);
  const [checkBox, setCheckbox] = useState([]);
  const [radio, setRadio] = useState([]);
  const [itemQuantity, setQuantity] = useState(1);
  const [totalCost, setTotalCost] = useState(0);
  const [orderTotal_tax, setOrderTotal_tax] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [instruction, setInstruction] = useState("");
  const [totalTax, setTotalTax] = useState(0);
  const [taxDetail, setTaxDetail] = useState([]);
  const [isTakeAway, setIsTakeAway] = useState(false);
  const [isDineIn, setIsDineIn] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);
  const size = useWindowSize();
  const boxSize = size.width / 11;

  const alert = useAlert();

  const verifyEmail = (email) => {
    const regx = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return regx.test(email);
  };

  const clearState = () => {
    setCart([]);
    setSubtotal(0);
    setOrderTotal(0);
    setTotalTax(0);
  };

  const setCartToLocalStorage = (cartForLocalStorage) => {
    sessionStorage.setItem("cart", JSON.stringify(cartForLocalStorage));
  };

  const onSelectValue = (val) => {
    if (val.q_type === "checkbox") {
      // console.log("On Select Check Box: ", val);
      const copyData = Array.from(checkBox);
      const index = copyData.findIndex((a) => a.option_id === val.option_id);
      if (index === -1) {
        copyData.push(val);
      } else {
        copyData.splice(index, 1);
      }
      setCheckbox(copyData);
    }
  };

  const onSelectRadio = (val) => {
    // console.log("OnSelectRadio: ", val);
    if (val.q_type === "select") {
      let copyData = Array.from(radio);
      copyData = copyData.filter((data) => data.q_id !== val.q_id);
      copyData.push(val);
      setRadio(copyData);
    }
  };

  const selected = (val) => {
    i18n.changeLanguage(val);
  };

  async function fetchAreaInfo() {
    if (business_config) {
      await dispatch(fetchAreas(business_config?.username));
    } else {
      await dispatch(getBusinessConfig());
    }
  }

  useEffect(() => {
    try {
      dispatch(fetchPOSMenu());
      dispatch({ type: DELETE_INVOICE });
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");
      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Something went wrong!", {
          type: "error",
        });
      }
    }
  }, []);
  useEffect(() => {
    checkRoute(dispatch);
    fetchAreaInfo();
  }, [business_config]);

  useEffect(() => {
    // console.log("--------------", pos_menu);
    setMenuLoading(true);
    let selectedOrder = pos_menu?.filter(
      (menu) => menu.category === selectedCategory
    )[0];
    let tax_obj;
    if (selectedOrder === undefined) {
      tax_obj = {
        title: pos_menu[1]?.category_tax_name,
        percentage: pos_menu[1]?.category_tax_rate,
      };
    } else {
      tax_obj = {
        title: selectedOrder?.category_tax_name,
        percentage: selectedOrder?.category_tax_rate,
      };
    }

    const condition = pos_menu?.filter((a) => a.category === selectedCategory);
    if (condition.length > 0) {
      setMenuData(condition);
      setMenuLoading(false);
    } else {
      setMenuData(pos_menu);
      setMenuLoading(false);
    }
    // console.log("Set Taxes UseEffect: ", Array.from([tax_obj]));
    setTaxes(Array.from([tax_obj]));
  }, [selectedCategory, pos_menu]);

  useEffect(() => {
    setMenuLoading(true);

    setMenuData(pos_menu);
    setMenuLoading(false);
  }, [pos_menu]);

  useEffect(() => {
    if (showCheckout === false && !!Object.values(invoiceData).length) {
      setShowInvoice(true);
    }
  }, [showCheckout]);

  useEffect(() => {
    if (localStorage.getItem("openOrders")) {
      setLocalOrder(JSON.parse(localStorage.getItem("openOrders")));
    }
  }, []);

  const setLocalItemToCart = (cart, id) => {
    calculateCartTotal(cart);
    setCart(cart);
    setSelectLocalIdentifier(cart[0].identifier);
    setSelectLocalId(id);
  };

  const calculateTaxTotal = (itemCart, optPrice) => {
    let totalTax = 0;
    let tempNames = [];
    let taxDetail = [];
    let count = {};
    let totalPrice = 0;
    itemCart.map((item) => {
      totalPrice += (item.item_price * item.item_quantity * item.taxRate) / 100;
      item.options.map((option) => {
        totalPrice +=
          (option.option_price * item.item_quantity * item.taxRate) / 100;
      });
      tempNames.push({ name: item.taxName, rate: item.taxRate });
    });
    for (let i = 0; i < tempNames.length; i++) {
      let obj = JSON.stringify(tempNames[i]);
      if (!count[obj]) {
        count[obj] = 1;
        taxDetail.push(tempNames[i]);
      } else {
        count[obj]++;
      }
    }
    setTotalTax(Math.ceil(totalTax));
    setTaxDetail(taxDetail);
  };
  const calculateCartTotal = (itemCart) => {
    console.log("itemCart", itemCart);
    let item_Total = 0;
    let subtotalAmount = 0;
    let actualTotal = 0;
    let discountedTotal = 0;
    let optPrice = 0;
    for (const item of itemCart) {
      item_Total += item.item_quantity;
      // actualTotal += item.item_quantity;
      // -------- For Discounted ---------
      actualTotal += parseInt(item.item_quantity) * parseFloat(item.item_price);
      item.options.forEach((op) => {
        actualTotal +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
        optPrice += parseInt(item.item_quantity) * parseFloat(op.option_price);
      });

      discountedTotal +=
        parseInt(item.item_quantity) * parseFloat(item.discounted_price);
      item.options.forEach((op) => {
        discountedTotal +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
      });

      subtotalAmount +=
        parseInt(item.item_quantity) * parseFloat(item.discounted_price);
      item.options.forEach((op) => {
        subtotalAmount +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
      });
    }
    calculateTaxTotal(itemCart, optPrice);
    setTotalCost(subtotalAmount);
    setActualPrice(actualTotal);
    setDiscountedPrice(discountedTotal);
    let taxesSum = 0.0;
    // //Taxes calculcation
    // console.log("Taxes --------", taxes);
    let calculatedTaxes = taxes.length && taxes;
    // console.log("Tax 618 ----", calculatedTaxes);
    for (let i = 0; i < calculatedTaxes.length; i++) {
      calculatedTaxes[i].taxAmount = parseFloat(
        actualTotal * (parseFloat(calculatedTaxes[i].percentage) / 100)
      ).toFixed(2);
      taxesSum =
        parseFloat(taxesSum) +
        parseFloat(
          actualTotal * (parseFloat(calculatedTaxes[i].percentage) / 100)
        );
    }
    setTaxes(JSON.parse(JSON.stringify(calculatedTaxes)));
    setTotalQuantity(item_Total);
    console.log(
      "subtotalAmount",
      subtotalAmount,
      "typof",
      typeof subtotalAmount
    );
    setOrderTotal(subtotalAmount);

    setTimeout(() => {
      setRadio([]);
      setCheckbox([]);
    }, 10);
  };

  const addCartToItems = (item, error) => {
    // let requiredCheck = false;
    // let itemMatched = false;
    // let obj = {};
    // let currentCart = cart;
    // let currentItem = item;
    // let cartCopy;
    // // ------- Handle Required --------- //
    // console.log("Current Item: ", currentItem);
    // currentItem.questions.map((question) => {
    //   if (question.is_required) {
    //     obj[question.question_id] = false;
    //     question.options.map((option) => {
    //       if (
    //         currentItem.options.some(
    //           (item) => item.option_id === option.option_id
    //         )
    //       ) {
    //         obj[question.question_id] = true;
    //       }
    //     });
    //   }
    // });
    // console.log("Object to validate: ", obj);
    // requiredCheck = Object.values(obj).every((v) => v === true);
    // // console.log("Required Check: ", requiredCheck);
    // if (requiredCheck) {
    //   // ------- Sort Options by Option Id --------
    //   currentItem?.options?.sort(function (b, a) {
    //     return -(a.option_id - b.option_id);
    //   });
    //   // ----- Create A unique id using itemid & optionid -----
    //   let itemUniqueId = currentItem.item_id.toString();
    //   currentItem.options.forEach((value) => {
    //     itemUniqueId += value.option_id;
    //   });
    //   // console.log("ItemUinqueID: ", itemUniqueId);
    //   // --------- Assign unique Id to current item -----
    //   currentItem = { ...currentItem, itemUniqueId: itemUniqueId };
    //   const newCart = currentCart?.map((value) => {
    //     if (value.itemUniqueId === currentItem.itemUniqueId) {
    //       // console.log("**** Item Id Matched ******  ");
    //       itemMatched = true;
    //       return {
    //         ...value,
    //         item_quantity: value.item_quantity + item.item_quantity,
    //       };
    //     } else {
    //       return { ...value };
    //     }
    //   });
    //   if (!itemMatched) {
    //     newCart.push(currentItem);
    //   }
    //   // currentCart.push(currentItem);
    //   currentCart = [...newCart];
    //   cartCopy = currentCart;
    //   calculateCartTotal(cartCopy);
    //   // cartCalculator(currentCart);
    //   // setCartToLocalStorage(currentCart);
    //   // cart_handler(JSON.parse(JSON.stringify(currentCart)));
    //   // ModalClose();
    // } else {
    //   // ------
    //   console.log("please Select required Question Important");
    //   error(true);
    // }
  };

  const removeCartItem = (index) => {
    let cartCopy = cart;
    cartCopy.splice(index, 1);
    calculateCartTotal(cartCopy);
  };

  const increaseQuantity = (index) => {
    // let cartCopy = cart;
    // cartCopy[index] = {
    //   ...cartCopy[index],
    //   item_quantity: cartCopy[index].item_quantity + 1,
    // };
    // calculateCartTotal(cartCopy);
    let currentCart = cart;
    currentCart[index].item_quantity = currentCart[index].item_quantity + 1;
    setCartToLocalStorage(currentCart);
    calculateCartTotal(currentCart);
    setCart(JSON.parse(JSON.stringify(currentCart)));
  };

  const decreaseQuantity = (index) => {
    // let cartCopy = cart;
    // if (cartCopy[index].item_quantity > 1) {
    //   cartCopy[index] = {
    //     ...cartCopy[index],
    //     item_quantity: cartCopy[index].item_quantity - 1,
    //   };
    //   calculateCartTotal(cartCopy);
    // }
    let currentCart = cart;
    if (currentCart[index].item_quantity > 1) {
      currentCart[index].item_quantity = currentCart[index].item_quantity - 1;
      calculateCartTotal(currentCart);
      setCartToLocalStorage(currentCart);
      setCart(JSON.parse(JSON.stringify(currentCart)));
    } else {
      setCartToLocalStorage(currentCart);
      calculateCartTotal(currentCart);
      setCart(JSON.parse(JSON.stringify(currentCart)));
    }
  };

  //Create Order
  const createOrderHandler = async (type_id, type) => {
    if (cart.length !== 0) {
      if (type === "takeAway") {
        setIsDineIn(false);
        setIsTakeAway(true);
      } else {
        setIsDineIn(true);
        setIsTakeAway(false);
      }
      setLoading(JSON.parse(JSON.stringify({ type: type, loading: true })));
      const obj = {
        customer_data: {
          customer_id: 1,
        },
        order_master: {
          type_id: type_id,
          business_username: business_config.username,
          order_items: cart.map((item) => ({
            menu_item_id: item.item_id,
            item_quantity: item.item_quantity,
            instruction: item.instruction
              ? item.instruction
              : "No specific instruction",
            ...(!!item?.options.length && {
              question_options_ids: item.options.map((op) => op.option_id),
            }),
          })),
        },
      };

      try {
        let res = await dispatch(createOrder(obj));
        let status_res = await dispatch(
          updateStatus({
            order_id: res.data.payload.order_id,
            order_status: { name: "Delivered", id: order_status["DELIVERED"] },
          })
        );
        setCreateOrderId(res.data.payload.order_id);
        setShowCheckout(true);
        setShowKOT(true);
        setLoading(JSON.parse(JSON.stringify({ type: "", loading: true })));
        setIsDineIn(false);
        setIsTakeAway(false);
        // setTimeout(() => {
        //   if (selectLocalId) {
        //     let data = delete_Cart_From_LocalStorage(selectLocalId);
        //     // console.log("Data: 368 ", data);
        //     setLocalOrder(JSON.parse(JSON.stringify(data)));
        //     setSelectLocalId(null);
        //     setSelectLocalIdentifier(null);
        //     setCart([]);
        //     setOrderTotal(0);
        //   }
        // }, 2500);
        alert.show("Order Created Successfully!", {
          type: "success",
        });
      } catch (error) {
        setShowCheckout(false);
        setIsDineIn(false);
        setIsTakeAway(false);
        setCreateOrderId("");
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong!", {
            type: "error",
          });
        }
      }
    } else {
      setLoading(JSON.parse(JSON.stringify({ type: "", loading: false })));
      alert.show("Add Something to Cart first!", {
        type: "error",
      });
    }
  };

  const delilverOrderHandler = async (type_id) => {
    if (!deliverOrderLoading) {
      setDeliverOrderLoading(true);
      const obj = {
        customer_data: {
          email: email,
          contact_no: contact.toString(),
          name: `${firstName} ${lastName}`,
        },
        order_master: {
          type_id: type_id,
          area: area,
          complete_address: completeAddress,
          business_username: business_config.username,
          order_items: cart.map((item) => ({
            menu_item_id: item.item_id,
            item_quantity: item.item_quantity,
            instruction: item.instruction
              ? item.instruction
              : "No specific instruction",
            ...(!!item?.options.length && {
              question_options_ids: item.options.map((op) => op.option_id),
            }),
          })),
        },
      };

      try {
        let res = await dispatch(createOrder(obj));
        let status_res = await dispatch(
          updateStatus({
            order_id: res.data.payload.order_id,
            order_status: { name: "Delivered", id: order_status["DELIVERED"] },
          })
        );
        let response = await dispatch(
          getOrderById(res?.data?.payload?.order_id)
        );
        setDeliveryCharges(response?.data?.payload?.delivery_charges);
        setCreateOrderId(res.data.payload.order_id);
        clearDetailModalState();
        setShowCustomerDetailModal(false);
        setShowCheckout(true);
        if (generateKOT) {
          setShowKOT(true);
        }
        setDeliverOrderLoading(false);
        setTimeout(() => {
          if (selectLocalId) {
            let data = delete_Cart_From_LocalStorage(selectLocalId);
            // console.log("Data: 368 ", data);
            setLocalOrder(JSON.parse(JSON.stringify(data)));
            setSelectLocalId(null);
            setSelectLocalIdentifier(null);
            setCart([]);
            setOrderTotal(0);
            setTotalTax(0);
          }
        }, 2500);
        alert.show("Order Created Successfully!", {
          type: "success",
        });
      } catch (error) {
        setShowCheckout(false);
        setCreateOrderId("");
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");
        setDeliverOrderLoading(false);

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong!", {
            type: "error",
          });
        }
      }
    }
  };

  const editOpenOrderHandler = async () => {
    if (cart.length !== 0) {
      setIsTakeAway(false);
      setIsDineIn(false);
      const obj = {
        order_id: selectLocalId,
        order_items: cart.map((item) => ({
          menu_item_id: item.item_id,
          item_quantity: item.item_quantity,
          instruction: item.instruction
            ? item.instruction
            : "No specific instruction.",
          ...(!!item?.options.length && {
            question_options_ids: item.options.map((op) => op.option_id),
          }),
        })),
      };
      try {
        let res = await dispatch(editOpenOrder(obj));
        add_Cart_To_LocalStorage(selectLocalIdentifier, cart, selectLocalId);
        setCreateOrderId(createOrderId);
        if (generateKOT) {
          setShowKOT(true);
        }
        setLocalOrder(get_local_order());
        setTimeout(() => {
          setCart([]);
          setSubtotal(0);
          setOrderTotal(0);
          setIsModalClose(false);
          setTotalTax(0);

          setSelected_typeId(null);
        }, 2500);
        setSelectLocalIdentifier(null);
        setSelectLocalId(null);
        alert.show("Order Created Successfully!", {
          type: "success",
        });
      } catch (error) {
        setCreateOrderId("");
        setIsModalClose(false);
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong!", {
            type: "error",
          });
        }
      }
    } else {
      alert.show("Add Something to Cart first!", {
        type: "error",
      });
    }
  };

  const addOrderToOpenHandler = async (order) => {
    console.log("cart651", cart);
    setIsModalClose(true);
    if (cart.length !== 0) {
      // Creating Order On Backend
      const obj = {
        customer_data: {
          customer_id: 1,
        },
        order_master: {
          type_id: 3,
          business_username: business_config.username,
          order_items: cart.map((item) => ({
            menu_item_id: item.item_id,
            item_quantity: item.item_quantity,
            instruction: item.instruction
              ? item.instruction
              : "No specific instruction",
            ...(!!item?.options.length && {
              question_options_ids: item.options.map((op) => op.option_id),
            }),
          })),
        },
      };
      try {
        let res = await dispatch(createOrder(obj));
        let status_res = await dispatch(
          updateStatus({
            order_id: res.data.payload.order_id,
            order_status: { name: "Preparing", id: order_status["PREPARING"] },
          })
        );

        add_Cart_To_LocalStorage(
          openOrderModalId,
          order,
          res.data.payload.order_id
        );
        setLocalOrder(get_local_order());
        setShowOpenOrderModal(false);
        setOpenOrderModalId("");
        setCreateOrderId(res.data.payload.order_id);
        if (generateKOT) {
          setShowKOT(true);
        }
        setTimeout(() => {
          setCart([]);
          setSubtotal(0);
          setOrderTotal(0);
          setTotalTax(0);
          setIsModalClose(false);
          setSelected_typeId(null);
        }, 2500);
        alert.show("Order Created Successfully!", {
          type: "success",
        });
      } catch (error) {
        setIsModalClose(false);
        setCreateOrderId("");
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong!", {
            type: "error",
          });
        }
      }
    } else {
      alert.show("Add Something to Cart first!", {
        type: "error",
      });
    }
  };

  const modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "35px",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const clearDetailModalState = () => {
    setFirstName(null);
    setLastName(null);
    setContact(null);
    setEmail(null);
    setCity(null);
    setArea(null);
    setCompleteAddress(null);
  };

  // console.log("subtotal", subtotal);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {showOpenOrderModal && (
        <MaterialModal
          open={showOpenOrderModal}
          onClose={() => {
            setIsModalClose(false);
            setShowOpenOrderModal(false);
          }}
        >
          <Box sx={modal_style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Set Order Identifier!
            </Typography>
            <Input
              value={openOrderModalId}
              onChange={(e) => {
                setOpenOrderModalId(e.target.value);
              }}
            />
            <br />
            <Button
              variant="contained"
              style={{ marginTop: "20px", backgroundColor: "#A3181B" }}
              onClick={() => {
                setSelected_typeId(3);
                addOrderToOpenHandler(cart);
              }}
              disabled={
                openOrderModalId.trim().length === 0
                  ? true
                  : false || select_typeId !== null
              }
            >
              Add Open Order
            </Button>
          </Box>
        </MaterialModal>
      )}
      {showCheckout && (
        <POSCheckoutModal
          cart={cart}
          subtotal={totalCost}
          orderTotal={orderTotal}
          discount={discountedPrice}
          actualPrice={actualPrice}
          taxes={taxes}
          totalTax={totalTax}
          taxDetail={taxDetail}
          deliveryCharges={deliveryCharges}
          show={showCheckout}
          onClose_Invoice={() => {
            setShowCheckout(!showCheckout);
            clearState();
            setCreateOrderId("");
          }}
          onClose={() => {
            setShowCheckout(!showCheckout);
            setSelected_typeId(null);
            setCart([]);
            setSubtotal(0);
            setOrderTotal(0);
            setTotalTax(0);

            setSelected_typeId(null);
            dispatch({ type: DELETE_INVOICE });
          }}
          order_id={createOrderId}
        />
      )}
      {showCustomerDetailModal && (
        <CustomerDetailModal
          show={showCustomerDetailModal}
          onClose={() => {
            setShowCustomerDetailModal(false);
            clearDetailModalState();
          }}
          onSubmit={(e) => {
            let fieldVerification = true;
            let fields = [
              firstName,
              lastName,
              contact,
              email,
              city,
              area,
              completeAddress,
            ];
            for (const field of fields) {
              console.log("Fields loop for condition", field);
              if (field === null || field === "") {
                window.alert("All Fields must be filled");
                fieldVerification = false;
                break;
              }
            }

            if (fieldVerification) {
              if (verifyEmail(email)) {
                delilverOrderHandler(1);
              } else {
                window.alert("Invalid Email Syntax");
              }
            }
          }}
          firstName={firstName}
          lastName={lastName}
          contact={contact}
          city={city}
          area={area}
          email={email}
          completeAddress={completeAddress}
          setFirstName={(value) => setFirstName(value)}
          setLastName={(value) => setLastName(value)}
          setContact={(value) => setContact(value)}
          setCity={(value) => setCity(value)}
          setArea={(value) => setArea(value)}
          setEmail={(value) => setEmail(value)}
          setCompleteAddress={(value) => setCompleteAddress(value)}
          loading={deliverOrderLoading}
        />
      )}
      {showInvoice && !!Object.values(invoiceData).length && (
        <MaterialModal
          open={showInvoice}
          onClose={() => {
            setShowInvoice(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{ overflow: "scroll" }}
          keyboard={false}
        >
          <Box sx={{ ...modal_style, width: 400, marginTop: "60px" }}>
            <Invoice
              onClose_handler={() => {
                setShowInvoice(false);
              }}
              taxDetail={taxDetail}
            />
          </Box>
        </MaterialModal>
      )}
      {showKOT && (
        <MaterialModal
          open={showKOT}
          onClose={() => {
            setShowKOT(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{ overflow: "scroll" }}
          keyboard={false}
        >
          <Box
            sx={{ ...modal_style, width: 400, marginTop: "60px", opacity: 0 }}
          >
            <KOT
              cart={cart}
              onClose={() => {
                setShowKOT(false);
              }}
              type_id={select_typeId}
              revised={selectLocalId ? true : false}
              order_id={createOrderId}
            />
          </Box>
        </MaterialModal>
      )}
      {open && (
        <POSModal
          // show={open}
          // item={selectedItem}
          // onSubmit={(item, error) => {
          //   addCartToItems(item, error);
          //   setOpen(false);
          // }}
          // onClose={() => {
          //   setOpen(false);
          //   setSelectedItem(null);
          // }}
          ModalClose={() => {
            setOpen(false);
            setQuantity(1);
            setSelectedItem(null);
            setCheckbox([]);
            setRadio([]);
          }}
          showModal={open}
          selected_item={selectedItem}
          select_value_handler={onSelectValue}
          select_radio_handler={onSelectRadio}
          checkbox_state={checkBox}
          radio_state={radio}
          item_quantity_state={itemQuantity}
          item_quantity_handler={setQuantity}
          cartCalculator={calculateCartTotal}
          cart_handler={setCart}
          cart_state={cart}
          setCartToLocalStorage={setCartToLocalStorage}
          instruction={instruction}
          setInstruction={setInstruction}
        />
      )}
      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* ---------- Back To Admin ---------- */}
        <div
          className="pos-header-buttons"
          style={{
            height: 30,
            // border: "solid white 1px",
            // borderRadius: 15,
            paddingLeft: 0,
            paddingRight: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            // margin: "0 10px",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <Typography
            variant="body1"
            style={{
              color: "white",
              padding: 5,
              paddingRight: 2,
            }}
          >
            <ArrowBackIcon style={{ marginRight: 5 }} />
            {t("pos:backToAdmin")}
          </Typography>
        </div>

        {/* ---------- Company Logo ---------- */}
        <div>
          <div style={{ flex: 1 }}>
            <img
              style={{
                height: 40,
                width: 40,
                borderRadius: 100 / 2,
                objectFit: "fill",
                marginBottom: 8,
              }}
              src={`${
                business_config?.images?.logo?.[0]
              }?${new Date().getTime()}`}
              alt="business-logo"
            />
          </div>
        </div>

        {/* ---------- DROPdoWN And Logout  ---------- */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="header-dropdown"
            style={{ height: 50, padding: "0 8px" }}
          >
            {/* <LanguageIcon style={{ color: "#fff" }} /> */}

            <select
              onChange={(val) => selected(val.target.value)}
              className="language-dropdown"
              value={i18n.resolvedLanguage}
              // style={{ padding: 5, marginRight: 50, marginBottom: 10 }}
            >
              <option value="en" style={{ fontSize: 18 }}>
                English
              </option>
              <option value="de" style={{ fontSize: 18 }}>
                German
              </option>
              <option value="ur" style={{ fontSize: 18 }}>
                Urdu
              </option>
            </select>
          </div>
          <div
            className="pos-header-buttons"
            style={{
              height: 30,
              // border: "solid white 1px",
              // borderRadius: 15,
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              margin: "0 10px",
            }}
            onClick={() => {
              dispatch({ type: "REMOVE_TOKEN" });
              navigate("/");
            }}
          >
            <Logout sx={{ color: "white", fontSize: 19 }} />
            <Typography
              variant="body1"
              style={{ color: "white", padding: 5, paddingRight: 0 }}
            >
              {t("pos:logout")}
            </Typography>
          </div>
        </div>
      </div>
      {/*   MAIN CONTAINER  */}
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          // height: 0,
          flexDirection: "row",
          display: "flex",
          height: "100%",
        }}
      >
        <div
          style={{
            flex: 0.15,
            borderLeft: "1px solid",
            boxShadow: "0px 1px 5px #888888",
            // height: '100%',
            // height: "calc(100% - 50px)",
            height: "calc(100vh - 50px)",
          }}
        >
          <div
            style={{
              height: 30,
              border: "solid #A3181B 0.7px",
              // borderRadius: 15,
              paddingLeft: 5,
              paddingRight: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "15px",
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: 20,
            }}
          >
            <Search sx={{ color: "#A3181B" }} />
            <input
              value={searchCategory}
              placeholder={t("pos:searchCategory")}
              style={{
                backgroundColor: "#fff",
                outline: "none",
                border: 0,
                fontSize: 13,
                flex: 1,
                color: "#000",
                paddingLeft: 10,
                // marginBottom: 10,
              }}
              onChange={(e) => {
                setSearchCategory(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              flexGrow: 1,
              height: "45%",
              padding: "15px",
              paddingTop: 0,
              overflowY: "scroll",
              // height: 300,
            }}
          >
            <div
              style={{
                border: "1px solid",
                padding: "10px",
                boxShadow: "2px 2px 8px 0.5px #d3d3d3",
                padding: 5,
                // marginTop: 15,
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
                borderColor: selectedCategory === "All" ? "#A3181B" : "#fff",
                backgroundColor:
                  selectedCategory === "All" ? "#A3181B" : "#fff",
              }}
              onClick={() => {
                setMenuLoading(true);
                setSelectedCategory("All");
                setMenuLoading(false);
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  color: selectedCategory === "All" ? "#fff" : "#000",
                  letterSpacing: 1,
                }}
              >
                {t("pos:all")}
              </span>
            </div>

            {pos_menu.length
              ? pos_menu
                  .filter(
                    (a) =>
                      a.category
                        .toLowerCase()
                        .indexOf(searchCategory.toLowerCase()) !== -1
                  )
                  .map((menu, index) => {
                    if (menu.menu_items.length !== 0) {
                      return (
                        <div
                          style={{
                            border: "1px solid",
                            padding: "10px",
                            boxShadow: "2px 2px 8px 0.5px #d3d3d3",
                            padding: 5,
                            marginTop: 15,
                            justifyContent: "center",
                            display: "flex",
                            cursor: "pointer",
                            borderColor:
                              selectedCategory === menu.category
                                ? "#A3181B"
                                : "#fff",
                            backgroundColor:
                              selectedCategory === menu.category
                                ? "#A3181B"
                                : "#fff",
                          }}
                          onClick={() => {
                            setMenuLoading(true);
                            setSelectedCategory(menu.category);
                            setMenuLoading(false);
                          }}
                        >
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                selectedCategory === menu.category
                                  ? "#fff"
                                  : "#000",
                              letterSpacing: 1,
                            }}
                          >
                            {menu.category}
                          </span>
                        </div>
                      );
                    }
                  })
              : null}
          </div>

          <div
            style={{
              borderTop: "solid #e0e0e0 0.7px",
              paddingTop: "15px",
            }}
          />

          <div
            style={{
              marginTop: 10,
              height: 30,
              border: "solid #A3181B 0.7px",
              // borderRadius: 15,
              paddingLeft: 5,
              paddingRight: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 15,
              marginRight: 15,
              marginBottom: 20,
            }}
          >
            <Search sx={{ color: "#A3181B" }} />
            <input
              value={openSearch}
              placeholder={t("pos:searchOpenOrder")}
              style={{
                backgroundColor: "#fff",
                outline: "none",
                border: 0,
                fontSize: 13,
                flex: 1,
                color: "#000",
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setOpenSearch(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              height: "50%",
              padding: "15px",
              paddingTop: 0,
              overflowY: "scroll",
              paddingBottom: "107px",
            }}
          >
            {Object.keys(localOrder).map((id) => {
              let orderArr = localOrder[id];
              if (orderArr[0]?.identifier?.toLowerCase().includes(openSearch)) {
                return (
                  <div
                    style={{
                      border: "1px solid",
                      // padding: '10px',
                      boxShadow: "2px 2px 8px 0.5px #d3d3d3",
                      // padding: 5,
                      // marginTop: 15,
                      // marginBottom: 10,
                      justifyContent: "center",
                      display: "flex",
                      cursor: "pointer",
                      borderColor: selectLocalId === id ? "#A3181B" : "#fff",
                      backgroundColor:
                        selectLocalId === id ? "#A3181B" : "#fff",
                      overflowY: "scroll",
                    }}
                  >
                    <OpenOrders
                      localId={id}
                      order={orderArr}
                      setOrderHandler={setLocalItemToCart}
                      deleteOrderHandler={(id) => {
                        let data = delete_Cart_From_LocalStorage(id);
                        setLocalOrder(data);
                        setSelectLocalId(null);
                        setSelectLocalIdentifier(null);
                        setCart([]);
                        setOrderTotal(0);
                        setTotalTax(0);
                      }}
                      isSelected={selectLocalId === id}
                      mode={selectLocalId}
                      cancelEdit={() => {
                        setSelectLocalId(null);
                        setSelectLocalIdentifier(null);
                        setCart([]);
                        setOrderTotal(0);
                        setTotalTax(0);
                        setSubtotal(0);
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div style={{ height: "100%", flex: 0.85 }}>
          <Grid container style={{ height: "100%" }}>
            <Grid
              item
              lg={7}
              style={{
                // borderRight: 'solid black 1px',
                // height: '100%',
                // height: "calc(100% - 50px)",
                height: "calc(100vh - 50px)",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                resize: "width",
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  height: 30,
                  border: "solid #d3d3d3 0.7px",
                  // borderRadius: 15,
                  paddingLeft: 5,
                  paddingRight: 5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                <Search sx={{ color: "#d3d3d3" }} />
                <input
                  value={searchItems}
                  placeholder={t("pos:searchItems")}
                  style={{
                    backgroundColor: "#fff",
                    outline: "none",
                    border: 0,
                    fontSize: 13,
                    flex: 1,
                    color: "#000",
                    paddingLeft: 10,
                  }}
                  onChange={(e) => setSearchItems(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  minHeight: 0,
                  overflowY: "auto",
                  height: "100%",
                  paddingBottom: 20,
                  flex: 1,
                  marginTop: 20,
                }}
              >
                {menuLoading ? (
                  <h2>Loading</h2>
                ) : (
                  menuData.map((element) =>
                    element.menu_items
                      .filter(
                        (a) =>
                          a.item_title
                            .toLowerCase()
                            .indexOf(searchItems.toLowerCase()) !== -1 &&
                          a.is_visible
                      )
                      .map((item) => {
                        return (
                          <POSItem
                            data={item}
                            setInstruction={setInstruction}
                            boxSize={boxSize}
                            onClick={(item) => {
                              let tax_obj = {
                                title: element?.category_tax_name,
                                percentage: element?.category_tax_rate,
                              };
                              // console.log("1111111111111148: ", element);
                              setTaxes([tax_obj]);
                              if (item?.questions?.length === 0) {
                                // let itemCopy = {
                                //   ...item,
                                //   item_quantity: 1,
                                //   options: [],
                                // };
                                // addCartToItems(itemCopy);
                                let itemMatched = false;
                                let currentCart = cart;
                                let currentItem = {
                                  ...item,
                                  categoryKey: element.category_id,
                                  itemKey: item.item_id,
                                  options: [...radio, ...checkBox],
                                  item_quantity: itemQuantity,
                                  itemUniqueId: item.item_id.toString(),
                                  taxName: element.category_tax_name,
                                  taxRate: element.category_tax_rate,
                                  taxAmount: Math.ceil(
                                    (item.item_price / 100) *
                                      element.category_tax_rate
                                  ),
                                };
                                const newCart = cart.map((value) => {
                                  if (
                                    value.itemUniqueId ===
                                    currentItem.itemUniqueId
                                  ) {
                                    itemMatched = true;
                                    return {
                                      ...value,
                                      item_quantity: value.item_quantity + 1,
                                    };
                                  } else {
                                    return { ...value };
                                  }
                                });
                                if (!itemMatched) {
                                  newCart.push(currentItem);
                                }
                                setSelectedItem({
                                  ...item,
                                  categoryKey: element.category_id,
                                  itemKey: item.item_id,
                                });
                                currentCart = newCart;
                                calculateCartTotal(currentCart);
                                setCartToLocalStorage(currentCart);
                                setCart(
                                  JSON.parse(JSON.stringify(currentCart))
                                );
                              } else {
                                setOpen(true);
                                setSelectedItem({
                                  ...item,
                                  categoryKey: element.category_id,
                                  itemKey: item.item_id,
                                  taxName: element.category_tax_name,
                                  taxRate: element.category_tax_rate,
                                  taxAmount: Math.ceil(
                                    (item.item_price / 100) *
                                      element.category_tax_rate
                                  ),
                                });
                              }
                            }}
                          />
                        );
                      })
                  )
                )}
              </div>
            </Grid>

            <Grid
              item
              lg={5}
              style={{
                // height: '100%',
                // height: "calc(100% - 50px)",
                height: "calc(100vh - 50px)",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 1px 5px #888888",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 15,
                  borderBottom: "solid rgba(0,0,0,0.1) 2px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ flex: 3, fontWeight: "bold" }}
                >
                  {t("pos:itemName")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ flex: 1, fontWeight: "bold" }}
                >
                  {t("pos:quantity")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ flex: 1, fontWeight: "bold" }}
                >
                  {t("pos:price")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ flex: 1, fontWeight: "bold" }}
                >
                  {t("pos:subtotal")}
                </Typography>
              </div>
              <div
                style={{
                  // minHeight: 0,
                  overflowY: "auto",
                  paddingBottom: 20,
                  flex: 1,
                }}
              >
                {cart.map((item, i) => {
                  return (
                    <POSCartItem
                      data={item}
                      removeItem={removeCartItem}
                      index={i}
                      increase_qty={increaseQuantity}
                      decrease_qty={decreaseQuantity}
                    />
                  );
                })}
              </div>
              <div
                style={{ padding: 10, borderTop: "solid rgba(0,0,0,0.1) 2px" }}
              >
                {/* <div
                  style={{
                    height: 35,
                    border: 'solid rgb(237,164,83) 1px',
                    borderRadius: 15,
                    paddingLeft: 10,
                    paddingRight: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <LocalOffer sx={{ color: 'rgb(237,164,83)', fontSize: 20 }} />
                  <input
                    style={{
                      backgroundColor: 'transparent',
                      outline: 'none',
                      border: 0,
                      fontSize: 13,
                      flex: 1,
                      color: 'rgb(237,164,83)',
                      marginLeft: 10,
                    }}
                  />
                  <div
                    style={{
                      height: 29,
                      padding: 5,
                      backgroundColor: 'rgb(237,164,83)',
                      borderRadius: 15,
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ color: 'white', fontSize: 12 }}
                    >
                      Apply Promo
                    </Typography>
                  </div>
                </div> */}
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2">Subtotal</Typography>
                  <Typography variant="body2">Rs. {subtotal}</Typography>
                </div> */}

                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2">Discount</Typography>
                  <Typography variant="body2">-</Typography>
                </div> */}
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body2">
                    Applied Promocode:{' '}
                    <span style={{ color: 'rgb(237,164,83)' }}>
                      PROMOCODE NAME
                    </span>
                  </Typography>
                  <Close sx={{ fontSize: 15, color: 'red' }} />
                </div> */}

                {/* {!!taxes.length &&
                  taxes.map((tax, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="body2">{tax.title}</Typography>
                        <Typography variant="body2">
                          Rs. {(orderTotal - subtotal).toFixed(2)}
                        </Typography>
                      </div>
                    );
                  })} */}

                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2">Delivery Fee</Typography>
                  <Typography variant="body2">-</Typography>
                </div> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#A2191B",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                  >
                    {t("pos:orderTotal")}
                    <p style={{ margin: "0px 0px 0px 2px", fontSize: "14px" }}>
                      {" "}
                      {`(incl. Tax)`}
                    </p>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", fontSize: 24 }}
                  >
                    {showPrice(orderTotal + totalTax)}
                  </Typography>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ButtonGroup
                    aria-label="large button group"
                    style={{ width: "80%", display: "flex" }}
                  >
                    <button
                      style={{
                        boxShadow: "3px 3px 8px 0.7px #d3d3d3",
                        backgroundColor: "#fff",
                        border: "none",
                        padding: "0px 15px",
                        borderRadius: "0px",
                        color: "#000",
                        fontWeight: "bolder",
                        margin: "10px 0",
                        fontSize: 13,
                        height: 40,
                        width: "80%",
                        borderColor: "#fff",
                        border: "1px solid",
                      }}
                      disabled={
                        isModalClose ||
                        cart.length === 0 ||
                        isTakeAway ||
                        isDineIn
                      }
                      onClick={() => {
                        if (selectLocalId) {
                          setIsModalClose(true);
                          setDeliveryCharges(0);
                          editOpenOrderHandler();
                          setSelected_typeId(3);
                        } else {
                          setIsModalClose(true);
                          setShowOpenOrderModal(true);
                        }
                      }}
                    >
                      {t("pos:stayOpen")}
                    </button>
                    <button
                      style={{
                        boxShadow: "3px 3px 8px 0.7px #d3d3d3",
                        backgroundColor: "#fff",
                        border: "none",
                        padding: "0px 15px",
                        borderRadius: "0px",
                        color: "#000",
                        fontWeight: "bolder",
                        margin: "10px 0",
                        fontSize: 13,
                        height: 40,
                        width: "80%",
                        borderColor: "#fff",
                        border: "1px solid",
                      }}
                      disabled={isTakeAway || isDineIn}
                      onClick={() => {
                        if (cart.length > 0) {
                          setShowCustomerDetailModal(true);
                          setSelected_typeId(1);
                        } else {
                          alert.show("Add Something to Cart first!", {
                            type: "error",
                          });
                        }
                      }}
                    >
                      {t("pos:homeDelivery")}
                    </button>
                  </ButtonGroup>
                  <ButtonGroup
                    aria-label="large button group"
                    style={{ width: "80%", display: "flex" }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        flex: 1,
                        backgroundColor: "#A3181B",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeliveryCharges(0);
                        createOrderHandler(2, "takeAway");
                        setSelected_typeId(2);
                      }}
                      disabled={isTakeAway || isDineIn}
                    >
                      {loading.type === "takeAway" && loading.loading === true
                        ? t("pos:generatingOrder")
                        : t("pos:takeAway")}
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        flex: 1,
                        backgroundColor: "#A3181B",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeliveryCharges(0);
                        createOrderHandler(3, "dineIn");
                        setSelected_typeId(3);
                      }}
                      disabled={isTakeAway || isDineIn}
                    >
                      {loading.type === "dineIn" && loading.loading === true
                        ? t("pos:generatingOrder")
                        : t("pos:dineIn")}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <div
        style={{
          //   backgroundColor: '#f8f8f8',
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          height: 60,
          width: "100%",
          // boxShadow: '10px 10px 5px grey',
          //   borderTopWidth: 2,
          //   borderTopColor: 'red',
        }}
      >
        <div
          style={{
            height: 60,
            borderTopWidth: 2,
            borderTopColor: "red",
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: 15,
          }}
        >
          <span
            onClick={() => selected("en")}
            style={{
              cursor: "pointer",
              marginRight: 15,
              fontSize: 15,
              fontWeight: i18n.language === "en" ? "bold" : "normal",
              color: "#A3181B",
            }}
          >
            English
          </span>
          <span
            onClick={() => selected("de")}
            style={{
              cursor: "pointer",
              marginRight: 15,
              fontSize: 15,
              fontWeight: i18n.language === "de" ? "bold" : "normal",
              color: "#A3181B",
            }}
          >
            German
          </span>
          <span
            onClick={() => selected("ur")}
            style={{
              cursor: "pointer",
              marginRight: 5,
              fontSize: 15,
              fontWeight: i18n.language === "ur" ? "bold" : "normal",
              color: "#A3181B",
            }}
          >
            Urdu
          </span>
        </div>
      </div> */}

      {/* <div
        style={{
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle" style={{ textAlign: 'center' }}>
          You are logged in as <b>Usama Moin</b>
        </Typography>
      </div> */}
    </div>
  );
}

export default POSPage;
