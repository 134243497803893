import React, { useEffect, useState } from "react";
import "./KOT.css";
import { delivery_type } from "../../constants/orderType";
import { Button, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

function KOT({ cart, onClose, type_id, revised, order_id }) {
  const [logo, setLogo] = useState("");
  const [print, setPrint] = useState(false);
  console.log("cart123123123", cart);
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const config = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  useEffect(() => {
    if (print) {
      downloadPDF();
    }
  }, [print]);

  let data = {
    logo: config?.images?.logo?.[0],
  };

  let type = delivery_type[type_id ? type_id : 2];

  const downloadPDF = () => {
    let ele = document.getElementById("kot");

    html2canvas(ele).then(function (canvas) {
      let h = canvas.height + 150;
      let doc = new jsPDF("p", "mm", [80, h * 0.264583]);
      let c = canvas.toDataURL("image/jpeg");
      doc.addImage(c, "PNG", 10, 10);
      // In order to save doc locally
      // doc.save('sample-file.pdf');

      //In order to print doc
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
      onClose();
    });
  };

  toDataURL(data.logo, function (dataUrl) {
    // console.log('RESULT:', dataUrl);
    setLogo(dataUrl);
    setPrint(true);
  });

  return (
    <div id="kot">
      <div className="logoContainer">
        {data?.logo ? <img src={logo} alt="" /> : <h6>{config?.name}</h6>}
      </div>
      <h2 className="centered">{revised ? "Revised" : ""} KOT</h2>
      <div className="info_view">
        <div className="rowFlex">
          <span>Order ID : </span>
          <span className="info_value">#{order_id}</span>
        </div>
        <div className="rowFlex">
          <span>Order Type : </span>
          <span className="info_value">{type}</span>
        </div>
      </div>
      <table
        style={{
          width: "200px",
          maxWidth: "200px",
        }}
      >
        <thead>
          <tr
            style={{
              width: "200px",
              maxWidth: "200px",
            }}
          >
            <th className="quantity">Qty.</th>
            <th className="description">Items Name</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item, i) => (
            <>
              <tr
                key={i}
                style={{
                  width: "200px",
                  maxWidth: "200px",
                }}
              >
                <td className="quantity">{item.item_quantity}</td>
                <td className="description">
                  {item.item_title}
                  {item.options.length > 0 && (
                    <Typography>
                      {item.options.map((opt, index) => {
                        return (
                          <>
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: "500",
                                opacity: 0.46,
                              }}
                            >
                              {opt.option}
                            </span>
                            {index + 1 !== i.length && ", "}
                          </>
                        );
                      })}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: 8,
                      fontWeight: "500",
                      opacity: 0.46,
                      wordBreak: "break-word",
                    }}
                  >
                    {item.instruction}
                  </Typography>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default KOT;
