import React from 'react'
import "./PopularItems.css"

function PopularItems() {
    return (
        <div className="card">
            <p>
                Pizza
            </p>
        </div>
    )
}

export default PopularItems
