import axios from "axios";
import { serverUrl } from "../../config";
import { SET_QUESTION_TYPE, SET_TOKEN, SET_USER } from "../constants";



export const getQuestionTypes = () => async (dispatch) => {
  return await axios({
    url: "api/user/menu/getQuestionTypes",
    method: "GET",
    baseURL: serverUrl,
  })
    .then((res) => {
      console.warn(res)
      dispatch({
        type: SET_QUESTION_TYPE,
        payload: res.data.payload,
      });
      return res
    })
    .catch((err) => {
      console.log("error:", err.response);
      throw err.response;
    });
};
