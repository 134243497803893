import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadImage from './../assets/uploadImage.png';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  maxHeight: 200,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 2,
  position: 'relative',
};

const crossBtn = {
  position: 'absolute',
  left: 0,
  top: 0,
  color: '#fff',
  backgroundColor: '#111',
  width: 20,
  fontWeight: 'bold',
  padding: 5,
  paddingBottom: 25,
  borderRadius: 5,
  height: 20,
  zIndex: 1000,
};

function ImageDropZone({
  imageArr,
  onChangeImages,
  maxFileLimit,
  onDeleteHandler,
  name,
  width,
  height,
}) {
  let crossClicked = false;
  const [files, setFiles] = useState(imageArr);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFileLimit,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (maxFileLimit === 1) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              update: true,
            })
          )
        );
        onChangeImages(acceptedFiles);
      } else {
        let instance = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            update: true,
          })
        );
        if (imageArr.length <= maxFileLimit) {
          imageArr.push(...instance);
          setFiles(imageArr);
          onChangeImages(imageArr);
        } else {
          imageArr[maxFileLimit - 1] = instance[0];
          setFiles(imageArr);
          onChangeImages(imageArr);
        }
      }
    },
  });

  let deleteImage = (index) => {
    let arr = imageArr;
    arr.splice(index, 1);
    onChangeImages(arr);
    if (onDeleteHandler) {
      onDeleteHandler(name);
    }
  };

  const thumbs = imageArr.map((file, index) => {
    return (
      <div style={thumb} key={index}>
        <div style={{ position: 'relative' }}>
          <span
            style={crossBtn}
            className="close"
            onClick={(e) => {
              crossClicked = true;
              deleteImage(index);
              setTimeout(() => {
                crossClicked = false;
              }, 1500);
            }}
          >
            X
          </span>
          <img src={file.preview} style={img} />
        </div>
      </div>
    );
  });
  return (
    <section
      style={{
        border: 'dashed rgba(0,0,0,0.3) 3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        marginBottom: 20,
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : '200px',
      }}
    >
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <input
          {...getInputProps()}
          onClick={(e) => {
            if (crossClicked === true) {
              e.preventDefault();
            }
          }}
          style={{ display: 'block', visibility: 'hidden' }}
        />

        {imageArr.length === 0 && (
          <>
            <img src={uploadImage} style={{ width: '50%' }} />
            <p>Drop an Image here or click here to select files</p>
          </>
        )}

        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
    </section>
  );
}

export default ImageDropZone;
