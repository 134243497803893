import {
  Email,
  EmailOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useWindowSize from "../helperFunctions/useWindowSize";
import curvedLine from "./../assets/curvedLine.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login } from "../redux/actions/auth";
import bg from "./../assets/bg.jpg";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

import CustomButton from "../components/CustomButton";
import { useRef } from "react";
import "./Login.css";

function LoginPage() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const passwordRef = useRef(null);
  const emailRef = useRef(null);

  const [selectedInput, setSelectedInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputType_password, setInputType_password] = useState("password");

  const auth = JSON.parse(localStorage.getItem("authenticated"));

  const alert = useAlert();

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, [auth]);

  const onLoginHandler = async () => {
    setLoading(true);
    const regx = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.trim() && password.trim()) {
      if (!regx.test(email)) {
        setLoading(false);
        alert.show("Invalid Email address.", {
          type: "info",
        });
      } else {
        try {
          const res = await dispatch(
            login({
              data: {
                email,
                password,
              },
            })
          );
          setLoading(false);
          navigate("/dashboard");
        } catch (err) {
          console.log("error: ", err);
          setLoading(false);
          let error =
            err?.message && err?.message.split(" ").slice(0, 2).join(" ");

          if (error && error === "connect ECONNREFUSED") {
            alert.show("Server is down please contact your server provider!", {
              type: "error",
            });
          } else if (err && err.data["type"] === "LOGIN_VENDOR_FAILED") {
            alert.show("Incorrect Username or Password!", {
              type: "error",
            });
          } else {
            alert.show("Something went Wrong!", {
              type: "error",
            });
          }
        }
      }
    } else {
      setLoading(false);
      alert.show("Please enter your email and password", {
        type: "info",
      });
    }
  };

  const enterKeyPressed = () => {
    onLoginHandler();
  };

  const selected = (val) => {
    i18n.changeLanguage(val);
  };

  const size = useWindowSize();

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundSize: "contain",
        backgroundImage: `url(${bg})`,
        backgroundPositionX: "right",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        {/* <div style={{width:'30%',height:'100%',background:'rgba(238, 164, 74,1)'}}/> */}
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(70deg, rgba(162,25,27,1) , rgba(162,25,27,1) ,rgba(162,25,27,0.99),rgba(162,25,27,0.5), rgba(0,0,0,0))",
          }}
        />
        <Hidden smDown>
          <img
            src={curvedLine}
            style={{
              width: "85%",
              height: "100%",
              position: "absolute",
              objectFit: "contain",
              opacity: 0.5,
            }}
          />
        </Hidden>
      </div>
      <div
        style={{
          paddingLeft: 50,
          paddingTop: 20,
          width: "100%",
          height: "10%",
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ height: 50, width: 50, borderRadius: 25 }} />

        <div className="header-language">
          <FormControl fullWidth>
            <InputLabel
              id="language-label"
              sx={{
                color: "#fff",
                fontWeight: 500,
              }}
            >
              Language
            </InputLabel>
            <Select
              variant="outlined"
              sx={{
                width: 120,
                color: "#fff",
                fontWeight: 500,
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "orange",
                  },
                },
              }}
              id="language-select"
              label="Language"
              labelId="language-label"
              onChange={(val) => selected(val.target.value)}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="ur">Urdu</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Grid
        container
        id="contentContainer"
        style={{
          position: "absolute",
          color: "white",
          height: "90%",
          top: "10%",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          style={{ padding: 25, paddingTop: size.height / 10 }}
        >
          <Grid container alignItems="center" style={{ flex: 1 }}>
            <Grid item xs={10} sm={8} md={6} lg={5} style={{ padding: 25 }}>
              <Typography
                variant="body"
                style={{
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  opacity: 0.5,
                }}
              >
                {t("Login:startNow")}
              </Typography>
              <Typography variant="h3" style={{ fontWeight: "bolder" }}>
                {t("Login:loginToAccount")}
                <span style={{ fontSize: 60, color: "#252422" }}>.</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={9}
              sm={6}
              md={4}
              lg={3}
              style={{
                padding: 8,
                paddingLeft: 20,
                backgroundColor: "rgba(255,255,255,0.2)",
                marginLeft: 30,
                borderRadius: 10,
                marginTop: 15,
                border:
                  selectedInput === "email"
                    ? "solid white 2px"
                    : "solid transparent 2px",
              }}
              className="inputContainer"
              onClick={() => emailRef.current.focus()}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontSize: 11,
                      marginBottom: 5,
                      marginTop: 5,
                      lineHeight: 0.5,
                      color: "rgba(0,0,0,0.75)",
                      fontWeight: "600",
                    }}
                    onClick={() => emailRef.current.focus()}
                  >
                    {t("Login:emailAddress")}
                  </p>
                  <input
                    ref={emailRef}
                    className="input-fields"
                    style={{
                      flex: 1,
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "0px solid",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    value={email}
                    placeholder="xyz@synevry.com"
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => {
                      setSelectedInput("email");
                    }}
                    onBlur={() => {
                      setSelectedInput(null);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        passwordRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <Email sx={{ color: "white" }} fontSize="medium" />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container style={{ width: "100%" }}>
            <Grid
              item
              xs={9}
              sm={6}
              md={4}
              lg={3}
              style={{
                padding: 8,
                paddingLeft: 20,
                backgroundColor: "rgba(255,255,255,0.2)",
                marginLeft: 30,
                borderRadius: 10,
                marginTop: 15,
                border:
                  selectedInput === "pass"
                    ? "solid white 2px"
                    : "solid transparent 2px",
              }}
              className="inputContainer"
              onClick={() => passwordRef.current.focus()}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontSize: 11,
                      marginBottom: 5,
                      marginTop: 5,
                      lineHeight: 0.5,
                      color: "rgba(0,0,0,0.75)",
                      fontWeight: "600",
                    }}
                  >
                    {t("Login:password")}
                  </p>
                  <input
                    ref={passwordRef}
                    type={inputType_password}
                    className="input-fields"
                    value={password}
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => {
                      setSelectedInput("pass");
                    }}
                    onBlur={() => {
                      setSelectedInput(null);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        enterKeyPressed();
                      }
                    }}
                    style={{
                      flex: 1,
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "0px solid",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  />
                </div>
                {inputType_password === "password" ? (
                  <VisibilityOff
                    sx={{ color: "white", cursor: "pointer" }}
                    fontSize="medium"
                    onClick={() => {
                      if (inputType_password === "password") {
                        setInputType_password("text");
                      } else {
                        setInputType_password("password");
                      }
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{ color: "white", cursor: "pointer" }}
                    fontSize="medium"
                    onClick={() => {
                      if (inputType_password === "password") {
                        setInputType_password("text");
                      } else {
                        setInputType_password("password");
                      }
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={9} sm={6} md={4} lg={3} className="inputContainer">
              <CustomButton
                value={t("Login:continueToDashboard")}
                disabled={!(email && password) || loading}
                onClick={onLoginHandler}
                loading={loading}
                style={{
                  marginLeft: 30,
                  marginTop: 25,
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={9}
              sm={6}
              md={4}
              lg={3}
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: 30,
                marginTop: 20,
              }}
            >
              {/* <Typography
                variant="body"
                style={{ opacity: 0.9, textAlign: 'center', fontSize: 14 }}
              >
                {t('Login:forgetPassword')}
                <span
                  style={{
                    color: '#252422',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    fontSize: 13,
                    paddingLeft: 5
                  }}
                >
                  {t('Login:recoverNow')}
                </span>
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
