import { combineReducers } from "redux";
import authReducer from "./auth";
import menuReducer from "./menu";
import taxReducer from "./tax";
import categoryReducer from "./category";
import configReducer from "./config";
import ordersReducer from "./orders";
import promoReducer from "./promo";
import posMenuReducer from "./pos";
import businessConfigReducer from "./businessConfig";
import invoiceReducer from "./invoice";
import sidebarReducer from "./sidebar";
import addressReducer from "./addressInfo";

export const reducer = combineReducers({
  state: (state = {}) => state,
  authReducer,
  menuReducer,
  taxReducer,
  categoryReducer,
  configReducer,
  ordersReducer,
  posMenuReducer,
  promoReducer,
  businessConfigReducer,
  invoiceReducer,
  sidebarReducer,
  addressReducer,
});
