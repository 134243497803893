import { SET_CATEGORIES, EDIT_CATEGORIES } from "../constants";

const initialState = {
  categories: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      //   console.log("SEt CAtegories CAlled: ", action.payload);
      return {
        ...state,
        categories: action.payload,
      };
    }
    case EDIT_CATEGORIES: {
      // console.log("Edit Categories Called: ");
      return {
        ...state,
      };
    }
    // case EDIT_TAXES: {
    //   console.log("Action Payload : ", action.payload);
    //   //   console.log("State: ----!!! ", { ...state });
    //   console.log("state non iterated: ", state);
    //   return {
    //     ...state,
    //     categories: state.taxes.map((category) =>
    //       categories.id === action.payload.id
    //         ? // ? console.log("Tax : ", { ...category, ...action.payload })
    //           { ...action.payload,}
    //         : category
    //     ),
    //   };
    // }
    default:
      return state;
  }
};

export default categoryReducer;
