import axios from "axios";
import { serverUrl } from "../../config";
import { SET_INVOICE } from "../constants";



export const getInvoice = (id) => async (dispatch) => {
    const token = window.localStorage.getItem('access_token')
    return await axios({
        url: `api/vendor/menu/invoice?order_id=${id}`,
        method: "GET",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
    })
        .then((res) => {
            dispatch({
                type: SET_INVOICE,
                payload: res.data.payload,
            });
            console.log('res', res)
            return res
        })
        .catch((err) => {
            console.log("error:", err.response);
            throw err.response;
        });
};
