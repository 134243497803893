import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PromoTable.css";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { deletePromo } from "../redux/actions/promo"
import { useSelector, useDispatch } from "react-redux"


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



export default function PromoTable({ edit }) {

    const promos = useSelector(state => state.promoReducer.promo)
    const dispatch = useDispatch();


    const deletePromoHandler = (id) => {
        dispatch(deletePromo({ promocode_id: id.toString() }))
    }




    return (
        <TableContainer component={Paper}>
            <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                <TableHead className="tableHeader">
                    <TableRow>
                        <TableCell className="tableHeaderCell">Amount</TableCell>
                        <TableCell className="tableHeaderCell" >
                            Code
                        </TableCell>
                        <TableCell className="tableHeaderCell" align="center">Max Voucher</TableCell>
                        <TableCell className="tableHeaderCell" align="center">Max Discount</TableCell>
                        <TableCell className="tableHeaderCell" align="center">Status</TableCell>
                        <TableCell className="tableHeaderCell" align="center">
                            Usage Per Person
                        </TableCell>
                        <TableCell className="tableHeaderCell">
                            Type
                        </TableCell>
                        <TableCell className="tableHeaderCell">
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!promos.length && promos.map((promo) => (
                        // <div className="mb-4">
                        <StyledTableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" >
                                {promo.amount}
                            </TableCell>
                            <TableCell>{promo.code}</TableCell>
                            <TableCell align="center">{promo.max_vouchers ? promo.max_vouchers : ''}</TableCell>
                            <TableCell align="center">{promo.max_discount ? promo.max_discount : ''}</TableCell>
                            <TableCell >
                                <div style={
                                    {
                                        backgroundColor: promo.is_visible == 1 ? "green" : "red",
                                        padding: '5px 5px 7px 7px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        borderRadius: '10px',
                                        fontWeight: "bold"
                                    }
                                }>
                                    {promo.is_visible == 0 ? "InActive" : "Active"}
                                </div>
                            </TableCell>
                            <TableCell align="center">{promo.usage_person ? promo.usage_person : ''}</TableCell>
                            <TableCell >
                                {promo.type}
                            </TableCell>
                            <TableCell >
                                <a href="#" style={{ color: "black" }}>
                                    <DeleteOutlineOutlinedIcon onClick={() => deletePromoHandler(promo.id)} />
                                </a>
                                <a href="#" style={{ color: "black" }}>
                                    <ModeEditOutlinedIcon onClick={() => edit(promo)} />
                                </a>
                            </TableCell>
                        </StyledTableRow>
                        // </div>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    );
}
