export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_MENU = "SET_MENU";
export const SET_PROMO = "SET_PROMO";
export const SET_MENU_POS = "SET_MENU_POS";
export const SET_QUESTION_TYPE = "SET_QUESTION_TYPE";
export const SET_BUSINESS_CONFIG = "SET_BUSINESS_CONFIG";
export const SET_INVOICE = "SET_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const SET_TAXES = "SET_TAXES";
export const GET_TAXES = "GET_TAXES";
export const ADD_TAXES = "ADD_TAXES";
export const EDIT_TAXES = "EDIT_TAXES";
export const DELETE_TAXES = "DELETE_TAXES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const EDIT_CATEGORIES = "EDIT_CATEGORIES";
export const EDIT_OPTION_PRICE = "EDIT_OPTION_PRICE";

export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const ADD_PROMO = "ADD_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";
export const EDIT_PROMO = "EDIT_PROMO";
export const EDIT_STATUS = "EDIT_STATUS";

export const SET_AREAS = "SET_AREAS";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const SET_CURRENCY = "SET_CURRENCY";
