import { Button, Modal, Image, Spinner } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { getInvoice } from "../redux/actions/invoice";
import "./POSCheckout.css";
import { useAlert } from "react-alert";
import { showPrice } from "../config/currency";

const styles = () => ({
  radio: {
    "&$checked": {
      color: "#F76C00",
    },
  },
  checked: {},
});

const POSCheckoutModal = ({
  cart,
  subtotal,
  orderTotal,
  discount,
  actualPrice,
  taxes,
  totalTax,
  taxDetail,
  deliveryCharges,
  show,
  onClose,
  onClose_Invoice,
  order_id,
}) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  console.log("totalTax 51", totalTax);
  console.log("taxDetail 52", taxDetail);
  console.log("deliveryChargesdeliveryCharges", deliveryCharges);
  console.log(
    "deliveryChargesdeliveryCharges",
    Math.round(orderTotal) + deliveryCharges
  );
  const fetchInvoice = async () => {
    if (!loading) {
      setLoading(true);
      try {
        await dispatch(getInvoice(order_id));
        onClose_Invoice();
        setLoading(false);
      } catch (error) {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          onClose();
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
          setLoading(false);
        } else {
          onClose();
          alert.show("Something went wrong while generarting Invoice!", {
            type: "error",
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Order Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card sx={{ maxWidth: 450, minWidth: 350 }} style={{ margin: "5px 0" }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              style={{ fontWeight: "bold" }}
              className="mt-4"
            >
              Order Summary
            </Typography>
            <div>
              {cart.map((item) => {
                let total = item.item_price * item.item_quantity;
                let discountedTotal =
                  item.discounted_price * item.item_quantity;
                item.options.forEach((i) => {
                  total += i.option_price * item.item_quantity;
                  discountedTotal += i.option_price * item.item_quantity;
                });
                return (
                  <div className="pos-items-flex ">
                    <span>
                      <span className="pos-qty-text">{item.item_quantity}</span>{" "}
                      <b> x </b>
                      {item.item_title} {`( ${showPrice(discountedTotal)})`}
                    </span>
                    <span className="pos-price-text"> {showPrice(total)}</span>
                  </div>
                );
              })}
            </div>
            <div className="pos-items-flex mt-4">
              <span>
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  Subtotal
                </Typography>
              </span>
              <span className="pos-price-text"> {showPrice(actualPrice)}</span>
            </div>
            <div className="pos-items-flex">
              <span>
                <Typography
                  gutterBottom
                  // variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  Discount
                </Typography>
              </span>
              <span className="pos-price-text">
                {showPrice(actualPrice - discount)}
              </span>
            </div>
            {/* <hr /> */}
            {taxes.length &&
              taxes.map((tax) => {
                return (
                  <div className="pos-items-flex">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>{tax.title}</span>
                      {taxDetail.length > 0 &&
                        taxDetail.map((ele) => (
                          <span
                            style={{ fontSize: "12px", marginBottom: "5px" }}
                          >
                            {`${ele.name} ( ${ele.rate}%)`}
                          </span>
                        ))}
                    </div>
                    <span className="pos-price-text">
                      {showPrice(Math.ceil(totalTax))}
                    </span>
                  </div>
                );
              })}
            <div className="pos-items-flex">
              <span>
                <Typography
                  gutterBottom
                  // variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  Delivery Charges
                </Typography>
              </span>
              <span className="pos-price-text">{deliveryCharges}</span>
            </div>
            <hr />
            <div className="pos-items-flex">
              <span>
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  Total
                </Typography>
              </span>
              <span className="pos-price-text">
                {" "}
                {deliveryCharges
                  ? showPrice(
                      Math.ceil(orderTotal + deliveryCharges + totalTax)
                    )
                  : showPrice(Math.ceil(orderTotal + totalTax))}
              </span>
            </div>
          </CardContent>
        </Card>

        <div className="pos-items-flex">
          <button
            className="pos-print-btn  mx-auto mt-4"
            onClick={() => {
              fetchInvoice();
            }}
            disabled={loading}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LocalPrintshopOutlinedIcon style={{ marginRight: "10px" }} />
              <span>{loading ? "Printing..." : "Print Invoice"}</span>
            </div>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withStyles(styles)(POSCheckoutModal);
