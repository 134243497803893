import axios from "axios";
import { serverUrl } from "../config";

export async function createCategory(data) {
  const token = window.localStorage.getItem("access_token");

  return await axios({
    url: "api/vendor/menu/category",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}

export async function createItem(data) {
  console.warn("Item creation data", data?.data?.questions);
  for (let i = 0; i < data?.data?.questions.length; i++) {
    delete data?.data?.questions[i].id;
    for (let j = 0; j < data?.data?.questions[i]?.options.length; j++) {
      console.warn(data?.data?.questions[i].options[j].menu_item_id);
      delete data?.data?.questions[i].options[j].menu_item_id;
    }
  }
  // data?.questions?.map((question) => {
  // })
  console.warn("Item creation data", data);
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}
export async function editItem(data) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu/item",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}

export async function editItemPrice(data) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu/itemPrice",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  });
}

export async function deleteItem(data) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu/item",
    method: "DELETE",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}

export async function deleteCategory(data) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu/category",
    method: "DELETE",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}

export async function getItemFromOptions(data) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    url: "api/vendor/menu/options?option=" + data,
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data,
  });
}

export async function uploadImages(images, type, extraData) {
  const token = window.localStorage.getItem("access_token");
  const formData = new FormData();
  if (type === "menuItem") {
    formData.append("menuItemIds", JSON.stringify(extraData));
  }
  for (let i = 0; i < images.length; i++) {
    let file = images[i];
    formData.append(`${type}`, file);
  }
  formData.append(`type`, type);

  return await axios({
    url: "api/vendor/uploadImage",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token, "content-type": "multipart/form-data" },
    data: formData,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export async function getItemPrices(id) {
  const token = window.localStorage.getItem("access_token");
  return await axios({
    // url: 'api/vendor/menu/options?option=' + data,
    url: `api/vendor/menu/itemPrice?id=${id}`,
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  });
}
