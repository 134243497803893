import { SET_MENU, SET_TAXES } from "../constants";

const initialState = {
  menu: [],
  taxes: []
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU: {
      return {
        ...state,
        menu: action.payload,
      };
    }
    case SET_TAXES: {
      return {
        ...state,
        taxes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default menuReducer;
