import React, { useEffect, useState } from "react";
import "./../App.css";
import useWindowSize from "../helperFunctions/useWindowSize";
import NavigationBar from "../navigation/NavigationBar";
import HomePage from "./HomePage";
import MenuPage from "./MenuPage";
import Tax from "./Tax";
import { OrderHistory } from "./OrderHistory/OrderHistory";
import { Switch, Route, Routes, Outlet, useLocation } from "react-router-dom";
import OrdersPage from "../pages/Orders";
import SettingsPage from "./SettingsPage";
import { useTranslation } from "react-i18next";
import socket from "../resources/socket";
import { useDispatch, useSelector } from "react-redux";
import PromoCode from "./PromoCode";
import Statistics from "./Statistics";
import { getOrderById, updateOrder } from "../redux/actions/orders";
import { Helmet } from "react-helmet";
import { serverUrl } from "../config";
import { SET_CURRENCY } from "../redux/constants";
import axios from "axios";

function Dashboard() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const size = useWindowSize();
  const [bodyWidth, setBodyWidth] = useState("200px");
  const businessDetails = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  console.log("businessDetails", businessDetails);
  const location = useLocation();

  const path_name = location.pathname.split("/")[1];

  const selected = (val) => {
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket id", socket.id); // to test if connected to server socket or not
    });
    socket.on("disconnect", () => {
      console.log("socket disconnected"); // to test if connected to server socket or not
      alert(
        "Connection to the server has broken, please refresh the web to get order updates in realtime."
      );
    });
  }, []);

  useEffect(() => {
    (async () => {
      const currencyData = await axios({
        url: `/api/vendor/currency/${businessDetails.currency_id}`,
        method: "GET",
        baseURL: serverUrl,
      });
      let temp = currencyData?.data?.payload;
      if (temp.length > 0) {
        temp = temp[0];
        dispatch({
          type: SET_CURRENCY,
          payload: { symbol: temp.symbol, prefix: temp.is_prefix },
        });
        window.localStorage.setItem(
          "currencyData",
          JSON.stringify({ symbol: temp.symbol, prefix: temp.is_prefix })
        );
      }
    })();
  }, [businessDetails]);

  useEffect(() => {
    const token = window.localStorage.getItem("access_token");
    console.warn("Business config", businessDetails);
    console.warn(
      "Event is ",
      "admin_5642" + businessDetails.id + "43_" + token
    );
    socket.on("admin_5642" + businessDetails.id + "43_" + token, (data) => {
      // console.log("Socket data recieved", data);
      // @TODO perform appropriate action based on Two type of response as shown below

      //   @TODO socket_order_update_2
      //  Sample Output:
      //   {
      //     "type": "UPDATE_ORDER",
      //     "query_by": {
      //         "order_id": 19
      //     },
      //     "data": {
      //         "order_status": 3 //currently this is order_status there can be any other variable in place of this
      //     }
      // }
      //Means update all orders with order_id 19 to order_status 3 and save in redux

      dispatch(updateOrder(data?.query_by, data?.data));
    });
    console.warn("Event is ", "admin_" + businessDetails.username);

    socket.on("admin_" + businessDetails.username, (data) => {
      // console.log("Socket data recieved", data);
      dispatch(getOrderById(data?.data?.order_id));
    });
  }, [businessDetails?.bid]);

  return (
    <div
      style={{
        width: size.width,
        height: size.height,
        // backgroundColor: "#A2191B",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {businessDetails?.name
            ? `${businessDetails?.name}-Synevry`
            : `Synevry`}
        </title>
      </Helmet>
      <NavigationBar
        setBodyWidth={setBodyWidth}
        businessDetails={businessDetails}
      />
      {path_name === "orders" ? (
        <div
          style={{
            height: "100%",
            backgroundColor: "#FFFFFF",
            flex: 10,
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            // padding: 50,
            marginLeft: bodyWidth,
            overflowY: "auto",
            transition: "margin-left .5s",
          }}
          className="menu-page"
        >
          <Routes>
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/tax" element={<Tax />} />
            <Route path="/promo" element={<PromoCode />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/statistics" element={<Statistics />} />
          </Routes>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            backgroundColor: "#FFFFFF",
            flex: 10,
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            // padding: 50,
            marginLeft: bodyWidth,
            overflowY: "auto",
            transition: "margin-left .5s",
            display: "flex",
            flexDirection: "column",
          }}
          className="menu-page"
        >
          <div style={{ flex: 1 }}>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/tax" element={<Tax />} />
              <Route path="/promo" element={<PromoCode />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/statistics" element={<Statistics />} />
            </Routes>
          </div>
          <div
            style={{
              //   backgroundColor: '#f8f8f8',
              // position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              height: 60,
              width: "100%",
              // boxShadow: '10px 10px 5px grey',
              //   borderTopWidth: 2,
              //   borderTopColor: 'red',
            }}
          >
            <div
              style={{
                height: 60,
                borderTopWidth: 2,
                borderTopColor: "red",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: 15,
              }}
            >
              <span
                onClick={() => selected("en")}
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  fontSize: 15,
                  fontWeight: i18n.language === "en" ? "bold" : "normal",
                  color: "#A2191B",
                }}
              >
                English
              </span>
              <span
                onClick={() => selected("de")}
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  fontSize: 15,
                  fontWeight: i18n.language === "de" ? "bold" : "normal",
                  color: "#A2191B",
                }}
              >
                German
              </span>
              <span
                onClick={() => selected("ur")}
                style={{
                  cursor: "pointer",
                  marginRight: 5,
                  fontSize: 15,
                  fontWeight: i18n.language === "ur" ? "bold" : "normal",
                  color: "#A2191B",
                }}
              >
                Urdu
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
