import {
  Close,
  Visibility,
  VisibilityOff,
  ArrowDownward,
  ArrowUpward,
  Edit,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteItem } from "../helperFunctions/DataHandlers";
import {
  editOptionPrice,
  editQuestionRequired,
  fetchMenu,
} from "../redux/actions/menu";
import CircularProgress from "@mui/material/CircularProgress";
import noImg from "./../assets/no-image.png";
import "./Custom.css";

import { useAlert } from "react-alert";
import { showPrice } from "../config/currency";

const MenuItem = ({ data, openEditModal, openEditItemPriceModal }) => {
  const [showQuestions, setShowQuestions] = React.useState(false);
  const [optionId, setOptionId] = React.useState(null);
  const [optionPrice, setOptionPrice] = useState(null);
  const [optionName, setOptionName] = useState("");
  const [ModalOpen, setModalOpen] = React.useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [display, setDisplay] = useState("");
  const [questionId, setQuestionId] = useState(null);
  const [questionName, setQuestionName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    maxWidth: "50%",
    minWidth: "40%",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    paddingRight: 8,
    maxHeight: "80%",
    overflowY: "auto",
    opacity: 1,
    visibility: "visible",
  };

  const handleOptionPrice = async () => {
    if (!loading) {
      setLoading(true);
      if (optionPrice > 0) {
        try {
          const data = {
            option_id: optionId.toString(),
            option_price: optionPrice,
          };
          await dispatch(editOptionPrice(data))
            .then(async (res) => {
              await dispatch(fetchMenu());
            })
            .catch((rej) => {
              console.log("Unable to Update Price");
            });
          setOptionPrice(null);
          setOptionId(null);
          setLoading(false);
          setModalOpen(false);
          alert.show("Option Price updated", {
            type: "success",
          });
        } catch (error) {
          let err =
            error?.message && error?.message.split(" ").slice(0, 2).join(" ");

          if (err && err === "connect ECONNREFUSED") {
            alert.show("Server is down please contact your server provider!", {
              type: "error",
            });
            setLoading(false);
            setModalOpen(false);
          } else {
            alert.show("Unable to Update Option Price", {
              type: "error",
            });
            setLoading(false);
            setModalOpen(false);
          }
        }
      } else {
        setLoading(false);
        alert.show("Option Price Must be greater than 0", {
          type: "error",
        });
      }
    }
  };

  const handleQuestionEdit = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const data = {
          question_id: questionId.toString(),
          is_required: isRequired,
        };
        await dispatch(editQuestionRequired(data))
          .then(async (res) => {
            await dispatch(fetchMenu());
            // console.log("Question Editted");
          })
          .catch((rej) => {
            // console.log("Unable to Edit Question");
          });
        setQuestionId(null);
        setQuestionName("");
        setIsRequired(false);
        setLoading(false);
        setQuestionModalOpen(false);
        alert.show("Question Status Updated", {
          type: "success",
        });
      } catch (error) {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
          setLoading(false);
          setQuestionModalOpen(false);
        } else {
          alert.show("Unable to Update Question", {
            type: "error",
          });
          setLoading(false);
          setQuestionModalOpen(false);
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log("Option Id : ", optionId);
  // }, [optionId])

  return (
    <div style={{ marginLeft: 25 }}>
      <div
        style={{
          backgroundColor: "white",
          width: 300,
          minHeight: 300,
          borderRadius: 15,
          boxShadow: "0px 0px 27px 11px rgba(0,0,0,0.04)",
          marginBottom: 60,
          marginleft: 5,
          marginRight: 5,
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              marginBottom: 5,
              paddingTop: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: 70,
                height: 70,
                borderRadius: 10,
                backgroundColor: "rgba(0,0,0,0.05)",
                marginLeft: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data?.image_link ? (
                <img
                  src={data.image_link}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 10,
                    objectFit: "cover",
                  }}
                  alt="item"
                />
              ) : (
                <img
                  src={noImg}
                  style={{
                    width: "70%",
                    height: "70%",
                    borderRadius: 10,
                    objectFit: "cover",
                    opacity: 0.5,
                  }}
                  alt="item"
                />
              )}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: 25,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                className="menu-item-title"
                style={{
                  fontSize: 16,
                  fontWeight: "500",
                }}
              >
                {data?.item_title}
              </Typography>
            </div>

            {data?.is_visible ? (
              <Visibility style={{ marginRight: 12, opacity: 0.3 }} />
            ) : (
              <VisibilityOff style={{ marginRight: 12, opacity: 0.5 }} />
            )}
          </div>
          <div style={{ margin: 10, height: 70 }}>
            <Typography
              className="menu-item-description"
              style={{
                fontSize: 12,
                fontWeight: "400",
                padding: "2px 5px",
                wordBreak: "break-word",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {data?.item_description}
            </Typography>
          </div>
        </div>
        {data?.questions && data?.questions.length > 0 ? (
          <div
            onClick={() => {
              setShowQuestions(!showQuestions);
            }}
            style={{
              cursor: "pointer",
              borderTop: "solid rgba(112, 112, 112,0.2) 1px",
              padding: 15,
              paddingBottom: 15,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: 15,
                fontWeight: "500",
                paddingTop: 5,
                lineHeight: 1,
              }}
            >
              Questions
            </Typography>
            {showQuestions ? <ArrowUpward /> : <ArrowDownward />}
          </div>
        ) : (
          <div style={{ height: 50 }} />
        )}

        {showQuestions && (
          <div
            style={{
              borderTop: "solid rgba(112, 112, 112,0.2) 1px",
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {data?.questions &&
              data?.questions.map((question, index) => {
                return (
                  <div
                    style={{
                      borderBottom:
                        data.questions.length - 1 === index
                          ? "none"
                          : "solid rgba(112, 112, 112,0.1) 1px",
                    }}
                  >
                    {/* {console.log("Questions in Menu Item: ", question)} */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "4px",
                        // marginTop: 5,
                      }}
                      onClick={() => {
                        setQuestionId(question.question_id);
                        setQuestionName(question.question_title);
                        setIsRequired(!!question.is_required);
                        setQuestionModalOpen(true);
                      }}
                      className="item-question"
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                          paddingTop: 5,
                          lineHeight: 1,
                        }}
                      >
                        {question.question_title}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "500",
                          lineHeight: 1,
                          marginTop: "inherit",
                        }}
                      >
                        {question.is_required === 1 ? (
                          <span
                            style={{
                              borderRadius: 5,
                              fontSize: 11,
                              fontWeight: "bold",
                              color: "white",
                              backgroundColor: "#A2191B",
                              border: "2px solid #7c7070",
                              padding: "3px 8px",
                            }}
                          >
                            * Required
                          </span>
                        ) : null}
                      </Typography>
                    </div>
                    {question.v_type === "checkbox" ? (
                      <FormGroup style={{ paddingLeft: 10, paddingTop: 5 }}>
                        {question.options.map((option, optionIndex) => {
                          return (
                            <FormControlLabel
                              disabled
                              // style={{ backgroundColor: "blue", width: "100%" }}
                              control={
                                <Checkbox
                                  style={{ padding: 2 }}
                                  defaultChecked
                                />
                              }
                              label={
                                <div
                                  style={{
                                    width: 250,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                  }}
                                  className="option-price"
                                >
                                  <Typography
                                    style={{ fontSize: 13, opacity: 0.7 }}
                                  >
                                    {option.option}
                                  </Typography>
                                  <Typography
                                    style={{
                                      marginLeft: 5,
                                      fontSize: 13,
                                      opacity: 0.8,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ({option.option_price})
                                  </Typography>
                                </div>
                              }
                              onClick={() => {
                                setOptionId(option.option_id);
                                setOptionPrice(option.option_price);
                                setOptionName(option.option);
                                setModalOpen(true);
                              }}
                            />
                          );
                        })}
                      </FormGroup>
                    ) : (
                      <FormControl style={{ paddingLeft: 10, paddingTop: 5 }}>
                        <RadioGroup
                          aria-label="gender"
                          name="radio-buttons-group"
                          value={
                            question.options.length > 0
                              ? question.options[0].option
                              : ""
                          }
                        >
                          {question.options.map((option, optionIndex) => {
                            return (
                              <FormControlLabel
                                value={option.option}
                                disabled
                                control={<Radio />}
                                label={
                                  <div
                                    style={{
                                      width: 235,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      cursor: "pointer",
                                    }}
                                    className="option-price"
                                  >
                                    <Typography
                                      style={{ fontSize: 13, opacity: 0.7 }}
                                    >
                                      {option.option}
                                    </Typography>
                                    <Typography
                                      style={{
                                        marginLeft: 5,
                                        fontSize: 13,
                                        opacity: 0.8,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ({option.option_price})
                                    </Typography>
                                  </div>
                                }
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOptionId(option.option_id);
                                  setOptionName(option.option);
                                  setOptionPrice(option.option_price);
                                  setModalOpen(true);
                                }}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </div>
                );
              })}
          </div>
        )}

        <div
          style={{
            borderTop: "solid rgba(112, 112, 112,0.2) 1px",
            padding: 15,
            paddingBottom: 15,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{ fontSize: 11, fontWeight: "500", opacity: 0.46 }}
            >
              <Edit
                onClick={() => openEditItemPriceModal(data.item_id)}
                sx={{ color: "#4CAF50" }}
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            </Typography>
            <Typography
              style={{
                fontSize: 15,
                fontWeight: "600",
                // paddingTop: 5,
                lineHeight: 1,
              }}
            >
              {data?.discounted_price &&
              data?.discounted_price !== data?.item_price ? (
                <p style={{ margin: "0px" }}>
                  {showPrice(data.discounted_price)}
                  <strike>{showPrice(data.item_price)}</strike>{" "}
                </p>
              ) : (
                <p style={{ margin: "0px" }}>{showPrice(data.item_price)}</p>
              )}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                cursor: "pointer",
                backgroundColor: "rgb(244, 67, 54,0.05)",
                padding: 5,
                border: "solid #F44336 2px",
                height: 25,
                width: 25,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}
              onClick={() => {
                // @TODO ask for confirmation using alert
                if (window.confirm("Sure! you want to delete Item?")) {
                  let payload = {
                    data: {
                      item_id: data.item_id.toString(),
                    },
                  };

                  deleteItem(payload)
                    .then((res) => {
                      try {
                        dispatch(fetchMenu());
                        alert.show("Successfully deleted !!!", {
                          type: "success",
                        });
                      } catch (error) {
                        alert.show(
                          "Something goes wrong while fetching data!",
                          {
                            type: "error",
                          }
                        );
                      }
                    })
                    .catch((err) => {
                      let error =
                        err?.message &&
                        err?.message.split(" ").slice(0, 2).join(" ");

                      if (error && error === "connect ECONNREFUSED") {
                        alert.show(
                          "Server is down please contact your server provider!",
                          {
                            type: "error",
                          }
                        );
                      } else {
                        alert.show(
                          "Something goes wrong while Deleting Item!",
                          {
                            type: "error",
                          }
                        );
                      }
                    });
                } else {
                  return;
                }
              }}
            >
              <Close sx={{ color: "#F44336" }} />
            </div>

            <div
              style={{
                cursor: "pointer",
                backgroundColor: "rgb(76, 175, 80,0.05)",
                padding: 5,
                border: "solid #4CAF50 2px",
                height: 25,
                width: 25,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                openEditModal(data.item_id);
              }}
            >
              <Edit sx={{ color: "#4CAF50" }} />
            </div>
          </div>
        </div>
      </div>
      {optionId && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={ModalOpen}
          onClose={() => setModalOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={ModalOpen}>
            <Box sx={style}>
              <div style={{ marginBottom: 15 }}>
                <Typography style={{ fontSize: 19, fontWeight: "500" }}>
                  Edit "{optionName}" Price
                </Typography>
              </div>
              <div style={{ marginBottom: 15 }}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  Option Price:
                </Typography>
                <input
                  className="inputStyleBlack"
                  value={optionPrice}
                  id="option-price-number"
                  type="number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setOptionPrice(e.target.value);
                    }
                  }}
                />
              </div>
              <button
                className="addButton noselect"
                onClick={() => {
                  handleOptionPrice();
                }}
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  margin: "20px auto",
                  marginBottom: 0,
                  textAlign: "center",
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Edit Option"
                )}
              </button>
            </Box>
          </Fade>
        </Modal>
      )}
      {questionId && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={questionModalOpen}
          onClose={() => setQuestionModalOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={questionModalOpen}>
            <Box
              sx={{
                ...style,
                minWidth: "30%",
                borderRadius: 3,
                // textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingRight: 5,
              }}
            >
              <div style={{ marginBottom: 15 }}>
                <Typography style={{ fontSize: 19, fontWeight: "500" }}>
                  Edit ({questionName}) Question
                </Typography>
              </div>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  Question Name:
                </Typography>
                <input
                  className="inputStyleBlack"
                  value={questionName}
                  id="question-title-name"
                  type="text"
                  onChange={(e) => {
                    setQuestionName(e.target.value);
                  }}
                /> */}
                <FormGroup style={{ marginLeft: 15 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRequired}
                        onChange={(e) => {
                          setIsRequired(e.target.checked);
                        }}
                      />
                    }
                    label=" Required !"
                  />
                </FormGroup>
              </div>
              <button
                className="addButton noselect"
                onClick={() => {
                  handleQuestionEdit();
                }}
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  margin: "20px auto",
                  marginBottom: 0,
                  textAlign: "center",
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Edit Question"
                )}
              </button>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

export default MenuItem;
