import { Close, Done, HourglassBottomOutlined } from "@mui/icons-material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import React from "react";
import "./OrderTicket.css";

function OrderTicket(props) {
  let statuses = {
    delivering: {
      color: "#4CAF50",
      background: "rgb(76, 175, 80,0.05)",
      icon: <LocalShippingOutlinedIcon sx={{ color: "#4CAF50" }} />,
    },
    delivered: {
      color: "#4CAF50",
      background: "rgb(76, 175, 80,0.05)",
      icon: <Done sx={{ color: "#4CAF50" }} />,
    },
    pending: {
      color: "#FFC107",
      background: "rgb(255, 193, 7,0.05)",
      icon: <HourglassBottomOutlined sx={{ color: "#FFC107" }} />,
    },
    rejected: {
      color: "#F44336",
      background: "rgb(244, 67, 54,0.05)",
      icon: <Close sx={{ color: "#F44336" }} />,
    },
    preparing: {
      color: "#F44336",
      background: "rgb(244, 67, 54,0.05)",
      icon: <LocalFireDepartmentOutlinedIcon sx={{ color: "#F44336" }} />,
    },
  };
  let selectedStatus = props.status || "delivered";

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: statuses[selectedStatus].background,
        alignItems: "center",
        border: `solid ${statuses[selectedStatus].color} 2px`,
        padding: 10,
        paddingBottom: 7,
        paddingTop: 7,
        borderRadius: 8,
        marginRight: 15,
        marginBottom: 10,
        width: "auto",
        height: 32,
        // cursor: "pointer",
      }}
      className="orderTicketContainer"
      key={props.id}
      onClick={props.handleClickScroll}
    >
      {statuses[selectedStatus].icon}
      <div style={{ paddingLeft: 5, color: statuses[selectedStatus].color }}>
        #{props.id}
      </div>
    </div>
  );
}

export default OrderTicket;
