import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import "bootstrap/dist/css/bootstrap.min.css";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard({
  MainIcon,
  num,
  text,
  ArrowIcon,
  arrowColor,
  percentage,
  percentColor,
}) {
  return (
    // <div className="me-2 ">
    <Card
      sx={{ width:'100%' }}
      style={{ backgroundColor: "rgb(248, 248, 248)", borderRadius: "15px" }}
      elevation={0}
    >
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "fit-content",
              padding: "10px",
              backgroundColor: "#A2191B",
              borderRadius: "5px",
            }}
          >
            {MainIcon && (
              <MainIcon fontSize="large" style={{ color: "white" }} />
            )}
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="body1"
              component="div"
              className="fw-bold mt-2 me-3"
              color={percentColor}
            >
              {percentage}
            </Typography>
            {ArrowIcon && <ArrowIcon fontSize="large" color={arrowColor} />}
          </div>
        </div>
        <Typography variant="h5" component="div" className="mt-3 fw-bold">
          {num}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" className="fw-bold">
          {text}
        </Typography>
      </CardContent>
    </Card>
    // </div>
  );
}
