import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { serverUrl } from "../../config";
import { SET_MENU, SET_TAXES } from "../constants";

export const fetchMenu = () => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  return await axios({
    url: "api/vendor/menu",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  })
    .then((res) => {
      console.log(res);

      dispatch({
        type: SET_MENU,
        payload: res.data.payload,
      });
      console.warn(res.data);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const fetchTaxes = () => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  await axios({
    url: "api/vendor/taxes",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  })
    .then((res) => {
      dispatch({
        type: SET_TAXES,
        payload: res.data.payload,
      });
      console.log(res.data);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const editOptionPrice = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  return await axios({
    url: "api/vendor/menu/optionPrice",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      // dispatch({
      //   type: SET_TAXES,
      //   payload: res.data.payload,
      // });
      // console.log("Edit Option Price Response: ", res.data);
      return res;
    })
    .catch((err) => {
      console.log("error:", err.response);
      throw err.response;
    });
};

export const editQuestionRequired = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  return await axios({
    url: "api/vendor/menu/questionRequired",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      // dispatch({
      //   type: SET_TAXES,
      //   payload: res.data.payload,
      // });
      // console.log("Edit Question Required Response: ", res.data);
      return res;
    })
    .catch((err) => {
      console.log("error:", err.response);
      throw err.response;
    });
};
