import React, { useEffect } from "react";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Form } from "react-bootstrap";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import OrderTicket from "../components/OrderTicket";
import OrderCard from "../components/OrderCard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../redux/actions/orders";
import { getBusinessConfig } from "../redux/actions/businessConfig";
import { updateStatus, getStatusCodes } from "../redux/actions/orders";
import { totalRevenueCalculator } from "../helperFunctions/TotalCalculator";
import { order_status } from "../constants/orderStatus";
import Invoice from "../components/Invoice/Invoice";
import { useAlert } from "react-alert";
import { getInvoice } from "../redux/actions/invoice";
import { checkRoute } from "../helperFunctions/routeHandler";
import { Spinner } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import noOrder from "./../assets/no-order.png";
import { getTax } from "../redux/actions/tax";
import "./HomePage.css";

function HomePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alert = useAlert();

  const allOrdersList = useSelector((state) => state.ordersReducer.ordersList);
  const business_config = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  const order_status_list = useSelector(
    (state) => state.ordersReducer.orderStatus
  );
  const business_id = useSelector((state) => state.authReducer.user.bid);
  const [pending, setPending] = useState([]);
  const [preparing, setPreparing] = useState([]);
  const [delivering, setDelivering] = useState([]);
  const [ongoingOrders, setOngoingOrders] = useState([]);

  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [loadingPrepare, setLoadingPrepare] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingDeliver, setLoadingDeliver] = useState(false);

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // ---------------- Sort Orders card according to latest order on top --------
  function compareDate(a, b) {
    if (
      new Date(a.creation_time).getTime() > new Date(b.creation_time).getTime()
    ) {
      return -1;
    }
    if (
      new Date(a.creation_time).getTime() < new Date(b.creation_time).getTime()
    ) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    let pen = allOrdersList
      .filter((a) => a.order_status.toLowerCase() === "pending")
      .sort((a, b) => a.creation_time > b.creation_time);
    let prep = allOrdersList
      .filter((a) => a.order_status.toLowerCase() === "preparing")
      .sort((a, b) => a.modified_on < b.modified_on);
    let del = allOrdersList
      .filter((a) => a.order_status.toLowerCase() === "delivering")
      .sort((a, b) => a.modified_on > b.modified_on);
    let ongoing = pen.concat(prep).concat(del);
    setPending(pen);
    setPreparing(prep);
    setDelivering(del);
    ongoing.sort(compareDate);
    setOngoingOrders(ongoing);
  }, [allOrdersList]);

  const modalHandler = (id) => {
    setOpen(true);
    setSelectedOrder(id);
    setSelectedStatus("");
  };

  useEffect(() => {
    checkRoute(dispatch);
  }, []);

  const statusHandler = () => {
    if (!loadingReject) {
      setLoadingReject(true);
      if (selectedStatus !== "" && selectedOrder !== "") {
        try {
          dispatch(
            updateStatus({
              order_id: selectedOrder,
              order_status: selectedStatus,
            })
          );
          setOpen(false);
          setSelectedStatus("");
          setSelectedOrder("");
          alert.show("Order Rejected Successfully!", {
            type: "success",
          });
          setLoadingReject(false);
        } catch (e) {
          let err = e?.message && e?.message.split(" ").slice(0, 2).join(" ");
          if (err && err === "connect ECONNREFUSED") {
            alert.show("Server is down please contact your server provider!", {
              type: "error",
            });
            setLoadingReject(false);
          } else {
            alert.show("Unable to Reject Order!", {
              type: "error",
            });
            setLoadingReject(false);
          }
        }
      } else {
        alert.show("Please select a status!", {
          type: "info",
        });
        setLoadingReject(false);
      }
    }
  };

  const prepareHandler = (id) => {
    if (!loadingPrepare) {
      setLoadingPrepare(true);
      try {
        dispatch(
          updateStatus({
            order_id: id,
            order_status: { id: 2, name: "Preparing" },
          })
        );
        setSelectedStatus("");
        setSelectedOrder("");
        alert.show("Order Accepted!", {
          type: "success",
        });
        setTimeout(() => {
          setLoadingPrepare(false);
        }, 2000);
      } catch (error) {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");
        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
          setLoadingPrepare(false);
        } else {
          alert.show("Unable to Accept Order!", {
            type: "error",
          });
          setLoadingPrepare(false);
        }
      }
    }
  };
  const fetchInvoiceData = async (orderId) => {
    try {
      await dispatch(getInvoice(orderId));
      setOpenInvoiceModal(true);
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Unable to generate Invoice!", {
          type: "error",
        });
      }
    }
  };

  const deliverHandler = async (id) => {
    if (!loadingDeliver) {
      setLoadingDeliver(true);
      try {
        await dispatch(
          updateStatus({
            order_id: id,
            order_status: { id: 4, name: "Delivered" },
          })
        );
        setSelectedStatus("");
        setSelectedOrder("");
        fetchInvoiceData(id);
        setLoadingDeliver(false);
        alert.show("Order Delivered!", {
          type: "success",
        });
      } catch (error) {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");
        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
          setLoadingDeliver(false);
        } else {
          alert.show("Unable to Update Order Status!", {
            type: "error",
          });
          setLoadingDeliver(false);
        }
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const getOrdersList = async () => {
    try {
      await dispatch(getAllOrders());
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Somrething went Wrong!", {
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    try {
      setLoader(true);
      getOrdersList();
      dispatch(getBusinessConfig());
      dispatch(getStatusCodes());
      dispatch(getTax());
      setLoader(false);
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");
      setLoader(false);

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Somrething went Wrong!", {
          type: "error",
        });
      }
    }
  }, []);

  function statusText(status) {
    return status.split("-")[0].trim();
  }

  const onChangeHandler = (val) => {
    setSelectedStatus(JSON.parse(val.target.value));
  };

  const totalRevenueGenerated = () => {
    let deliveredOrder = allOrdersList.filter(
      (order) => statusText(order.order_status).toLowerCase() !== "delivered"
    );
    return totalRevenueCalculator(deliveredOrder);
  };
  const handleClickScroll = (e, key) => {
    let tempKey = window.localStorage.getItem("key");
    window.localStorage.setItem("key", key);
    console.log("Runing scroll handler", key);
    let temp = document.getElementById(key);
    temp.style.boxShadow = "rgb(0, 0, 0,0.55) 0px 5px 20px";
    temp.scrollIntoView();
    if (tempKey) {
      console.log("tempKey", tempKey);
      if (tempKey !== key) {
        let previousCard = document.getElementById(tempKey);
        if (previousCard) {
          previousCard.style.boxShadow = "none";
        }
      }
    }
  };

  return (
    <div style={{ padding: 50 }}>
      {loader === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            width: "80vw",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "20%",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ height: "80px", width: "80px" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              backgroundColor: "#F8F8F8",
              padding: 15,
              borderRadius: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ margin: 15 }}>
              <Typography style={{ fontSize: 25, fontWeight: "500" }}>
                {business_config?.name}
              </Typography>
              <Typography
                style={{ fontSize: 13, fontWeight: "500", opacity: 0.6 }}
              >
                {`${business_config?.city},${business_config?.country}`}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                    backgroundColor: "#A2191B",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RoomServiceIcon sx={{ color: "white", fontSize: 50 }} />
                </div>
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: 30,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      opacity: 0.6,
                      lineHeight: 1,
                      paddingTop: 5,
                    }}
                  >
                    {t("Home:ordersDelivered")}
                  </Typography>
                  <Typography
                    style={{ fontSize: 35, fontWeight: "500", lineHeight: 1.3 }}
                  >
                    {
                      allOrdersList.filter(
                        (order) =>
                          statusText(order.order_status).toLowerCase() ===
                          "delivered"
                      ).length
                    }
                  </Typography>
                </div>

                <div
                  style={{
                    width: 6,
                    backgroundColor: "white",
                    height: 90,
                    marginRight: 30,
                    borderRadius: 5,
                  }}
                />
                <div
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                    backgroundColor: "#A2191B",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PendingActionsIcon sx={{ color: "white", fontSize: 50 }} />
                </div>
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: 30,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      opacity: 0.6,
                      lineHeight: 1,
                      paddingTop: 5,
                    }}
                  >
                    {t("Home:orderPending")}
                  </Typography>
                  <Typography
                    style={{ fontSize: 35, fontWeight: "500", lineHeight: 1.3 }}
                  >
                    {
                      allOrdersList.filter(
                        (order) =>
                          statusText(order.order_status).toLowerCase() ===
                          "pending"
                      ).length
                    }
                  </Typography>
                </div>
                <div
                  style={{
                    width: 6,
                    backgroundColor: "white",
                    height: 90,
                    marginRight: 30,
                    borderRadius: 5,
                  }}
                />
                <div
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                    backgroundColor: "#A2191B",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalFireDepartmentIcon
                    sx={{ color: "white", fontSize: 50 }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: 30,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      opacity: 0.6,
                      lineHeight: 1,
                      paddingTop: 5,
                    }}
                  >
                    {t("Home:orderPreparing")}
                  </Typography>
                  <Typography
                    style={{ fontSize: 35, fontWeight: "500", lineHeight: 1.3 }}
                  >
                    {
                      allOrdersList.filter(
                        (order) =>
                          statusText(order.order_status).toLowerCase() ===
                          "preparing"
                      ).length
                    }
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {/* Modal */}
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400, paddingTop: 6 }}>
              <h5 id="parent-modal-title">Please select a reason</h5>
              <Form>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                >
                  <option value="" disabled selected>
                    Select Reason
                  </option>
                  {order_status_list
                    .filter(
                      (status) =>
                        statusText(status.name).toLowerCase() === "rejected"
                    )
                    .map((status) => {
                      return (
                        <option value={JSON.stringify(status)}>
                          {status.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form>
              <div
                style={{ width: "100%", textAlign: "right", marginBottom: 5 }}
              >
                <Button
                  className="mt-4"
                  style={{
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => statusHandler()}
                  disabled={loadingReject || selectedStatus.length === 0}
                >
                  {loadingReject ? (
                    <CircularProgress color="danger" size={18} />
                  ) : (
                    "Reject"
                  )}
                </Button>
              </div>
            </Box>
          </Modal>
          {/*  */}
          {/* Invoice Modal */}
          <Modal
            open={openInvoiceModal}
            onClose={() => {
              setOpenInvoiceModal(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            style={{ overflow: "scroll" }}
          >
            <Box sx={{ ...style, width: 400, marginTop: "60px" }}>
              <Invoice
                onClose_handler={() => {
                  setOpenInvoiceModal(false);
                }}
              />
            </Box>
          </Modal>
          {/*  */}
          <div style={{ paddingTop: 25, paddingBottom: 15 }}>
            <Typography style={{ fontSize: 21, fontWeight: "500" }}>
              {t("Home:orderList")}
            </Typography>
          </div>
          <div className="onGoingOrders">
            {ongoingOrders.length
              ? ongoingOrders.map((order, index) => {
                  return (
                    <OrderTicket
                      key={order.order_id}
                      status={statusText(order.order_status).toLowerCase()}
                      id={order.order_id}
                      handleClickScroll={(e, key) =>
                        handleClickScroll(e, order.order_id)
                      }
                    />
                  );
                })
              : null}
          </div>
          {loader ? (
            <span>Loading...</span>
          ) : (
            <div
              style={{
                padding: 25,
                paddingLeft: 12,
                paddingRight: 12,
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: 'space-between',
              }}
            >
              {ongoingOrders.length ? (
                ongoingOrders.map((order, index) => (
                  <>
                    <OrderCard
                      key={order.order_id}
                      id={order.order_id}
                      order={order}
                      statusModalHandler={modalHandler}
                      statusPreparingHandler={prepareHandler}
                      statusDeliverHandler={deliverHandler}
                      loadingPrepare={loadingPrepare}
                      loadingDeliver={loadingDeliver}
                    />
                    {console.log(order)}
                  </>
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    height: "50vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={noOrder} alt="" style={{ width: "350px" }} />
                  <h5>You have no pending orders</h5>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HomePage;
