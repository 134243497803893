import React, { useState } from 'react';
import moment from 'moment';
import { Close, Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import Invoice from './Invoice/Invoice';
import { useDispatch, useSelector } from 'react-redux';
import {
  totalCalculator_tax,
  totalRevenueCalculator,
  itemAmountCalculator,
} from '../helperFunctions/TotalCalculator';
import { getInvoice } from '../redux/actions/invoice';
import CircularProgress from '@mui/material/CircularProgress';
import noImg from './../assets/no-image.png';

import { useAlert } from 'react-alert';
import { showPrice } from '../config/currency';

function OrderCard(props) {
  const businessConfig = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  // console.log("------", businessConfig);
  const { t } = useTranslation();
  const {
    order,
    statusModalHandler,
    statusPreparingHandler,
    statusDeliverHandler,
    loadingPrepare,
    loadingDeliver,
  } = props;
  const dispatch = useDispatch();
  const [promptModal, setPromptModal] = useState(false);
  const [id, setId] = useState('');

  const [open, setOpen] = useState(false);
  const alert = useAlert();

  let statuses = {
    delivering: {
      color: '#4CAF50',
      background: 'rgb(76, 175, 80,0.25)',
    },
    delivered: {
      color: '#4CAF50',
      background: 'rgb(76, 175, 80,0.25)',
    },
    pending: {
      color: '#FFC107',
      background: 'rgb(255, 193, 7,0.25)',
    },
    rejected: {
      color: '#F44336',
      background: 'rgb(244, 67, 54,0.25)',
    },
    preparing: {
      color: '#F44336',
      background: 'rgb(244, 67, 54,0.25)',
    },
  };
  let selectedStatus = order.order_status.toLowerCase() || 'delivered';

  function statusText(status) {
    return status.split('-')[0].trim();
  }

  const OrderTotal = (ord) => {
    // console.log("Order: ", ord);
    if (businessConfig.is_taxable) {
      return totalCalculator_tax(ord);
    } else {
      return totalRevenueCalculator([ord]);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '35px',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const itemHeadingStyle = {
    margin: '0px',
    width: '165px',
    wordBreak: 'break-all',
  };

  return (
    <div
      key={order.order_id}
      id={props.id}
      style={{
        width: 300,
        borderRadius: 15,
        marginBottom: 60,
        marginleft: 5,
        marginRight: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 27px 11px rgba(0,0,0,0.04)',
      }}
    >
      <div
        style={{
          padding: 15,
          paddingBottom: 5,
          borderBottom: 'solid rgba(112, 112, 112,0.2) 1px',
          backgroundColor: statuses[selectedStatus].background,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
      >
        <Typography style={{ fontSize: 15, fontWeight: '400', paddingTop: 5 }}>
          {t('Home:Order')} #{order.order_id}
        </Typography>
        {order.order_status.toLowerCase() === 'pending' ? (
          <Typography
            style={{
              fontSize: 11,
              fontWeight: '500',
              paddingTop: 5,
              paddingBottom: 5,
              opacity: 0.46,
            }}
          >
            {t('Home:orderPlaced')}{' '}
            {moment(order.creation_time).format('D MMMM hh:mm A')}
          </Typography>
        ) : (
          <Typography
            style={{
              fontSize: 11,
              fontWeight: '500',
              paddingTop: 5,
              paddingBottom: 5,
              opacity: 0.46,
            }}
          >
            {t('Home:orderModified')}{' '}
            {moment(order.modified_on).format('D MMMM hh:mm A')}
          </Typography>
        )}
        <Typography
          style={{
            fontSize: 11,
            fontWeight: '500',
            paddingTop: 5,
            paddingBottom: 5,
            lineHeight: 0.2,
            opacity: 0.46,
            marginBottom: 8,
          }}
        >
          {t('Home:contact')} {order?.contact_no}
        </Typography>
      </div>

      <div style={{ width: '100%', height: 250, overflowY: 'auto' }}>
        {Object.values(order.items).map((item) => {
          return (
            <div
              key={item.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  width: '70px',
                  height: '70px',
                  borderRadius: 10,
                  marginLeft: 10,
                  overflow: 'hidden',
                  backgroundColor: 'rgba(0,0,0,0.05)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {item.item_image ? (
                  <img
                    src={item.item_image}
                    alt=''
                    style={{ width: '100%', height: '100%' }}
                  />
                ) : (
                  <img
                    src={noImg}
                    alt=''
                    style={{ width: '60%', height: '60%', opacity: 0.5 }}
                  />
                )}
              </div>
              <div
                style={{
                  borderBottom: 'solid rgb(112, 112, 112,0.2) 1px',
                  flex: 1,
                  marginLeft: 25,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <p
                  className='item-heading'
                  style={{
                    ...itemHeadingStyle,
                    fontSize: 14,
                    fontWeight: '500',
                  }}
                >
                  {item.item}
                </p>

                {Object.values(item.options).length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                    }}
                  >
                    {Object.values(item.options).map((i, index) => {
                      return i.map((a, myIndex) => {
                        return (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography
                              key={myIndex}
                              style={{
                                fontSize: 11,
                                fontWeight: '500',
                                opacity: 0.46,
                              }}
                            >
                              {` ${a.answer}, `}
                              {/* {index + 1 !== i.length && ". "} */}
                            </Typography>
                          </div>
                        );
                      });
                    })}
                    <Typography
                      style={{
                        fontSize: 10,
                        fontWeight: '500',
                        opacity: 0.46,
                        wordBreak: 'break-word',
                        paddingRight: '10px',
                      }}
                    >
                      {item.instruction}
                      {/* {index + 1 !== i.length && ". "} */}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginRight: 15,
                    paddingTop: 10,
                  }}
                >
                  <Typography style={{ fontSize: 11, fontWeight: '400' }}>
                    {showPrice(itemAmountCalculator(item))}
                  </Typography>
                  <Typography style={{ fontSize: 11, fontWeight: '400' }}>
                    Qty: {item.item_qty}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}

        <div style={{ height: 80 }} />
      </div>
      <div
        style={{
          borderTop: 'solid rgba(112, 112, 112,0.2) 1px',
          padding: 15,
          paddingBottom: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography
            style={{ fontSize: 11, fontWeight: '500', opacity: 0.46 }}
          >
            x{Object.values(order.items).length} {t('Home:Items')}
          </Typography>
          <Typography
            style={{
              fontSize: 15,
              fontWeight: '600',
              paddingTop: 5,
              lineHeight: 1,
            }}
          >
            {showPrice(Math.ceil(OrderTotal(order)))}{' '}
            {businessConfig?.is_taxable ? (
              <span style={{ fontSize: 10, fontWeight: '300' }}>
                (incl. Tax)
              </span>
            ) : null}
          </Typography>
        </div>
        {/* Modal */}
        {open && (
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
            style={{ overflow: 'scroll' }}
          >
            <Box sx={{ ...style, width: 400, marginTop: '60px' }}>
              <Invoice />
            </Box>
          </Modal>
        )}
        {/* Modal End */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {statusText(order.order_status).toLowerCase() === 'pending' ? (
            <>
              <div
                onClick={() => {
                  statusModalHandler(order.order_id);
                }}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'rgb(244, 67, 54,0.05)',
                  padding: 5,
                  border: 'solid #F44336 2px',
                  height: 25,
                  width: 25,
                  borderRadius: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                <Close sx={{ color: '#F44336' }} />
              </div>

              <div
                disabled={loadingPrepare}
                onClick={() => {
                  statusPreparingHandler(order.order_id);
                  setId(order.order_id);
                }}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'rgb(76, 175, 80,0.05)',
                  padding: 5,
                  border: 'solid #4CAF50 2px',
                  height: 25,
                  width: 25,
                  borderRadius: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {loadingPrepare && id === order.order_id ? (
                  <CircularProgress color='success' size={12} />
                ) : (
                  <Done sx={{ color: '#4CAF50' }} />
                )}
              </div>
            </>
          ) : statusText(order.order_status).toLowerCase() === 'preparing' ? (
            <Button
              className='mt-4'
              style={{ color: 'green', borderColor: 'green' }}
              variant='outlined'
              onClick={() => {
                // statusDeliverHandler(order.order_id);
                setPromptModal(true);
              }}
            >
              Deliver
            </Button>
          ) : (
            <Button
              disabled={true}
              className='mt-4'
              style={{ color: 'red', fontSize: '11px' }}
            >
              {order.order_status}
            </Button>
          )}
        </div>
      </div>
      {/* Invoice Modal */}
      <Modal
        open={promptModal}
        onClose={() => {
          setPromptModal(false);
        }}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        style={{ overflow: 'scroll' }}
      >
        <Box sx={{ ...style, width: 500, marginTop: '30px', borderRadius: 4 }}>
          {/* <p> Please Select </p> */}
          <h3 className='mt-3'>Confirm #{order.order_id} delivered</h3>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className='mt-3 mx-2'
              style={{
                color: '#A2291B',
                borderColor: '#A2291B',
                textAlign: 'right',
              }}
              variant='outlined'
              onClick={() => {
                setPromptModal(false);
              }}
              disabled={loadingDeliver}
            >
              No
            </Button>
            <Button
              className='mt-3'
              style={{
                color: 'green',
                borderColor: 'green',
                textAlign: 'right',
              }}
              variant='outlined'
              onClick={() => {
                statusDeliverHandler(order.order_id);
              }}
              disabled={loadingDeliver}
            >
              {loadingDeliver ? (
                <CircularProgress color='success' size={18} />
              ) : (
                'Yes'
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default OrderCard;
