import logo from './logo.svg';
import './App.css';
import useWindowSize from './helperFunctions/useWindowSize';
import {
  HistoryOutlined,
  HomeOutlined,
  InsightsOutlined,
  ListAltOutlined,
  LogoutOutlined,
  PaymentOutlined,
  RestaurantMenuOutlined,
  SettingsOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useState } from 'react';
import Navigator from './navigation/Navigator';

function App() {
  const size = useWindowSize();

  return (
    <div
      style={{
        width: size.width,
        height: size.height,
        backgroundColor: '#A2191B',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Navigator />
      {/* <Dashboard /> */}
    </div>
  );
}

export default App;
