import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import SignUpPage from '../pages/SignUpPage';
import POSPage from '../pages/POSPage';
import PrivateRoute from '../route/PrivateRoute'


function Navigator() {
  const authenticatedRoutes = [];
  const unauthenticatedRoutes = [];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard/*" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path="/pos" element={
          <PrivateRoute>
            <POSPage />
          </PrivateRoute>
        } />
      </Routes>

      {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      {/* <Route path="*" element={<Dashboard />} /> */}
    </BrowserRouter>
  );
}

export default Navigator;
