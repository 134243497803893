import { Box, Grid, Popover, Switch, Typography } from "@mui/material";
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AddLocation, CopyAll, MoreTime, TvRounded } from "@mui/icons-material";
import { SketchPicker } from "react-color";
import ImageDropZone from "../components/ImageDropZone";
import { useTranslation } from "react-i18next";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  Circle,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBusinessConfig,
  getBusinessConfig,
} from "../redux/actions/businessConfig";
import { Button } from "react-bootstrap";
import { uploadImages } from "../helperFunctions/DataHandlers";
import { checkRoute } from "../helperFunctions/routeHandler";
import { useAlert } from "react-alert";
import CircularProgress from "@mui/material/CircularProgress";
// import { DateTimePicker } from "@material-ui/pickers";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { updateTemporaryClose } from "../redux/actions/businessConfig";
import CloseIcon from "@mui/icons-material/Close";

// Area Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import axios from "axios";
import { fetchAreasAll, setAreas } from "../redux/actions/addressInfo";

function ChangeMapView({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

function SettingsPage(props) {
  const dispatch = useDispatch();

  const defaultTimingStruture = [
    {
      dayName: "days:monday",
      times: [],
    },
    {
      dayName: "days:tuesday",
      times: [],
    },
    {
      dayName: "days:wednesday",
      times: [],
    },
    {
      dayName: "days:thursday",
      times: [],
    },
    {
      dayName: "days:friday",
      times: [],
    },
    {
      dayName: "days:saturday",
      times: [],
    },
    {
      dayName: "days:sunday",
      times: [],
    },
  ];
  // Leaflet Map setting
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    shadowSize: [20, 30],
    iconAnchor: [10, 15],
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  let center = [24.92304132975232, 67.08931582570135];
  let circleCenter = [24.92304132975232, 67.08931582570135];

  const [draggableMarker, setDraggableMarker] = useState(false);

  const markerRef = useRef(null);
  const circleRef = useRef(null);

  const business_config = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  console.log("business_config", business_config);

  // Input Field handler
  const [businessName, setBusinessName] = useState("");
  const [businessSubcategory, setBusinessSubcategory] = useState([]);

  const [businessUserName, setBusinessUserName] = useState("");
  const [businessContact, setBusinessContact] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [businessMinOrder, setBusinessMinOrder] = useState(0);
  const [businessPrimaryColor, setBusinessPrimaryColor] = useState("");
  const [businessSecondaryColor, setBusinessSecondaryColor] = useState("");
  //To store initial business timings and send these back to API in case of no change
  const [businessTimings, setBusinessTimings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timingsChanged, setTimingsChanged] = useState(false);

  const [location_cor, setLocation_cor] = useState([
    24.92304132975232, 67.08931582570135,
  ]);

  const [logoImage, setLogoImage] = useState([]);
  console.log("123123123123Logo", logoImage);
  const [coverImage, setCoverImage] = useState([]);

  const [deleteLogo, setDeleteLogo] = useState(false);
  const [deleteCover, setDeleteCover] = useState(false);
  const [closedTillTimeChanged, setClosedTillTimeChanged] = useState(false);
  const [selectedDate, handleDateChange] = useState("");
  const [closetillDate, setClosetillDate] = useState("");
  console.log("selectedDate", selectedDate);
  // Areas Setting
  const [data, setData] = useState(null);
  const [areasLoading, setAreasLoading] = useState(false);

  const alert = useAlert();

  const [operatingTimes, setOperatingTimes] = React.useState(
    defaultTimingStruture
  );
  console.log("deleting time slot 152", operatingTimes);
  console.log("Timings Changed: ", timingsChanged);

  useEffect(() => {
    console.log("BUsiness Config Useffect 155: ", business_config);
    if (business_config) {
      setBusinessName(business_config?.name);
      setBusinessSubcategory(business_config?.sub_category);
      setBusinessUserName(business_config?.username);
      setBusinessContact(business_config?.contact_number);
      setBusinessDescription(business_config?.description);
      setBusinessPrimaryColor(business_config?.primary_color);
      setBusinessSecondaryColor(business_config?.secondary_color);
      setBusinessMinOrder(business_config?.min_order_amount);
      setBusinessTimings(business_config?.timings);
      // setClosedTill(business_config?.closed_till);
      if (business_config?.closed_till !== null) {
        handleDateChange(
          moment(business_config?.closed_till)
            .subtract(10, "hours")
            .format("YYYY-MM-DD HH:mm:ss ")
        );
        console.log("Closed Till if: ", business_config?.closed_till);
      } else {
        handleDateChange(moment(new Date()).format("YYYY-MM-DD HH:mm:ss "));
        console.log("Closed Till else: ", business_config?.closed_till);
      }
      let businessTimesCompiled = JSON.parse(
        JSON.stringify(defaultTimingStruture)
      );
      console.log("deleting time slot 120", operatingTimes);
      for (let i = 0; i < operatingTimes?.length; i++) {
        for (let j = 0; j < business_config.timings.length; j++) {
          if (
            business_config.timings[j].day ===
            operatingTimes[i].dayName.split(":")[1]
          ) {
            businessTimesCompiled[i].times.push({
              start_time: business_config.timings[j].start_time,
              end_time: business_config.timings[j].end_time,
            });
            if (business_config.timings[j]?.is_closed === 1) {
              businessTimesCompiled[i].is_closed = 1;
            } else {
              businessTimesCompiled[i].is_closed = 0;
            }
          }
        }
      }
      console.log("deleting time slot 121", businessTimesCompiled);
      setOperatingTimes(businessTimesCompiled);

      setLocation_cor(Array.from([business_config?.lat, business_config?.lng]));
      // setBusiness_subCategory(business_config?.sub_category)

      if (business_config?.images?.logo?.length > 0) {
        fetch(business_config?.images?.logo[0])
          .then(async (response) => {
            let res = await response.blob();
            let file = new File([res], `logo${0}.png`, {
              type: "image/png",
            });
            let time = new Date().getTime();
            file["preview"] = `${response.url}?${time}`;
            file["path"] = `logo${0}.png`;
            setLogoImage([file]);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (business_config?.images?.carousel?.length > 0) {
        let carouselArr = [];
        business_config?.images?.carousel.map((c, i) => {
          fetch(c)
            .then(async (response) => {
              let res = await response.blob();
              let file = new File([res], `carousel${i}.png`, {
                type: "image/png",
              });
              let time = new Date().getTime();
              file["preview"] = `${response.url}?${time}`;
              file["path"] = `carousel${i}.png`;
              carouselArr.push(file);
              setCoverImage(Array.from(carouselArr));
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    }
  }, [business_config]);

  useEffect(() => {
    try {
      checkRoute(dispatch);
      dispatch(getBusinessConfig());
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Somrething went Wrong!", {
          type: "error",
        });
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAreasAll(business_config?.username))
      .then((res) => {
        let fetchedData = res?.data?.payload;
        let groupedAreas = fetchedData.reduce(
          (h, obj) =>
            Object.assign(h, { [obj.city]: (h[obj.city] || []).concat(obj) }),
          {}
        );
        setData(groupedAreas);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const updateConfig = async () => {
    let proceed = true;
    let tempDate = localStorage.getItem("closedTill");
    let tempClosedTill;
    if (tempDate) {
      tempClosedTill = tempDate;
    } else {
      tempClosedTill = null;
    }
    operatingTimes?.forEach((element) => {
      element?.times?.forEach((time) => {
        if (!timeValidate(time?.start_time) || !timeValidate(time?.end_time)) {
          alert.show("Operating Times Format Should be HH:MM!", {
            type: "error",
          });
          proceed = false;
        }
      });
    });
    if (proceed) {
      if (!loading) {
        setLoading(true);
        let primaryColorNew = businessPrimaryColor;
        let secondaryColorNew = businessSecondaryColor;

        if (typeof businessPrimaryColor === "object") {
          primaryColorNew = `rgba(${Object.values(
            businessPrimaryColor
          ).toString()})`;
        }
        if (typeof businessSecondaryColor === "object") {
          secondaryColorNew = `rgba(${Object.values(
            businessSecondaryColor
          ).toString()})`;
        }
        let timingObjDbStructure = [];
        if (timingsChanged) {
          for (let i = 0; i < operatingTimes?.length; i++) {
            if (operatingTimes[i].is_closed === 1) {
              timingObjDbStructure.push({
                day: operatingTimes[i].dayName.split(":")[1],
                start_time: "00:00",
                end_time: "23:59",
                is_closed: true,
              });
            } else {
              for (let j = 0; j < operatingTimes[i]?.times?.length; j++) {
                timingObjDbStructure.push({
                  day: operatingTimes[i].dayName.split(":")[1],
                  start_time: operatingTimes[i].times[j].start_time,
                  end_time: operatingTimes[i].times[j].end_time,
                  is_closed: false,
                });
              }
            }
          }
        } else {
          timingObjDbStructure = businessTimings?.map((time) => {
            if (time?.is_closed && time?.is_closed === 1) {
              return { ...time, is_closed: true };
            } else {
              return { ...time, is_closed: false };
            }
          });
        }
        console.log(" TImings Changed: 999 ", timingsChanged);
        console.log(" Timings Arary: 999 ", timingObjDbStructure);
        if (
          businessName !== "" &&
          businessUserName !== "" &&
          businessContact !== "" &&
          businessDescription !== ""
        ) {
          let is_logoChanged = false;

          if (logoImage?.length > 0) {
            is_logoChanged = logoImage[0].update ? logoImage[0].update : false;
            console.log("123123123123Logo", is_logoChanged);
          }

          let is_coverChanged = false;

          coverImage.map((c_image) => {
            if (c_image.update) {
              is_coverChanged = true;
              return;
            }
          });

          try {
            await dispatch(
              updateBusinessConfig({
                sub_category: JSON.parse(businessSubcategory),
                name: businessName,
                description: businessDescription,
                contact_number: businessContact,
                email: business_config?.email,
                admin_language: business_config?.admin_language,
                customer_language: business_config?.customer_language,
                primary_color: primaryColorNew,
                secondary_color: secondaryColorNew,
                min_order_amount: businessMinOrder,
                lat: location_cor[0],
                lng: location_cor[1],
                // is_taxable: !!business_config.is_taxable,
                is_virtual: !!business_config.is_virtual,
                free_delivery_amount: business_config.free_delivery_amount,
                timings: timingObjDbStructure,
                closed_till: tempClosedTill,
              })
            );
            if (is_logoChanged || deleteLogo) {
              await uploadImages(logoImage, "logo");
            }
            if (is_coverChanged || deleteCover) {
              await uploadImages(coverImage, "carousel");
            }

            await dispatch(getBusinessConfig());

            alert.show("Configuration Successfully Updated!", {
              type: "success",
            });
            setLoading(false);
          } catch (error) {
            let err =
              error?.message && error?.message.split(" ").slice(0, 2).join(" ");
            setLoading(false);
            if (err && err === "connect ECONNREFUSED") {
              alert.show(
                "Server is down please contact your server provider!",
                {
                  type: "error",
                }
              );
            } else {
              // console.log("Error: ", error);
              alert.show("Error while updating configuration", {
                type: "error",
              });
            }
          }
        } else {
          alert.show("Please Fill All Fields", {
            type: "error",
          });
          setLoading(false);
        }
      }
    }
  };

  const handleTemporaryClose = async () => {
    if (!loading) {
      setLoading(true);
      const tcYear = selectedDate.getFullYear();
      let tcMonth = selectedDate.getMonth() + 1;
      tcMonth = tcMonth <= 9 ? "0" + tcMonth : tcMonth;
      let tcDate = selectedDate.getDate();
      tcDate = tcDate <= 9 ? "0" + tcDate : tcDate;
      let tcHours = selectedDate.getHours();
      tcHours = tcHours <= 9 ? "0" + tcHours : tcHours;
      let tcMinutes = selectedDate.getMinutes();
      tcMinutes = tcMinutes <= 9 ? "0" + tcMinutes : tcMinutes;
      let tcSeconds = selectedDate.getSeconds();
      tcSeconds = tcSeconds <= 9 ? "0" + tcSeconds : tcSeconds;
      const closedTill =
        tcYear +
        "-" +
        tcMonth +
        "-" +
        tcDate +
        " " +
        tcHours +
        ":" +
        tcMinutes +
        ":" +
        tcSeconds;
      console.log("closeTill Date", closedTill);
      setClosetillDate(closedTill);
      if (closedTill) {
        window.localStorage.setItem("closedTill", closedTill);
      }
      await updateTemporaryClose({ closed_till: closedTill })
        .then((res) => {
          alert.show("Temporary Closed Time Updated!", {
            type: "success",
          });
        })
        .catch((err) => {
          alert.show("Unable to Update Temporary Closed Time", {
            type: "error",
          });
        });
      setLoading(false);
      setClosedTillTimeChanged(false);
    }
  };

  const eventHandlers = useMemo(
    () => ({
      drag() {
        const marker = markerRef.current;
        if (marker != null) {
          setLocation_cor(Object.values(marker.getLatLng()));
        }
      },
    }),
    []
  );
  const toggleDraggable = useCallback(() => {
    setDraggableMarker((d) => !d);
  }, []);

  /////////////////
  const { t } = useTranslation();

  const timeChangeHandler_start = (questionIndex, value) => {
    let array = operatingTimes;
    array[questionIndex].start_time = value;

    setOperatingTimes(Array.from(array));
  };

  const timeChangeHandler_end = (questionIndex, value) => {
    let array = operatingTimes;
    array[questionIndex].end_time = value;

    setOperatingTimes(Array.from(array));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [locationsArray, setLocationsArray] = useState([]);
  const open = Boolean(anchorEl);

  const handleDeleteSlot = (day, index, time, indexInner) => {
    console.log("deleting time slot 513", operatingTimes);
    let arrToModify = operatingTimes;
    arrToModify[index].times.splice(indexInner, 1);
    console.log("deleting time slot 516", arrToModify);
    setOperatingTimes([...arrToModify]);
  };

  function timeValidate(data) {
    let res = String(data)
      .toLowerCase()
      .match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/);
    // .match(/^\d{2}(:\d{2})/g);
    if (res) {
      return true;
    } else {
      return false;
    }
  }
  const toggleHandler = (city, id) => {
    let copy = { ...data };
    copy[city] = copy[city].map((entry) =>
      entry.id === id
        ? { ...entry, is_visible: entry?.is_visible === 1 ? 0 : 1 }
        : { ...entry }
    );

    setData(copy);
  };
  const updateAreas = () => {
    if (areasLoading) {
      return;
    }
    setAreasLoading(true);

    let payload = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        payload = [...payload, ...data[key]];
      });
    }

    dispatch(setAreas(business_config?.id || "1", payload))
      .then((res) => {
        console.log("res", res);
        alert.show("Areas Succesfully Updated!", {
          type: "success",
        });
        setAreasLoading(false);
      })
      .catch((err) => {
        alert.show("Something went wrong!", {
          type: "error",
        });

        setAreasLoading(false);
      });
  };

  // const [primaryColor, setPrimaryColor] = useState('rgb(0,0,25)');

  return (
    <div style={{ padding: 50, paddingTop: 25 }}>
      <div style={{ paddingTop: 25, paddingBottom: 15 }}>
        <Typography style={{ fontSize: 21, fontWeight: "500" }}>
          {t("Settings:settings")}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:businessName")}
          </Typography>
          <input
            placeholder=""
            className="inputStyleBlack"
            value={businessName}
            disabled
          />
        </Grid>

        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:businessUsername")}
          </Typography>
          <input
            placeholder=""
            className="inputStyleBlack"
            value={businessUserName}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:contactNumber")}
          </Typography>
          <input
            placeholder=""
            className="inputStyleBlack"
            onChange={(e) => {
              setBusinessContact(e.target.value);
            }}
            value={businessContact}
          />
        </Grid>

        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:minOrderAmount")}
          </Typography>
          <input
            placeholder=""
            className="inputStyleBlack"
            value={businessMinOrder}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              e.target.value >= 0 && setBusinessMinOrder(e.target.value);
            }}
            type="number"
          />
        </Grid>
      </Grid>

      {/* ------------- Closed Till Temporary Closed ----------- */}

      <Grid
        item
        md={6}
        style={{ marginTop: 0, display: "flex", alignItems: "center" }}
      >
        <div>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 15,
              marginRight: 15,
              marginBottom: 5,
            }}
          >
            Temporary Close Till
          </Typography>
        </div>
        <div
        // className="inputStyleBlack"
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              value={selectedDate}
              onChange={(val) => {
                handleDateChange(val);
                setClosedTillTimeChanged(true);
              }}
              ampm={false}
              format="yyyy-MM-dd HH:mm:SS"
              minDate={new Date(new Date().getTime() - 86400000)}
              maxDate={new Date("12-12-2032")}
            />
            {/* {console.log("Date Format: ", selectedDate)} */}
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <button
            onClick={() => {
              handleTemporaryClose();
            }}
            disabled={loading || !closedTillTimeChanged}
            style={{
              padding: 2,
              border: "1px solid #252422",
              color: "#252422",
              marginLeft: 30,
              borderRadius: 10,
              // marginTop: 25,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                margin: 4,
                textAlign: "center",
                color: "#252422",
                fontSize: "0.8rem",
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={14} />
              ) : (
                "Close Temporarily"
              )}
            </p>
          </button>
        </div>
      </Grid>

      <Typography
        style={{
          fontWeight: "bold",
          fontSize: 14,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {t("Settings:colorScheme")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:primaryColor")}
          </Typography>

          <SketchPicker
            color={businessPrimaryColor}
            onChange={(color) => {
              setBusinessPrimaryColor(color.rgb);
            }}
          />
        </Grid>

        <Grid item md={6}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:secondaryColor")}
          </Typography>

          <SketchPicker
            color={businessSecondaryColor}
            onChange={(color) => {
              setBusinessSecondaryColor(color.rgb);
            }}
          />
        </Grid>
      </Grid>

      <Grid container className="mt-4">
        <Grid item md={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:businessDescription")}
          </Typography>
          <textarea
            placeholder=""
            className="inputStyleBlack"
            style={{ minHeight: 100 }}
            onChange={(e) => {
              setBusinessDescription(e.target.value);
            }}
            value={businessDescription}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:coverImages")}
          </Typography>
          <ImageDropZone
            imageArr={coverImage}
            onChangeImages={(images) => {
              setCoverImage(images);
            }}
            maxFileLimit={5}
            name="carousel"
            onDeleteHandler={(name) => {
              if (name === "carousel") {
                setDeleteCover(true);
              } else {
                setDeleteLogo(true);
              }
            }}
            height={350}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {t("Settings:logoImage")}
          </Typography>
          <ImageDropZone
            imageArr={logoImage}
            onChangeImages={(images) => {
              if (logoImage.length === 0) {
                setDeleteLogo(false);
              }
              setLogoImage(images);
            }}
            maxFileLimit={1}
            name="logo"
            onDeleteHandler={(name) => {
              if (name === "carousel") {
                setDeleteCover(true);
              } else {
                setDeleteLogo(true);
              }
            }}
            height={350}
          />
        </Grid>
      </Grid>

      <Typography
        style={{
          fontWeight: "400",
          fontSize: 14,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {t("Settings:businessLocation")}
      </Typography>

      {/* leaflet Map */}
      <div style={{ height: "400px", width: "100%", overflow: "hidden" }}>
        <MapContainer
          center={location_cor}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: "50vh", width: "80vw" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ChangeMapView coords={location_cor} />
          <CircleMarker
            center={location_cor}
            pathOptions={{ color: "red" }}
            radius={60}
            ref={circleRef}
          ></CircleMarker>
          <Marker
            draggable={draggableMarker}
            eventHandlers={eventHandlers}
            position={location_cor}
            ref={markerRef}
          >
            <Popup minWidth={90}>
              <span onClick={toggleDraggable}>
                {draggableMarker
                  ? "Marker is draggable"
                  : "Click here to make marker draggable"}
              </span>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      {/*  */}
      {/* @TODO V2:Locations */}
      {/* <Grid container spacing={3} style={{ paddingTop: 10 }}>
        <Grid
          item
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              marginTop: 5,
              marginBottom: 5,
            }}
            variant="body1"
          >
            {t('Settings:locations')}:
          </Typography>

          <div
            style={{
              border: 'solid white 1px',
              borderRadius: 15,
              padding: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              let locationArr = locationsArray;
              locationArr.push({});
              setLocationsArray(JSON.parse(JSON.stringify(locationArr)));
            }}
          >
            <AddLocation sx={{ color: 'black', fontSize: 16 }} />
            <Typography
              variant="body2"
              style={{ color: 'black', padding: 5, paddingRight: 0 }}
            >
              {t('Settings:addLocation')}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {locationsArray.map((location, index) => {
        return (
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t('Settings:locationTitle')}
              </Typography>
              <input
                placeholder=""
                className="inputStyleBlack"
                onChange={(evt) => { }}
              />
            </Grid>

            <Grid item md={4}>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t('Settings:locationDeliveryRadius')}
              </Typography>
              <input
                placeholder=""
                className="inputStyleBlack"
                onChange={(evt) => { }}
              />
            </Grid>

            <Grid item md={4}></Grid>
          </Grid>
        );
      })} */}
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 14,
            marginTop: 5,
            marginBottom: 5,
          }}
          variant="body1"
        >
          {t("Settings:AreasManager")}:
        </Typography>
        {data &&
          Object.keys(data).map((city) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {city}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data[city].map((area) => {
                    return (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                            }}
                          >
                            {area.area}
                          </Typography>
                          <Switch
                            checked={!!area?.is_visible ? true : false}
                            onChange={() => {
                              toggleHandler(city, area.id);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        <button
          onClick={() => {
            updateAreas();
          }}
          style={{
            padding: 8,
            backgroundColor: "#252422",
            borderRadius: 10,
            marginTop: 25,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // cursor: "pointer",
          }}
          disabled={areasLoading}
        >
          <p
            style={{
              margin: 5,
              // cursor: "pointer",
              textAlign: "center",
              color: "white",
            }}
          >
            {areasLoading ? (
              <CircularProgress color="inherit" size={18} />
            ) : (
              t("Settings:updateAreas")
            )}
          </p>
        </button>
      </div>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: 14,
          marginTop: 5,
          marginBottom: 5,
        }}
        variant="body1"
      >
        {t("Settings:operatingTimes")}:
      </Typography>

      {operatingTimes &&
        operatingTimes.map((day, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={3}
              alignItems={"flex-start"}
              style={{ borderBottom: "solid rgba(0,0,0,0.1) 1px" }}
            >
              <Grid item md={2}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {t(day.dayName)}
                </Typography>
              </Grid>
              <Grid item md={5}>
                {day.times.map((time, indexInner) => {
                  return (
                    <Grid container spacing={3} alignItems={"center"}>
                      <Grid item md={6} style={{ paddingTop: 53 }}>
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                          }}
                        >
                          {t("Settings:startTime")}
                        </Typography>
                        <input
                          value={
                            operatingTimes[index].is_closed === 1
                              ? "00:00"
                              : time.start_time
                          }
                          placeholder="00:00"
                          className="inputStyleBlack"
                          disabled={
                            operatingTimes[index].is_closed === 1 ? true : false
                          }
                          style={{
                            backgroundColor: timeValidate(
                              operatingTimes[index].times[indexInner].start_time
                            )
                              ? "transparent"
                              : "#ff000d20",
                          }}
                          onKeyPress={(event) => {
                            if (
                              event.which === 58 &&
                              !event.target.value.includes(":") &&
                              event.target.value.length === 2
                            ) {
                            } else if (event.which < 48 || event.which > 57) {
                              event.preventDefault();
                            } else {
                              if (event.target.value.length === 2) {
                                event.preventDefault();
                              }
                            }
                          }}
                          onChange={(evt) => {
                            // if (timeValidate(evt.target.value)) {
                            // console.log("time validate");
                            if (evt.target.value.length < 6) {
                              // console.log(timeValidate(evt.target.value));
                              // setTimingsChanged(true);
                              let operatingTimesNew = JSON.parse(
                                JSON.stringify(operatingTimes)
                              );
                              operatingTimesNew[index].times[
                                indexInner
                              ].start_time = evt.target.value;
                              setOperatingTimes(operatingTimesNew);
                            }
                            // }
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            paddingTop: 10,
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteSlot(day, index, time, indexInner);
                              setTimingsChanged(true);
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                          }}
                        >
                          {t("Settings:endTime")}
                        </Typography>
                        <input
                          value={
                            operatingTimes[index].is_closed === 1
                              ? "23:59"
                              : time.end_time
                          }
                          placeholder="23:59"
                          className="inputStyleBlack"
                          disabled={
                            operatingTimes[index].is_closed === 1 ? true : false
                          }
                          style={{
                            backgroundColor: timeValidate(
                              operatingTimes[index].times[indexInner].end_time
                            )
                              ? "transparent"
                              : "#ff000d20",
                          }}
                          onKeyPress={(event) => {
                            if (
                              event.which === 58 &&
                              !event.target.value.includes(":") &&
                              event.target.value.length === 2
                            ) {
                            } else if (event.which < 48 || event.which > 57) {
                              event.preventDefault();
                            } else {
                              if (event.target.value.length === 2) {
                                event.preventDefault();
                              }
                            }
                          }}
                          onChange={(evt) => {
                            // if (timeValidate(evt.target.value)) {
                            if (evt.target.value.length < 6) {
                              // console.log("time validate");
                              setTimingsChanged(true);
                              let operatingTimesNew = JSON.parse(
                                JSON.stringify(operatingTimes)
                              );
                              operatingTimesNew[index].times[
                                indexInner
                              ].end_time = evt.target.value;
                              setOperatingTimes(operatingTimesNew);
                            }
                            // }
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item md={5}>
                <div
                  style={{
                    border: "solid white 1px",
                    borderRadius: 15,
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTimingsChanged(true);
                    let optTimes = operatingTimes;
                    optTimes[index].times.push({
                      start_time: "00:00",
                      end_time: "00:00",
                    });

                    setOperatingTimes(JSON.parse(JSON.stringify(optTimes)));
                  }}
                >
                  <MoreTime sx={{ color: "black", fontSize: 16 }} />
                  <Typography
                    variant="body2"
                    style={{ color: "black", padding: 5, paddingRight: 0 }}
                  >
                    {t("Settings:addAnotherSlot")}
                  </Typography>
                </div>

                <div
                  style={{
                    border: "solid white 1px",
                    borderRadius: 15,
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTimingsChanged(true);
                    let optTimes = operatingTimes;

                    let currentSlots = optTimes[index].times;
                    for (let i = 0; i < optTimes?.length; i++) {
                      optTimes[i].times = currentSlots;
                    }
                    setOperatingTimes(JSON.parse(JSON.stringify(optTimes)));
                  }}
                >
                  <CopyAll sx={{ color: "black", fontSize: 16 }} />
                  <Typography
                    variant="body2"
                    style={{ color: "black", padding: 5, paddingRight: 0 }}
                  >
                    {t("Settings:setForAllDays")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px 0",
                    marginLeft: "12px",
                  }}
                  onClick={() => {
                    setTimingsChanged(true);
                    let arr = operatingTimes;
                    arr[index].is_closed = arr[index].is_closed === 1 ? 0 : 1;
                    setOperatingTimes(JSON.parse(JSON.stringify(arr)));
                  }}
                >
                  <input
                    type="checkbox"
                    name="is_closed"
                    checked={
                      operatingTimes[index].is_closed === 1 ? true : false
                    }
                  />
                  <label
                    htmlFor="is_closed"
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    {" "}
                    Is Closed
                  </label>
                </div>
              </Grid>
            </Grid>
          );
        })}

      <Grid container justifyContent={"center"}>
        <Grid item xs={9} sm={6} md={4} lg={3} className="inputContainer">
          <button
            // disabled={true}
            onClick={() => {
              setClosedTillTimeChanged(false);
              updateConfig();
            }}
            style={{
              padding: 8,
              backgroundColor: "#252422",
              marginLeft: 30,
              borderRadius: 10,
              marginTop: 25,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // cursor: "pointer",
            }}
            disabled={deleteLogo || loading}
          >
            <p
              style={{
                margin: 5,
                // cursor: "pointer",
                textAlign: "center",
                color: "white",
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                t("Settings:updateDetails")
              )}
            </p>
          </button>
        </Grid>
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <ChromePicker
        disableAlpha
        color={primaryColor}
        onChange={(color) => {
          setPrimaryColor(color.hex);
        }}
        /> */}
      </Popover>
    </div>
  );
}
export default SettingsPage;

// export default GoogleApiWrapper({
//     apiKey: "AIzaSyAygZx6Zk0WfSkVCtCN2N4uekVcNMunVtc"
//   })(SettingsPage)
