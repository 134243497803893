import React from "react";
import OrderProgress from "./OrderProgress";
import OrderList from "./OrderList";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

function OrderScreen1stColumn() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1
          style={{
            textTransform: "uppercase",
            color: "#717171",
            marginLeft: 30,
          }}
        >
          Orders
        </h1>
        <MenuOpenIcon
          style={{ color: "#c0c0c0", marginRight: "35" }}
          fontSize="large"
        />
      </div>
      <OrderProgress />
      <OrderList />
    </div>
  );
}

export default OrderScreen1stColumn;
