/* eslint-disable import/no-anonymous-default-export */
export default {
    en:{
        body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
                filterTooltip: 'Filter'
            }
        },
        pagination:{
            labelDisplayedRows:"{from}-{to} of {count}"
        },
        toolbar:{
            exportName:"Export as CSV"
        }
    },
    de:{
        body: {
          emptyDataSourceMessage: 'Keine Einträge',
          addTooltip: 'Hinzufügen',
          deleteTooltip: 'Löschen',
          editTooltip: 'Bearbeiten',
          filterRow: {
            filterTooltip: 'Filter'
          },
          editRow: {
            deleteText: 'Diese Zeile wirklich löschen?',
            cancelTooltip: 'Abbrechen',
            saveTooltip: 'Speichern'
          }
        },
        grouping: {
          placeholder: 'Spalten ziehen ...',
          groupedBy: 'Gruppiert nach:'
        },
        header: {
          actions: 'Aktionen'
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} von {count}',
          labelRowsSelect: 'Zeilen',
          labelRowsPerPage: 'Zeilen pro Seite:',
          firstAriaLabel: 'Erste Seite',
          firstTooltip: 'Erste Seite',
          previousAriaLabel: 'Vorherige Seite',
          previousTooltip: 'Vorherige Seite',
          nextAriaLabel: 'Nächste Seite',
          nextTooltip: 'Nächste Seite',
          lastAriaLabel: 'Letzte Seite',
          lastTooltip: 'Letzte Seite'
        },
        toolbar: {
          addRemoveColumns: 'Spalten hinzufügen oder löschen',
          nRowsSelected: '{0} Zeile(n) ausgewählt',
          showColumnsTitle: 'Zeige Spalten',
          showColumnsAriaLabel: 'Zeige Spalten',
          exportTitle: 'Export',
          exportAriaLabel: 'Export',
          exportName: 'Export als CSV',
          searchTooltip: 'Suche',
          searchPlaceholder: 'Suche'
        }
      }
  };
  