/* eslint-disable import/no-anonymous-default-export */
export default {
  Login: {
    startNow: "اب شروع کریں",
    loginToAccount: "آپ کے اکاؤنٹ میں لاگ ان کریں",
    emailAddress: "ای میل اڈریس",
    password: "پاس ورڈ",
    continueToDashboard: "لاگ ان کرین",
    forgetPassword: "اپنا پاس ورڈ بھول گئے؟",
    recoverNow: "ابھی بازیافت کریں۔",
  },
  Home: {
    restaurantName: "ریسٹورانٹ  کا نام",
    addressHere: "یہاں ایڈریس, کراچی, پاکستان.",
    ordersDelivered: "آرڈر ڈیلیورڈ",
    revenueGenerated: "آمدنی",
    orderList: "آرڈر کی فہرست",
    Order: "آرڈر",
    orderPlaced: "پر آرڈر دیا گیا۔",
    orderModified: "آرڈر میں ترمیم کی گئی۔",
    itemName: "شے کا نام ",
    extraCheese: "اضافی پنیر",
    Items: "اشیاء",
    orderPending: "آرڈر زیر التواء",
    orderPreparing: "آرڈر کی تیاری",
  },
  Orders: {
    orders: "آرڈرز",
    new: "نئی",
    preparing: "آرڈر تیار کیا جا رہا ہے",
    delivery: "ڈیلیوری",
    order: "آرڈر",
    orderAmount: "آرڈر کی رقم",
    declineOrder: "آرڈر رد کریں ",
    acceptOrder: "آرڈر قبول کریں",
  },
  orderHistory: {
    totalOrders: "کل آرڈرز",
    ordersPending: "آرڈرز زیر التواء",
    ordersDelivered: "آرڈرز ڈیلیورڈ",
    ordersRejected: "آرڈرز مسترد",
    orderReports: "آرڈر رپورٹس",
    recentOrders: "  حالیہ آرڈرز",
    name: "نام",
    orderId: "آرڈر آئی ڈی",
    status: "حالت",
    totalAmount: "کل رقم ",
    orderTime: "آرڈر کا وقت",
    actions: "اعمال",
    orderType: "آرڈر کی قسم",
  },
  Menu: {
    menuItems: "مینو آئٹم",
    errorMsg:
      "آپ نے ابھی تک کوئی آئٹم شامل نہیں کیا ہے، کیوں نہ اسے شامل کرکے شروع کریں؟",
    addNewCategory: "نئی قسم شامل کریں",
    deleteCategory: "قسم حذف کروایئ",
    categoryTitle: "قسم کا عنوان",
    categoryDescription: "قسم کی تفصیل",
    createCategory: "قسم بنائیں",
    addNew: "نیا شامل کریں",
    item: "آئٹم",
    itemName: "آئٹم کا نام",
    itemDescription: "آئٹم کی تفصیل ",
    itemImage: "آئٹم کی تصویر",
    itemCost: "آئٹم کی قیمت",
    enableDiscountedRates: "رعایتی شرح کو فعال کریں؟",
    discountedItemCost: "رعایتی آئٹم کی قیمت",
    questions: "سوالات",
    addNewQuestion: "نیا سوال شامل کریں",
    question: "سوال",
    addNewOption: "نئے آپشن شامل کریں۔",
    questionType: "سوال کی قسم ",
    option: "آپشن",
    PriceOptional: "قیمت (Optional)",
    linkedWith: "کے ساتھ منسلک",
    clickHereToUnlink: "لنک ختم کرنے کے لیے یہاں کلک کریں۔",
    similarNameError: "ہمیں نام کے ساتھ ملتی جلتی ایک آئٹم ملی",
    clickHere: "یہاں کلک کریں",
    toLinkOptions: "اس آپشن کے ساتھ آئٹم کو لنک کرنے کے لی",
    createItem: "آئٹم بنائیں",
    alertError1: "غلطی! براہ کرم قسم کا عنوان درج کریں۔",
    alertError2: "غلطی! ای میل ایڈریس بری طرح سے فارمیٹ کیا گیا ہے۔",
    alertError3: "غلطی! براہ کرم قسم کا عنوان درج کریں",
    alertError4: "غلطی! براہ کرم آئٹم کا نام درج کریں۔",
  },
  Settings: {
    settings: "ترتیبات",
    businessName: "کاروبار کا نام",
    businessUsername: "کاروباری صارف نام",
    contactNumber: "ابطہ نمبر",
    deliveryCharges: "ڈیلیوری چارجز",
    colorScheme: "رنگ سکیم",
    primaryColor: "بنیادی رنگ",
    secondaryColor: "ثانوی رنگ",
    businessDescription: "کاروباری تفصیل",
    coverImages: "کورتصاویر",
    updateCoverImages: "کور تصاویر کو اپ ڈیٹ کریں۔",
    updateLogoImages: "لوگو تصاویر کو اپ ڈیٹ کریں۔",
    logoImage: "لوگو کی تصویر",
    businessLocation: "کاروباری مقام",
    locations: "مقامات",
    addLocation: "مقام شامل کریں",
    locationTitle: "مقام کا عنوان",
    locationDeliveryRadius: "مقام کی ترسیل کا رداس",
    operatingTimes: "آپریٹنگ ٹائمز",
    startTime: "وقت آغاز",
    endTime: "اختتامی وقت",
    addAnotherSlot: "ایک اور سلاٹ شامل کریں۔",
    setForAllDays: "تمام دنوں کے لیے سیٹ کریں۔",
    updateDetails: "تفصیلات کو اپ ڈیٹ کریں۔",
    AreasManager: "علاقے کا انتظام",
    updateAreas: "علاقوں کو اپ ڈیٹ کریں۔",
  },
  SideBar: {
    home: "ہوم",
    orders: "آرڈر",
    orderHistory: "آرڈر کی تاریخ",
    menu: "مینو",
    statistics: "شماریات",
    settings: "ترتیبات",
    logout: "لاگ آوٹ",
    Tax: "ٹیکس",
    pos: "پی او ایس",
  },
  days: {
    monday: "پیر",
    tuesday: "منگل",
    wednesday: "بدھ",
    thursday: "جمعرات",
    friday: "جمعہ",
    saturday: "ہفتہ",
    sunday: "اتوار",
  },
  pos: {
    searchCategory: "زمرہ تلاش کریں",
    searchOpenOrder: "اوپن آرڈر تلاش کریں",
    searchItems: "اشیاء تلاش کریں",
    stayOpen: "کھلے رہیں",
    takeAway: "لے جاؤ",
    generatingOrder: "آرڈر تیار کیا جا رہا ہے",
    dineIn: "اندر کھانا",
    itemName: "شے کا نام",
    quantity: "مقدار",
    price: "قیمت",
    subtotal: "ذیلی کل",
    orderTotal: "کل آرڈر",
    backToAdmin: "بیک ٹو ایڈمن",
    logout: "لاگ آوٹ",
    all: "تمام",
    homeDelivery: "گھر تک ترسیل",
  },
  editItemModal: {
    price: "آئٹم کی قیمت",
    discountedPrice: "رعایتی قیمت",
    discountPos: "POS پر رعایت",
    discountOnline: "آن لائن ڈسکاؤنٹ",
    startDate: "شروع کرنے کی تاریخ",
    isVisible: "نظر آتا ہے",
  },
  tax: {
    tax: "ٹیکس",
    taxes: "ٹیکس",
    id: "آئی ڈی",
    name: "نام",
    rate: "شرح %",
    actions: "اعمال",
  },
};
