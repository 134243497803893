import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { serverUrl } from "../../config";
import { SET_PROMO, ADD_PROMO, DELETE_PROMO, EDIT_PROMO } from "../constants";

export const fetchPromo = () => async (dispatch) => {
    const token = window.localStorage.getItem('access_token')
    console.warn(token)
    await axios({
        url: "api/vendor/promocode",
        method: "GET",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
    }).then((res) => {
        dispatch({
            type: SET_PROMO,
            payload: res.data.payload,
        });
        console.warn(res.data)

        return res;
    })
        .catch((err) => {
            // console.log('error:', err);
            console.log("error:", err.response);
            throw err.response;
        });


};

export const addPromo = (data) => async (dispatch) => {
    const token = window.localStorage.getItem('access_token')
    console.warn(token)
    await axios({
        url: "api/vendor/promocode",
        method: "POST",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
        data,
    }).then((res) => {
        dispatch({
            type: ADD_PROMO,
            payload: res.data.payload,
        });
        console.warn(res)

        return res;
    })
        .catch((err) => {
            // console.log('error:', err);
            console.log("error:", err.response);
            throw err.response;
        });


};

export const deletePromo = (data) => async (dispatch) => {

    const token = window.localStorage.getItem('access_token')
    console.warn(token)
    await axios({
        url: "api/vendor/promocode",
        method: "DELETE",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
        data: { data: data },
    }).then((res) => {
        dispatch({
            type: DELETE_PROMO,
            payload: data.promocode_id,
        });
        console.warn(res)

        return res;
    })
        .catch((err) => {
            // console.log('error:', err);
            console.log("error:", err.response);
            throw err.response;
        });


};

export const editPromo = (data) => async (dispatch) => {

    const token = window.localStorage.getItem('access_token')
    console.warn(token)
    await axios({
        url: "api/vendor/promocode",
        method: "PUT",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
        data: { data: data },
    }).then((res) => {
        dispatch({
            type: EDIT_PROMO,
            payload: data,
        });
        console.warn(res)

        return res;
    })
        .catch((err) => {
            // console.log('error:', err);
            console.log("error:", err.response);
            throw err.response;
        });


};