import { SET_TOKEN, SET_USER, REMOVE_TOKEN, } from "../constants";

const initialState = {
  user: {},
  token: "",
  authenticated: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      localStorage.setItem('authenticated', JSON.stringify(true))
      return {
        ...state,
        user: action.payload,
        authenticated: true
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case REMOVE_TOKEN: {
      localStorage.setItem('authenticated', JSON.stringify(false))
      localStorage.setItem('access_token', JSON.stringify(''))
      return {
        ...state,
        user: {},
        token: "",
        authenticated: false
      };
    }
    default:
      return state;
  }
};

export default authReducer;
