import { GET_ALL_ORDERS, GET_ORDER_HISTORY, EDIT_STATUS, SET_ORDER_STATUS, GET_ORDER_BY_ID } from '../constants';

const initialState = {
  ordersList: [],
  orderHistory: [],
  orderStatus: []
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS: {
      return {
        ...state,
        ordersList: action.payload,
      };
    }
    case GET_ORDER_BY_ID: {
      return {
        ...state,
        ordersList: state.ordersList.concat(action.payload),
      };
    }
    case GET_ORDER_HISTORY: {
      return {
        ...state,
        orderHistory: action.payload,
      };
    }
    case EDIT_STATUS: {

      return {
        ...state,
        ordersList: state.ordersList.map(order => order.order_id === action.payload.order_id ? { ...order, order_status: action.payload.order_status.name, modified_on: new Date() } : order),
      };
    }
    case SET_ORDER_STATUS: {

      return {
        ...state,
        orderStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ordersReducer;
