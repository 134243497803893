import { useState, useEffect } from "react"
import Typography from '@mui/material/Typography';
import { Button, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import PromoTable from "../components/PromoTable";
import { useDispatch } from "react-redux";
import { withStyles } from "@mui/styles"
import { fetchPromo, addPromo, editPromo } from "../redux/actions/promo"


const styles = theme => ({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#A2191B !important`,
        }
    },
    cssFocused: {},
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#C4C4C4 !important'
    },
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#A2191B !important'
        }
    }, selected: {
        backgroundColor: "#f9c286 !important",
        color: "white",
        fontWeight: 600
    }

});

function PromoCode(props) {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(0)
    const [code, setCode] = useState('')
    const [maxVoucher, setMaxVoucher] = useState('')
    const [status, setStatus] = useState('Active')
    const [type, setType] = useState('AMOUNT')
    const [usagePerPerson, setUsagePerPerson] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [promoToEdit, setPromoToEdit] = useState('')

    const [maxDiscount, setMaxDiscount] = useState(0)



    useEffect(() => {
        dispatch(fetchPromo())
    }, [])


    const addPromoHandler = () => {
        if (dataValidation()) {

            const data = {
                amount,
                code,
                max_vouchers: maxVoucher ? maxVoucher : '',
                is_visible: status === "Active" ? "1" : "0",
                type,
                usage_person: usagePerPerson ? usagePerPerson : '',
                max_discount: maxDiscount
            }


            dispatch(addPromo({ data }))

            clearState()

        }
    }

    const editPromoHandler = (promo) => {
        setIsEdit(true)
        setPromoToEdit(promo)
        setInputState(promo)
    }

    const editPromoFunc = () => {
        let obj = {
            amount,
            code,
            max_vouchers: maxVoucher ? maxVoucher : '',
            is_visible: status === "Active" ? "1" : "0",
            type,
            usage_person: usagePerPerson ? usagePerPerson : '',
            promocode_id: promoToEdit.id.toString(),
            max_discount: maxDiscount
        }
        dispatch(editPromo(obj))

        clearState()
        setIsEdit(false)
    }

    const setInputState = (promo) => {
        setAmount(promo.amount)
        setCode(promo.code)
        setMaxVoucher(promo.max_vouchers)
        setStatus(promo.is_visible == 0 ? 'InActive' : "Active")
        setType(promo.type)
        setUsagePerPerson(promo.usage_person)
        setMaxDiscount(promo.max_discount)
    }

    const dataValidation = () => {
        if (maxVoucher === 0 || usagePerPerson === 0) {
            alert('Max Voucher && Usage Per Person Filed Cannot Be 0')
            return false
        } else if (type === 'PERCENTAGE' && amount < 1 || type === 'PERCENTAGE' && amount > 100) {
            alert('Amount should be in between 1-100')
            return false
        } else if (type === 'AMOUNT' && amount <= 0) {
            alert("Amount should be greater than 0")
            return false
        } else if (type === 'PERCENTAGE' && maxDiscount <= 0) {
            alert("Please Fill Max Discount Field")
            return false
        } else if (code === '' || code.length < 2) {
            alert("Code Field Must Contain Atleast 2 Character")
            return false
        } else if (amount < 0 || maxVoucher < 0 || usagePerPerson < 0 || maxDiscount < 0) {
            alert('Fileds Can not be a negative value')
            return false
        } else {
            return true
        }
    }

    const clearState = () => {
        setAmount(0)
        setCode('')
        setMaxVoucher('')
        setStatus('Active')
        setType("AMOUNT")
        setUsagePerPerson('')
        setMaxDiscount(0)
    }


    const { classes } = props;

    return (
        <div style={{ padding: 50 }}>

            <Typography gutterBottom variant="h5" component="div">
                Add Promo Code
            </Typography>
            <Grid container spacing={5} className='mb-3'>
                <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Amount
                    </Typography>
                    <TextField
                        size='small'
                        placeholder="Enter Amount"
                        className="inputStyleBlack"
                        onChange={(e) => { setAmount(e.target.valueAsNumber) }}
                        type="number"
                        value={amount}
                        InputLabelProps={{
                            classes: {
                                focused: classes.cssFocused,
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                            inputMode: "numeric"
                        }}
                    />
                </Grid>
                <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Code
                    </Typography>
                    <TextField
                        size='small'
                        placeholder="Enter Code"
                        className="inputStyleBlack"
                        value={code}
                        onChange={(e) => { setCode(e.target.value) }}
                        type="text"
                        InputLabelProps={{
                            classes: {
                                focused: classes.cssFocused,
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },

                        }}
                    />
                </Grid>
                <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Max Voucher
                    </Typography>
                    <TextField
                        size='small'
                        placeholder="Max Voucher"
                        className="inputStyleBlack"
                        onChange={(e) => { setMaxVoucher(e.target.valueAsNumber) }}
                        type="number"
                        value={maxVoucher}
                        InputLabelProps={{
                            classes: {
                                focused: classes.cssFocused,
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                            inputMode: "numeric"
                        }}
                    />
                </Grid>

            </Grid>
            <Grid container spacing={5} className='mb-3'>
                <Grid item md={4} sm={6}>
                    <FormControl fullWidth >
                        <Typography
                            style={{
                                fontWeight: '400',
                                fontSize: 14,
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            Type
                        </Typography>
                        <Select
                            onChange={(e) => {
                                setType(e.target.value)
                                setMaxDiscount(null)
                            }}
                            size='small'
                            value={type}
                            className={classes.root}
                        >
                            <MenuItem value='PERCENTAGE' classes={{ selected: classes.selected }}>PERCENTAGE</MenuItem>
                            <MenuItem value='AMOUNT' classes={{ selected: classes.selected }}>AMOUNT</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Usage Per Person
                    </Typography>
                    <TextField
                        size='small'
                        placeholder="Usage Per Person "
                        className="inputStyleBlack"
                        onChange={(e) => { setUsagePerPerson(e.target.valueAsNumber) }}
                        type="number"
                        value={usagePerPerson}
                        InputLabelProps={{
                            classes: {
                                focused: classes.cssFocused,
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                            inputMode: "numeric"
                        }}
                    />
                </Grid>
                <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Status
                    </Typography>
                    <Button variant="contained" style={{ width: '100%', backgroundColor: status === 'Active' ? '#A2191B' : '#f9c286' }} onClick={() => {
                        status === 'Active' ? setStatus('InActive') : setStatus('Active')
                    }}>{status}</Button>
                </Grid>
            </Grid>
            <Grid container spacing={5} className='mb-3' >
                {type === 'PERCENTAGE' && <Grid item md={4} sm={6}>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        Max Discount
                    </Typography>
                    <TextField
                        size='small'
                        placeholder=" Max Discount"
                        className="inputStyleBlack"
                        onChange={(e) => { setMaxDiscount(e.target.valueAsNumber) }}
                        type="number"
                        value={maxDiscount}
                        InputLabelProps={{
                            classes: {
                                focused: classes.cssFocused,
                            },
                        }}

                    />
                </Grid>}
                <Grid item md={3} className='my-4' sm={6} >
                    <Button variant="contained" style={{ width: '100%', backgroundColor: "#A2191B" }} onClick={() => {
                        if (isEdit) {
                            editPromoFunc()
                        } else {
                            addPromoHandler()
                        }
                    }}>{!isEdit ? 'Add Promo' : "Edit Promo"}</Button>
                </Grid>
            </Grid>
            <div className="mt-4">
                <PromoTable edit={editPromoHandler} />
            </div>
        </div >
    )
}

export default withStyles(styles)(PromoCode)
