
const initialState = {
    selected_nav: "SideBar:home"
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_NAV": {
            return {
                ...state,
                selected_nav: action.payload,
            };
        }
        case "DEFAULT_NAV": {
            return {
                ...state,
                selected_nav: "SideBar:home"
            };
        }
        default:
            return state;
    }
};

export default sidebarReducer;
