import { useState } from "react";
import "./Invoice.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import {
  totalRevenueCalculator,
  totalCalculator_tax,
  itemAmountCalculator,
} from "../../helperFunctions/TotalCalculator";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { DELETE_INVOICE } from "../../redux/constants";
import { delivery_type } from "../../constants/orderType";
import { useAlert } from "react-alert";
import { showPrice } from "../../config/currency";

function Invoice({ onClose_handler, taxDetail }) {
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  console.log("taxDetail={taxDetail}", taxDetail);

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const invoiceData = useSelector((state) => state.invoiceReducer.invoice);

  console.log("invoiceData", invoiceData);

  const config = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );

  let data = {
    logo: config?.images?.logo?.[0],
  };

  // Download Pdf Function

  const downloadPDF = () => {
    setLoading(true);
    let ele = document.getElementById("ticket");
    console.warn(ele.innerHTML);
    html2canvas(ele).then(function (canvas) {
      console.warn(canvas);
      let h = canvas.height + 150;
      let doc = new jsPDF("p", "mm", [80, h * 0.264583]);
      let c = canvas.toDataURL("image/jpeg");
      // console.log("Data Url In Canvas: ", c);
      doc.addImage(c, "PNG", 10, 10);
      //In order to print doc
      doc.autoPrint();
      //This is a key for printing
      doc.output("dataurlnewwindow");
      onClose_handler();
      // dispatch({ type: DELETE_INVOICE });
      setLoading(false);
    });
  };

  // Rendering Html Fucntion

  const renderHtml = (data) => {
    const totalItemsCost = totalRevenueCalculator([invoiceData]);
    const totalItemsCost_tax = totalCalculator_tax(invoiceData);

    const grandTotal = 1000;
    const {
      customer,
      invoice_id,
      order_id,
      order_type_id,
      creation_time,
      complete_address,
      area,
      items,
      delivery_charges,
    } = invoiceData;

    toDataURL(data.logo, function (dataUrl) {
      // console.log('RESULT:', dataUrl);
      setLogo(dataUrl);
    });

    const itemNameStyle = {
      margin: "0px",
      width: "165px",
      wordBreak: "break-word",
    };

    return (
      <div id="ticket">
        <div className="logoContainer">
          {data?.logo ? <img src={logo} alt="" /> : <h6>{config?.name}</h6>}
        </div>
        <h2 className="centered">SALES RECEIPT</h2>
        <div className="info_view">
          <div className="rowFlex">
            <span>Customer : </span>
            <span className="info_value">{customer}</span>
          </div>
          <div className="rowFlex">
            <span>Invoice Number : </span>
            <span className="info_value">{invoice_id}</span>
          </div>
          <div className="rowFlex">
            <span>Order Id : </span>
            <span className="info_value">{order_id}</span>
          </div>
          <div className="rowFlex">
            <span>Order Type : </span>
            <span className="info_value">{delivery_type[order_type_id]}</span>
          </div>
          <div className="rowFlex">
            <span>Order Creation : </span>
            <span className="info_value">
              {moment(creation_time).format("DD/MM/YYYY hh:mm:ss")}
            </span>
          </div>
          {invoiceData?.customer === "POS - HOTSPOT" ? (
            <span></span>
          ) : (
            <div className="rowFlex_">
              <div style={{ width: "200px" }}>Address : </div>
              <div
                className="info_value"
                style={{ textAlign: "right", width: "100%" }}
              >
                {complete_address} <br /> {area}
              </div>
            </div>
          )}
        </div>
        <table
          style={{
            width: "200px",
            maxWidth: "200px",
          }}
        >
          <thead>
            <tr
              style={{
                width: "200px",
                maxWidth: "200px",
              }}
            >
              <th className="quantity">Qty.</th>
              <th className="description">Items Name</th>
              <th className="price">Price</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(items).map((item, i) => (
              <tr
                key={i}
                style={{
                  width: "200px",
                  maxWidth: "200px",
                  marginTop: 5,
                }}
              >
                <td className="quantity">{item.item_qty}</td>
                <td
                  className="description"
                  style={{ ...itemNameStyle, lineHeight: 1, paddingTop: 5 }}
                >
                  {item.item}
                  <br />
                  {Object.entries(item?.options).map((value) => {
                    console.log("values", value[0]);
                    return (
                      <>
                        <span style={{ fontSize: 8, fontWeight: "bold" }}>
                          {`${value[0]} : `}
                        </span>
                        {value[1]?.map((ele) => {
                          return (
                            <span style={{ fontSize: 8 }}>
                              {ele?.answer + " " + ele?.option_price + ". "}
                            </span>
                          );
                        })}
                      </>
                    );
                  })}
                  <br />
                  <p className="instruction">{item.instruction}</p>
                </td>
                <td className="price">
                  {" "}
                  {showPrice(itemAmountCalculator(item))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <div className="rowFlex_">
          <b>Sub Total</b>
          <span className="invoice-price">
            <span className="price-amount">
              {showPrice(totalItemsCost - delivery_charges)}{" "}
            </span>
          </span>
        </div>
        {config?.is_taxable ? (
          <div className="rowFlex_">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <b> Tax </b>
              {taxDetail &&
                taxDetail?.length > 0 &&
                taxDetail?.map((ele) => (
                  <span style={{ fontSize: "10px", marginBottom: "5px" }}>
                    {`${ele.name} ( ${ele.rate}%)`}
                  </span>
                ))}
            </div>
            <span className="invoice-price">
              <span className="price-amount">
                {showPrice(Math.ceil(totalItemsCost_tax - totalItemsCost))}
              </span>
            </span>
          </div>
        ) : null}
        {delivery_charges !== null && (
          <>
            <div className="rowFlex_">
              <b> Delivery Charges</b>
              <span className="invoice-price">
                <span className="price-amount">
                  {showPrice(delivery_charges)}
                </span>
              </span>
            </div>
            <hr />
          </>
        )}

        <div className="rowFlex_">
          <b>
            <span styles={{ fontSize: "18px" }}> Total</span>
          </b>
          <span className="invoice-price">
            <span className="price-amount">
              {showPrice(
                config?.is_taxable
                  ? Math.ceil(totalItemsCost_tax)
                  : Math.ceil(totalItemsCost)
              )}
            </span>
          </span>
        </div>
        <hr />
        <p className="centered_">Thanks for your purchase!</p>
      </div>
    );
  };

  return (
    <>
      {renderHtml(data)}
      <button
        disabled={loading}
        className="print_button"
        onClick={() => {
          downloadPDF();
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          "Print Invoice"
        )}
      </button>
    </>
  );
}

export default Invoice;
