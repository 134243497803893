export function totalCalculator_tax(order) {
  let totalCost = 0;
  let deliveryCharges = 0;
  let discountPrice = 0;
  let totalDiscount = 0;
  let tax = 0;
  // return totalCost + OptCost
  Object.values(order.items).forEach((item) => {
    let total = item.item_qty * item.actual_price;
    discountPrice += item.item_qty * item.item_price;
    let optTotalCost = 0;
    Object.values(item.options).forEach((arr) => {
      arr.forEach((ob) => {
        optTotalCost += ob.option_price * item.item_qty;
        discountPrice += ob.option_price * item.item_qty;
      });
    });
    deliveryCharges = order.delivery_charges;

    tax += Math.ceil(((total + optTotalCost) * item.tax_rate) / 100);
    totalCost += total + optTotalCost;
  });
  totalDiscount = totalCost - discountPrice;
  totalCost += deliveryCharges;
  return Math.ceil(totalCost + tax - totalDiscount);
}

export function totalRevenueCalculator(ordersList) {
  let totalCost = 0;
  let deliveryCharges = 0;
  let discountPrice = 0;
  let totalDiscount = 0;
  if (ordersList.length > 0) {
    ordersList.forEach((order) => {
      // return totalCost + OptCost
      Object.values(order.items).forEach((item) => {
        discountPrice += item.item_qty * item.item_price;
        let total = item.item_qty * item.actual_price;
        let optTotalCost = 0;

        Object.values(item.options).forEach((arr) => {
          arr.forEach((ob) => {
            optTotalCost += ob.option_price * item.item_qty;
            discountPrice += ob.option_price * item.item_qty;
          });
        });
        deliveryCharges = order.delivery_charges;
        totalCost += total + optTotalCost;
      });
    });
    totalDiscount = totalCost - discountPrice;
    totalCost += deliveryCharges;
  }
  return Math.ceil(totalCost - totalDiscount);
}

export function itemAmountCalculator(item) {
  let totalCost = 0;
  let total = item.item_qty * item.item_price;
  let optTotalCost = 0;
  Object.values(item.options).forEach((arr) => {
    arr.forEach((ob) => {
      optTotalCost += ob.option_price * item.item_qty;
    });
  });
  totalCost += total + optTotalCost;
  return Math.ceil(totalCost);
}
