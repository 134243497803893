import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Sales By Month',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', "August", "September", "October", "November", "December"];

export const data = {
    labels,
    datasets: [
        {
            label: 'Sales By Month Data',
            data: [300, 450, 200, 650, 700, 386, 297, 456, 987, 235, 730, 920],
            borderColor: '#A2191B',
            backgroundColor: '#A2191B)',
        },

    ],
};


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function SalesMonthChart() {
    return <Line options={options} data={data} />;
}

export default SalesMonthChart




