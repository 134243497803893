import axios from "axios";
import { serverUrl } from "../../config";
import { SET_BUSINESS_CONFIG } from "../constants";

export const getBusinessConfig = () => async (dispatch, getState) => {
  const token = window.localStorage.getItem("access_token");
  return axios({
    url: `api/vendor/config`,
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  })
    .then((res) => {
      let data = {
        ...res?.data?.payload,
        images: JSON.parse(res?.data?.payload?.images),
      };

      dispatch({
        type: SET_BUSINESS_CONFIG,
        payload: data,
      });

      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const updateBusinessConfig = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  return axios({
    url: "api/vendor/config",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      dispatch({
        type: SET_BUSINESS_CONFIG,
        payload: res.data.payload,
      });

      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const updateTemporaryClose = async (data) => {
  const token = window.localStorage.getItem("access_token");
  return axios({
    url: "api/vendor/temporaryClose",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      console.log("data", res);
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};
