import React, { useEffect, useState } from "react";
import { Add, Close, Edit } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  InputLabel,
  Typography,
  MenuItem as MItem,
  Grid,
} from "@mui/material";
import OrderTicket from "../components/OrderTicket";
import OrderCard from "../components/OrderCard";
import noItem from "./../assets/empty.png";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import "./MenuPage.css";
import MenuItem from "./../components/MenuItem";
import AddMenuItem from "../components/AddMenuItem";
import ImageDropZone from "../components/ImageDropZone";
import {
  createCategory,
  createItem,
  deleteCategory,
  editItem,
  editItemPrice,
  getItemFromOptions,
  uploadImages,
} from "../helperFunctions/DataHandlers";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenu, fetchTaxes } from "../redux/actions/menu";
import { getQuestionTypes } from "../redux/actions/config";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal as BootstrapModal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useAlert } from "react-alert";
import { checkRoute } from "../helperFunctions/routeHandler";
import EditItemPirceModal from "../components/EditItemPirceModal";
import { getCategories } from "../redux/actions/category";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  maxWidth: "50%",
  minWidth: "40%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  paddingRight: 8,
  maxHeight: "80%",
  overflowY: "scroll",
  opacity: 1,
  visibility: "visible",
};

function MenuPage() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [createItemModal, setCreateItemModal] = useState(false);
  const [menu, setMenu] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [itemName, setItemName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [itemDescription, setItemDescription] = useState("");
  const [itemCost, setItemCost] = useState("");
  const [itemDiscountedCost, setItemDiscountedCost] = useState("");
  const [discountOnPOS, setDiscountOnPOS] = useState(false);
  const [discountOnOnline, setDiscountOnOnline] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedTaxId, setSelectedTaxId] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [priceDateError, setPriceDateError] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [itemPrices, setItemPrices] = useState([]);
  const [addDiscount, setAddDiscount] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditPriceModal, setOpenEditPriceModal] = useState(false);
  const [itemToEditId, setItemToEditId] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isDoubleOption, setIsDoubleOption] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [addMenuError, setAddMenuError] = useState({
    error: false,
    errorType: "",
  });
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const handleCategoryOpen = () => setCategoryModalOpen(true);
  const handleCategoryClose = () => setCategoryModalOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleItemClose = () => {
    setCreateItemModal(false);
    setIsDoubleOption(false);
    setProductImage([]);
    setQuestions([]);
  };
  const [isTitle, setIsTitle] = useState(false);
  const listKeys = (Math.random() + 1).toString(36).substring(7);
  const date = new Date().getTime();
  const listKey = 123;

  const alert = useAlert();

  const dispatch = useDispatch();
  const menuRedux = useSelector((state) => state.menuReducer.menu);

  // console.log("menuRedux", menuRedux);
  const questionTypes = useSelector(
    (state) => state.configReducer.questionOptions
  );
  const taxesTypes = useSelector((state) => state.menuReducer.taxes);
  const reduxCategories = useSelector(
    (state) => state.categoryReducer.categories
  );
  const [categories, setCategories] = useState(reduxCategories);
  // console.log("Categories: ", categories);

  const onCloseEditModalHandler = () => {
    setOpenEditModal(false);
    setItemToEditId(null);
    setItemToEdit(null);
    setItemName("");
    setItemDescription("");
    setItemCost("");
    setProductImage([]);
  };

  useEffect(() => {
    if (!categories.length > 0) {
      dispatch(getCategories()).then((res) =>
        setCategories(res?.data?.categories)
      );
    }
  }, []);

  useEffect(() => {
    if (addMenuError.error) {
      setTimeout(() => {
        if (addMenuError.error === true) {
          setAddMenuError({ ...addMenuError, error: false });
        }
      }, 4000);
    }
  }, [addMenuError.error]);

  useEffect(() => {
    if (itemToEditId !== null) {
      setLoader(true);
      let itemCategory = menuRedux.filter(
        (menu) => menu.category_id === itemToEditId.category_id
      )[0];
      let item_edit = itemCategory.menu_items.filter(
        (item) => item.item_id === itemToEditId.item_id
      )[0];

      setItemToEdit(item_edit);
      setIsVisible(item_edit?.is_visible === 1 ? true : false);
      setItemName(item_edit?.item_title);
      setItemDescription(item_edit?.item_description);
      setItemCost(item_edit?.item_price);
      setItemDiscountedCost(item_edit?.discounted_price);

      if (item_edit?.image_link) {
        fetch(item_edit?.image_link)
          .then(async (response) => {
            let res = await response.blob();
            let file = new File([res], `item${0}.png`, {
              type: "image/png",
            });
            let time = new Date().getTime();
            file["preview"] = `${response.url}?${time}`;
            file["path"] = `logo${0}.png`;
            setProductImage([file]);
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      } else {
        setLoader(false);
        setProductImage([]);
      }
    }
  }, [itemToEditId]);

  useEffect(() => {
    try {
      checkRoute(dispatch);
      dispatch(getQuestionTypes());
      dispatch(fetchTaxes());
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Something went Wrong!", {
          type: "error",
        });
      }
    }
  }, []);

  const deleteQuestionHandler = (id, index) => {
    let newArray = questions;
    newArray.splice(index, 1);
    setQuestions(Array.from(newArray));
  };

  const deleteOptionHandler = (que_index, opt_id, option_index) => {
    let questionState = questions;
    let optionArray = questionState[que_index].options;
    optionArray.splice(option_index, 1);
    let newArr = optionArray;
    questionState[que_index].options = newArr;
    setQuestions(Array.from(questionState));
  };

  const handleEditCategories = () => {};

  async function addNewCategory() {
    if (!loader) {
      setLoader(true);
      if (!!selectedTaxId) {
        if (categoryTitle.trim()) {
          if (categoryTitle.length === 0) {
            setLoader(false);
            alert.show(t("Menu:alertError2"), {
              type: "error",
            });
          } else {
            let payload = {
              data: {
                name: categoryTitle,
                description: categoryDescription,
                tax_id: selectedTaxId,
              },
            };
            if (payload.data.description.length === 0) {
              delete payload.data.description;
            }
            await createCategory(payload)
              .then(async (res) => {
                await dispatch(fetchMenu());
                //@TODO Show alert
                alert.show("Category Created!", {
                  type: "success",
                });
                setLoader(false);
                clearState();
              })
              .catch((error) => {
                setLoader(false);
                clearState();
                let err =
                  error?.message &&
                  error?.message.split(" ").slice(0, 2).join(" ");

                if (err && err === "connect ECONNREFUSED") {
                  alert.show(
                    "Server is down please contact your server provider!",
                    {
                      type: "error",
                    }
                  );
                } else {
                  alert.show("Error while creating category!", {
                    type: "error",
                  });
                }
              });
          }
        } else {
          setLoader(false);
          alert.show(t("Menu:alertError1"), {
            type: "error",
          });
        }
        handleClose();
        clearState();
      } else {
        alert.show("Tax type is required", {
          type: "error",
        });
        setLoader(false);
        handleClose();
        clearState();
      }
    }
  }

  async function addNewItem() {
    if (!loader) {
      let quest = questions;
      for (let i = 0; i < quest.length; i++) {
        for (let j = 0; j < quest[i].options.length; j++) {
          if (quest[i].options[j].similarItem) {
          }
          delete quest[i].options[j].similarItem;
        }
      }
      setLoader(true);
      let items = questions;
      let flag = false;
      for (let i = 0; i < items.length; i++) {
        let options = items[i].options;
        for (let j = 0; j < options.length; j++) {
          for (let k = j + 1; k < options.length; k++) {
            if (options[j].name === options[k].name) {
              flag = true;
              setIsDoubleOption(true);
            }
          }
        }
      }
      if (!flag) {
        setIsDoubleOption(false);
        if (itemName.trim()) {
          if (itemCost === 0 || itemCost.length === 0) {
            setLoader(false);
            setAddMenuError({
              ...addMenuError,
              error: true,
              errorType: "Item Price must be greater than 0",
            });
            //  // alert.show("Item Price Should be greater than 0", {
            //  //   type: "error",
            //  // });
          } else {
            let payload = {
              data: {
                title: itemName,
                category_id: selectedCategory.category_id.toString(),
                price: itemCost,
                description: itemDescription,
                questions: quest,
              },
            };

            if (itemDescription.length === 0) {
              delete payload.data.description;
            }

            createItem(payload)
              .then((res) => {
                uploadImages(productImage, "menuItem", [res.data.payload.id])
                  .then((resInner) => {
                    setLoader(false);
                    setCreateItemModal(false);
                    dispatch(fetchMenu());
                    clearState();

                    alert.show("Item Created!", {
                      type: "success",
                    });
                  })
                  .catch((error) => {
                    setLoader(false);
                    setCreateItemModal(false);
                    clearState();
                    alert.show(
                      "Item was created, but something went wrong while adding image",
                      {
                        type: "error",
                      }
                    );
                  });
              })
              .catch((error) => {
                //@TODO Show alert
                setLoader(false);
                setCreateItemModal(false);
                clearState();
                alert.show("Unable To Create Menu Item", {
                  type: "error",
                });
                let err =
                  error?.message &&
                  error?.message.split(" ").slice(0, 2).join(" ");

                if (err && err === "connect ECONNREFUSED") {
                  alert.show(
                    "Server is down please contact your server provider!",
                    {
                      type: "error",
                    }
                  );
                } else {
                  // alert.show("Something went wrong", {
                  //   type: "error",
                  // });
                }
              });
          }
        } else {
          setLoader(false);
          // setCreateItemModal(false);
          // clearState();
          // alert.show(t("Menu:alertError4"), {
          //   type: "error",
          // });
          setAddMenuError({
            ...addMenuError,
            error: true,
            errorType: "Item Name Should not be Empty",
          });
        }
      } else {
        setLoader(false);
      }
    }
  }

  // console.log("Product Image: ", productImage[0]?.preview);
  const editItemHandler = (e) => {
    if (!loader) {
      e.preventDefault();
      setLoader(true);
      console.log("itemDescription", typeof itemDescription);
      let data = {
        data: {
          item_id: itemToEditId.item_id.toString(),
          title: itemName,
          category_id: itemToEditId.category_id.toString(),
          price: itemCost,
          description:
            itemDescription === null
              ? null
              : itemDescription.length === 0
              ? null
              : itemDescription.toString(),
          image_link: productImage[0]?.preview,
          is_visible: isVisible === true ? "1" : "0",
        },
      };
      editItem(data)
        .then((res) => {
          if (imageChange) {
            uploadImages(productImage, "menuItem", [itemToEditId.item_id])
              .then((resInner) => {
                setLoader(false);
                setOpenEditModal(false);
                dispatch(fetchMenu());
                clearState();
                setImageChange(false);
                alert.show("Item Updated!", {
                  type: "success",
                });
              })
              .catch((err) => {
                setLoader(false);
                onCloseEditModalHandler(false);
                clearState();
                setImageChange(false);
                alert.show(
                  "Item was updated, but something went wrong while adding image",
                  {
                    type: "error",
                  }
                );
              });
          } else {
            // console.log("Item Updated Without Image");
            setLoader(false);
            setOpenEditModal(false);
            dispatch(fetchMenu());
            clearState();
            alert.show("Item Updated!", {
              type: "success",
            });
          }
        })
        .catch((error) => {
          // console.log("Item Update catch error");
          setLoader(false);
          onCloseEditModalHandler(false);
          clearState();
          alert.show("Unable to Update Menu Item", {
            type: "error",
          });
          let err =
            error?.message && error?.message.split(" ").slice(0, 2).join(" ");

          if (err && err === "connect ECONNREFUSED") {
            alert.show("Server is down please contact your server provider!", {
              type: "error",
            });
          } else {
            // alert.show("Unable to Update Item", {
            //   type: "error",
            // });
          }
        });
    }
  };

  useEffect(() => {
    try {
      dispatch(fetchMenu());
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Something went wrong", {
          type: "error",
        });
      }
    }
  }, []);

  useEffect(() => {}, [questionTypes]);
  // console.log("Item Prices: ", itemPrices);
  const editItemPriceHandler = async () => {
    if (!loader) {
      // ------ Condition to Check Same Date -------
      const dateToStart = (obj) => {
        const pricesDate = new Date(obj.start_date).setHours(0, 0, 0, 0);
        const selectedDate = new Date(startDate).setHours(0, 0, 0, 0);
        return pricesDate === selectedDate;
      };
      // -------- Handle Update -------
      if (!itemPrices.some(dateToStart)) {
        setLoader(true);
        let data = {};
        if (addDiscount) {
          data = {
            ...data,
            discounted_price: Number(itemDiscountedCost),
            discount_pos: discountOnPOS,
            discount_online: discountOnOnline,
          };
        }
        data = {
          ...data,
          item_id: itemToEditId?.item_id,
          price: Number(itemCost),
          start_date: startDate,
        };
        // console.log(data);
        editItemPrice(data)
          .then(async (res) => {
            await dispatch(fetchMenu());
            setOpenEditPriceModal(false);
            clearState();
            setLoader(false);
            alert.show("Item Price Successfully Added", {
              type: "success",
            });
          })
          .catch((error) => {
            setLoader(false);
            setOpenEditPriceModal(false);
            clearState();
            let err =
              error?.message && error?.message.split(" ").slice(0, 2).join(" ");
            if (err && err === "connect ECONNREFUSED") {
              alert.show(
                "Server is down please contact your server provider!",
                {
                  type: "error",
                }
              );
            } else {
              alert.show("Something went wrong", {
                type: "error",
              });
            }
          });
      } else {
        setLoader(false);
        setPriceDateError(true);
      }
    }
  };

  const handleOptions = (evt, index, optionIndex) => {
    let ques = questions;
    ques[index]["options"][optionIndex].name = evt.target.value;
    ques[index]["options"][optionIndex].similarItem = null;
    setQuestions(JSON.parse(JSON.stringify(ques)));
  };
  const clearState = () => {
    setCategoryTitle("");
    setCategoryDescription("");
    setItemName("");
    setItemDescription("");
    setItemCost("");
    setQuestions([]);
    setProductImage([]);
    setSelectedTaxId("");
    setItemToEditId(null);
    setItemToEdit(null);
    setOpenEditPriceModal(false);
    setDiscountOnOnline(false);
    setDiscountOnPOS(false);
    setStartDate("");
    setIsDoubleOption(false);
  };

  return (
    <div style={{ padding: 50 }}>
      <div>
        <Typography style={{ fontSize: 21, fontWeight: "500" }}>
          {t("Menu:menuItems")}
        </Typography>
      </div>
      {/* Item To Edit Price Modal*/}
      {openEditPriceModal && itemToEdit && (
        <EditItemPirceModal
          showModal={openEditPriceModal}
          data={{
            itemCost,
            itemDiscountedCost,
            discountOnOnline,
            discountOnPOS,
            startDate,
          }}
          itemId={itemToEdit?.item_id}
          itemName={itemToEdit?.item_title}
          itemCostHandler={(value) => setItemCost(value)}
          itemCostDiscountHandler={(value) => setItemDiscountedCost(value)}
          discOnPOSHandler={() => setDiscountOnPOS(!discountOnPOS)}
          discOnOnlineHandler={() => setDiscountOnOnline(!discountOnOnline)}
          startDateHandler={(value) => setStartDate(value)}
          itemPrices={itemPrices}
          setItemPrices={setItemPrices}
          priceDateError={priceDateError}
          setPriceDateError={setPriceDateError}
          addDiscount={addDiscount}
          setAddDiscount={setAddDiscount}
          loader={loader}
          onSubmitHandler={() => {
            editItemPriceHandler();
          }}
          ModalHandler={() => {
            clearState();
            setOpenEditPriceModal(false);
          }}
        />
      )}

      {/* Item To Edit Modal */}
      {openEditModal && itemToEdit && (
        <BootstrapModal
          show={openEditModal}
          onHide={onCloseEditModalHandler}
          keyboard={false}
        >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Edit Item Modal</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <Form autocomplete="off">
              <Form.Group className="mb-3">
                <Form.Label>{t("Menu:itemName")}</Form.Label>
                <Form.Control
                  type="text"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("Menu:itemDescription")}</Form.Label>
                <Form.Control
                  type="text"
                  value={itemDescription}
                  onChange={(e) => setItemDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" style={{ display: "flex" }}>
                <Form.Label style={{ marginRight: "5px" }}>
                  {" "}
                  {t("Menu:isVisible")}
                </Form.Label>

                <Form.Check
                  checked={isVisible}
                  onClick={() => {
                    setIsVisible(!isVisible);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label> {t("Menu:productImage")}</Form.Label>
                <ImageDropZone
                  imageArr={productImage}
                  onChangeImages={(images) => {
                    setProductImage(images);
                    setImageChange(true);
                  }}
                  maxFileLimit={1}
                  width={470}
                  height={200}
                />
              </Form.Group>

              <Button
                className="btn-lg btn-block mt-4"
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "#A2191B",
                  borderColor: "#A2191B",
                  width: "100%",
                }}
                onClick={(e) => {
                  editItemHandler(e);
                }}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Edit Item"
                )}
              </Button>
            </Form>
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
      {menuRedux.length === 0 ? (
        <div
          style={{
            padding: 25,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={noItem}
            alt="no item"
            style={{ width: "70%", height: "70%" }}
          />
          <p>{t("Menu:errorMsg")}</p>
          <div className="addButton noselect" onClick={handleOpen}>
            {t("Menu:addNewCategory")}
          </div>
        </div>
      ) : (
        <>
          <div
            onClick={() => {
              handleOpen();
            }}
            style={{
              backgroundColor: "white",
              width: "100%",
              minHeight: 110,
              borderRadius: 15,
              boxShadow: "0px 0px 27px 11px rgba(0,0,0,0.04)",
              marginTop: 20,
              marginBottom: 20,
              fontSize: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Add sx={{ color: "#A2191B" }} fontSize="inherit" />

            <Typography
              style={{
                fontSize: 21,
                fontWeight: "500",
                color: "#A2191B",
                marginLeft: 10,
              }}
            >
              {t("Menu:addNewCategory")}
            </Typography>
          </div>

          {menuRedux.map((category, index) => {
            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <Typography
                        className={"category-title"}
                        style={{ fontSize: 16, fontWeight: "500" }}
                      >
                        {category.category}
                      </Typography>
                      {/* <div
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(76, 175, 80,0.05)",
                          padding: 5,
                          border: "solid #4CAF50 2px",
                          height: 25,
                          width: 25,
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          handleCategoryOpen();
                          setCategoryId(category.category_id);
                        }}
                      >
                        <Edit sx={{ color: "#4CAF50" }} />
                      </div> */}
                    </div>
                    <div>
                      <Typography
                        className={"category-description"}
                        style={{ fontSize: 13 }}
                      >
                        {category.description}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgb(244, 67, 54,0.05)",
                        padding: 5,
                        // width: 140,
                        border: "solid #F44336 2px",
                        height: 35,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                      onClick={async () => {
                        // @TODO ask for confirmation using alert
                        if (!loader) {
                          if (
                            window.confirm(
                              "Sure you want to delete this category?"
                            )
                          ) {
                            setLoader(true);
                            setDeleteLoader(true);
                            let payload = {
                              data: {
                                cat_id: category.category_id.toString(),
                              },
                            };

                            await deleteCategory(payload)
                              .then(async (res) => {
                                await dispatch(fetchMenu());
                                alert.show("Successfully deleted !!!", {
                                  type: "success",
                                });
                                setLoader(false);
                                setDeleteLoader(false);
                              })
                              .catch((error) => {
                                let err =
                                  error?.message &&
                                  error?.message
                                    .split(" ")
                                    .slice(0, 2)
                                    .join(" ");
                                setLoader(false);
                                setDeleteLoader(false);
                                if (err && err === "connect ECONNREFUSED") {
                                  alert.show(
                                    "Server is down please contact your server provider!",
                                    {
                                      type: "error",
                                    }
                                  );
                                } else {
                                  alert.show("Unable to Delete Category", {
                                    type: "error",
                                  });
                                }
                              });
                          }
                        } else {
                          return;
                        }
                      }}
                    >
                      <Close sx={{ color: "#F44336" }} />
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 13,
                          color: "#F44336",
                          marginLeft: 10,
                        }}
                      >
                        {deleteLoader ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          t("Menu:deleteCategory")
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: 10,
                    marginBottom: 50,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    // marginLeft: 20,
                    // justifyContent: "space-between",
                  }}
                >
                  <AddMenuItem
                    onClick={() => {
                      setSelectedCategory(category);
                      setCreateItemModal(true);
                    }}
                  />
                  {category.menu_items.map((item, itemIndex) => {
                    return (
                      <MenuItem
                        data={item}
                        openEditItemPriceModal={(id) => {
                          setItemToEditId({
                            item_id: id,
                            category_id: category.category_id,
                          });
                          setOpenEditPriceModal(true);
                        }}
                        openEditModal={(id) => {
                          setItemToEditId({
                            item_id: id,
                            category_id: category.category_id,
                          });
                          setOpenEditModal(true);
                        }}
                      />
                    );
                  })}
                </div>
                <hr />
              </div>
            );
          })}
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ marginBottom: 15 }}>
              <Typography style={{ fontSize: 21, fontWeight: "500" }}>
                {t("Menu:addNewCategory")}
              </Typography>
            </div>

            {!!taxesTypes.length && (
              <div style={{ marginBottom: 15 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    {t("Menu:selectTaxType")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="selectTaxType"
                    sx={{ height: 50 }}
                    onChange={(e) => {
                      let valueObj = JSON.parse(e.target.value);
                      setSelectedTaxId(valueObj.id);
                    }}
                  >
                    {/* <MItem value="">{t("Menu:taxesType")}</MItem> */}
                    {taxesTypes?.map((taxObj) => (
                      <MItem value={JSON.stringify(taxObj)}>
                        {taxObj.name}
                      </MItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            <Typography
              style={{
                fontWeight: "400",
                fontSize: 14,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {t("Menu:categoryTitle")}
            </Typography>
            <input
              placeholder=""
              className="inputStyleBlack"
              onChange={(evt) => {
                setCategoryTitle(evt.target.value);
              }}
              value={categoryTitle}
            />

            <Typography
              style={{
                fontWeight: "400",
                fontSize: 14,
                // marginTop: 5,
                marginBottom: 5,
              }}
            >
              {t("Menu:categoryDescription")}
            </Typography>
            <textarea
              placeholder=""
              className="inputStyleBlack"
              style={{ minHeight: 120 }}
              onChange={(evt) => {
                setCategoryDescription(evt.target.value);
              }}
              value={categoryDescription}
            />

            <button
              className="addButton noselect"
              // onClick={handleOpen}
              style={{ width: "100%", paddingTop: 20, paddingBottom: 20 }}
              onClick={() => {
                // handleOpen();
                addNewCategory();
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                t("Menu:createCategory")
              )}
            </button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={createItemModal}
        onClose={handleItemClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={createItemModal}>
          <Box sx={style} className="create-item-modal">
            <>
              {addMenuError.error ? (
                <Alert
                  severity="error"
                  style={{
                    position: "sticky",
                    top: -30,
                    zIndex: 111,
                    // marginLeft: 8,
                    backgroundColor: "#FDEDED",
                  }}
                >
                  {addMenuError.errorType}
                </Alert>
              ) : null}
              <div style={{ marginBottom: 20 }}>
                <Typography style={{ fontSize: 21, fontWeight: "500" }}>
                  {t("Menu:addNew")} ({selectedCategory.category}){" "}
                  {t("Menu:item")}{" "}
                </Typography>
              </div>

              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t("Menu:itemName")}
              </Typography>
              <input
                className="inputStyleBlack"
                onChange={(evt) => {
                  setItemName(evt.target.value);
                }}
              />

              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t("Menu:itemDescription")}
              </Typography>
              <textarea
                className="inputStyleBlack"
                onChange={(evt) => {
                  setItemDescription(evt.target.value);
                }}
                style={{ minHeight: 130, marignTop: 5 }}
              />

              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t("Menu:productImage")}
              </Typography>
              <ImageDropZone
                imageArr={productImage}
                onChangeImages={(images) => {
                  setProductImage(images);
                }}
                maxFileLimit={1}
                // width={"100%"}
                // height={200}
                style={{ width: "100%", height: 200 }}
              />

              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {t("Menu:itemCost")}
              </Typography>
              <input
                className="inputStyleBlack"
                type="number"
                min={0}
                value={itemCost}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setItemCost(0);
                  } else if (Number(e.target.value) >= 1) {
                    setItemCost(Number(e.target.value));
                  }
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {t("Menu:questions")}
                </Typography>

                <Typography
                  className="add-question"
                  onClick={() => {
                    let ques = questions;
                    ques.push({
                      title: "",
                      lkup_question_type_id: "1",
                      is_required: false,
                      options: [{ name: "", price: "0" }],
                      id: new Date().getTime(),
                    });
                    setQuestions(JSON.parse(JSON.stringify(ques)));
                  }}
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    marginTop: 5,
                    marginBottom: 5,
                    // cursor: "pointer",
                  }}
                >
                  <Add /> {t("Menu:addNewQuestion")}
                </Typography>
              </div>
              <hr />

              {questions.map((question, index) => {
                return (
                  <div
                    style={{
                      border: "solid rgba(0,0,0,0.15) 1px",
                      padding: 10,
                      marginBottom: 8,
                    }}
                    key={question?.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          marginTop: 5,
                          marginBottom: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Menu:question")} {index + 1}
                      </Typography>
                      <div
                        style={{ marginBottom: 15 }}
                        onClick={() => {
                          deleteQuestionHandler(question.id, index);
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            textAlign: "center",
                            // marginBottom: 10,
                          }}
                          className="close-icon"
                        >
                          <Close />
                        </span>
                      </div>
                    </div>
                    <input
                      placeholder=""
                      className="inputStyleBlack"
                      onChange={(evt) => {
                        let ques = questions;
                        ques[index]["title"] = evt.target.value;
                        setQuestions(JSON.parse(JSON.stringify(ques)));
                      }}
                    />
                    <FormGroup style={{ marginRight: 5 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ marginRight: 5 }}
                            onChange={(e) => {
                              // console.log("E.targfet.value: ", e.target.checked);
                              let ques = questions;
                              ques[index]["is_required"] = e.target.checked;
                              setQuestions(JSON.parse(JSON.stringify(ques)));
                            }}
                          />
                        }
                        label="Required !"
                      />
                    </FormGroup>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 14,
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      {t("Menu:questionType")}
                    </Typography>
                    <FormControl fullWidth>
                      <Form.Select
                        onChange={(evt) => {
                          let ques = questions;
                          ques[index]["lkup_question_type_id"] =
                            evt.target.value;
                          setQuestions(JSON.parse(JSON.stringify(ques)));
                        }}
                      >
                        {questionTypes.map((type, index) => {
                          return <option value={type.id}>{type.name}</option>;
                        })}
                      </Form.Select>
                    </FormControl>
                    <div style={{ padding: 10 }}>
                      {question.options.map((option, optionIndex) => {
                        return (
                          <>
                            <Grid
                              container
                              spacing={3}
                              key={optionIndex}
                              alignItems={"center"}
                            >
                              <Grid item md={7}>
                                <div>
                                  <Typography
                                    style={{
                                      fontWeight: "400",
                                      fontSize: 14,
                                    }}
                                  >
                                    {t("Menu:option")} {optionIndex + 1}
                                  </Typography>
                                  <input
                                    placeholder=""
                                    className="inputStyleBlack"
                                    value={option.name}
                                    disabled={option.menu_item_id}
                                    onBlur={() => {
                                      getItemFromOptions(option.name).then(
                                        (res) => {
                                          if (res.data.payload) {
                                            let ques = questions;
                                            ques[index]["options"][
                                              optionIndex
                                            ].similarItem = res.data.payload;
                                            setQuestions(
                                              JSON.parse(JSON.stringify(ques))
                                            );
                                          }
                                        }
                                      );
                                    }}
                                    onChange={(evt) =>
                                      handleOptions(evt, index, optionIndex)
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item md={4}>
                                <div>
                                  <Typography
                                    style={{
                                      fontWeight: "400",
                                      fontSize: 14,
                                    }}
                                  >
                                    {t("Menu:option")} {optionIndex + 1}{" "}
                                    {t("Menu:PriceOptional")}
                                  </Typography>
                                  <input
                                    placeholder=""
                                    className="inputStyleBlack"
                                    value={
                                      questions[index]["options"][optionIndex]
                                        .price
                                    }
                                    onChange={(evt) => {
                                      let ques = questions;
                                      ques[index]["options"][
                                        optionIndex
                                      ].price = evt.target.value;
                                      setQuestions(
                                        JSON.parse(JSON.stringify(ques))
                                      );
                                    }}
                                    onBlur={() => {
                                      let ques = questions;
                                      if (
                                        !ques[index]["options"][optionIndex]
                                          .price
                                      ) {
                                        ques[index]["options"][
                                          optionIndex
                                        ].price = "0";
                                        setQuestions(
                                          JSON.parse(JSON.stringify(ques))
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </Grid>
                              {question.options.length > 1 && (
                                <Grid
                                  item
                                  md={1}
                                  onClick={() => {
                                    deleteOptionHandler(
                                      index,
                                      option.id,
                                      optionIndex
                                    );
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Close />
                                  </span>
                                </Grid>
                              )}
                              {/* {option.similarItem && (
                                <Grid item md={12} style={{ paddingTop: 0 }}>
                                  <Typography
                                    style={{
                                      fontWeight: "400",
                                      fontSize: 14,
                                    }}
                                    onClick={() => {
                                      if (option.menu_item_id) {
                                        let ques = questions;
                                        ques[index]["options"][
                                          optionIndex
                                        ].menu_item_id = null;
                                        setQuestions(
                                          JSON.parse(JSON.stringify(ques))
                                        );
                                      } else {
                                        let ques = questions;
                                        ques[index]["options"][
                                          optionIndex
                                        ].menu_item_id =
                                          option.similarItem.option_id;
                                        ques[index]["options"][
                                          optionIndex
                                        ].name = option.similarItem.title;
                                        setQuestions(
                                          JSON.parse(JSON.stringify(ques))
                                        );
                                      }
                                    }}
                                  >
                                    {option.menu_item_id ? (
                                      <span>
                                        {t("Menu:linkedWith")}:{" "}
                                        <b>{option.similarItem.title}</b>.{" "}
                                        <span
                                          style={{
                                            color: "#A2191B",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {t("Menu:clickHereToUnlink")}
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        {t("Menu:similarNameError")}{" "}
                                        <b>{option.similarItem.title}</b>.{" "}
                                        <span
                                          style={{
                                            color: "#A2191B",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {t("Menu:clickHere")}
                                        </span>{" "}
                                        {t("Menu:toLinkOptions")}
                                      </span>
                                    )}
                                  </Typography>
                                </Grid>
                              )} */}
                            </Grid>
                          </>
                        );
                      })}
                      <Typography
                        onClick={() => {
                          let ques = questions;
                          ques[index].options.push({
                            name: "",
                            price: "0",
                          });
                          setQuestions(JSON.parse(JSON.stringify(ques)));
                        }}
                        style={{
                          width: 150,
                          fontWeight: "400",
                          fontSize: 14,
                          marginTop: 5,
                          marginBottom: 5,
                          cursor: "pointer",
                        }}
                        className="add-question"
                      >
                        <Add /> {t("Menu:addNewOption")}
                      </Typography>
                    </div>
                  </div>
                );
              })}
              {isDoubleOption && (
                <span style={{ fontSize: 14, color: "red" }}>
                  Options must be different!
                </span>
              )}

              <button
                className="addButton noselect"
                // onClick={handleOpen}
                style={{ width: "100%", paddingTop: 20, paddingBottom: 20 }}
                onClick={() => {
                  // handleOpen();
                  addNewItem();
                }}
                disabled={false}
              >
                {loader ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Create Item"
                )}
              </button>
            </>
          </Box>
        </Fade>
      </Modal>
      {/* ------------- Edit Category Modal --------------- */}

      {categoryId && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={categoryModalOpen}
          onClose={handleCategoryClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div style={{ marginBottom: 15 }}>
                <Typography style={{ fontSize: 21, fontWeight: "500" }}>
                  {/* {t('Menu:addNewCategory')} */} Add Tax To Categories
                </Typography>
              </div>

              {categories?.length && (
                <ul class="list-group categories-list">
                  {categories.map((value) => {
                    // console.log("VAlue: 164:  ", value);
                    return (
                      <li
                        class={`list-group-item ${
                          value.defaultCheck && "disabled"
                        }`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{value.name}</span>
                        {/* <span>
                          <Checkbox
                            // {value.tax_id === selectedTaxId}
                            disabled={value.defaultCheck}
                            checked={value.check}
                            onChange={(e) => handleCheckBox(e, value.id)}
                          />
                        </span> */}
                      </li>
                    );
                  })}
                </ul>
              )}
              <div
                className="addButton noselect"
                onClick={handleEditCategories}
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  margin: "20px auto",
                  marginBottom: 0,
                  textAlign: "right",
                }}
                // onClick={addNewCategory}
              >
                {/* {t('Menu:createCategory')} */}
                {/* {loading ? (
                  <div class="spinner-border text-danger" role="status" />
                ) : ( */}
                Edit
                {/* )} */}
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}

export default MenuPage;
