import { SET_INVOICE, DELETE_INVOICE } from "../constants";

const initialState = {
  invoice: {},
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE: {
      return {
        ...state,
        invoice: action.payload,
      };
    }
    case DELETE_INVOICE: {
      return {
        ...state,
        invoice: {},
      };
    }
    default:
      return state;
  }
};

export default invoiceReducer;
