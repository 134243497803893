import axios from "axios";
import { serverUrl } from "../../config";
import { SET_CATEGORIES, EDIT_CATEGORIES } from "../constants";

//  -------- Get Categories to add Tax id ---------------

export const getCategories = () => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  return await axios({
    url: "api/vendor/menu/category",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  })
    .then((res) => {
      dispatch({
        type: SET_CATEGORIES,
        payload: res.data.categories,
      });
      // console.log("Response: ", res.data.categories);
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      throw err.response;
    });
};

// --------------- Edit Categories Tax ------------

export const editCategoriesTax = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  // console.log(token);
  await axios({
    url: "api/vendor/menu/categoryTax",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      dispatch({
        type: EDIT_CATEGORIES,
      });
      // console.log("Response: ", res.data);
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      throw err.response;
    });
};
