import { ADD_PROMO, DELETE_PROMO, EDIT_PROMO, SET_PROMO } from '../constants';

const initialState = {
    promo: []
};

const promoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROMO: {
            return {
                ...state,
                promo: action.payload,
            };
        }
        case ADD_PROMO: {
            return {
                ...state,
                promo: [...state.promo, action.payload],
            };
        }
        case DELETE_PROMO: {
            return {
                ...state,
                promo: state.promo.filter(pro => pro.id !== +action.payload),
            };
        }
        case EDIT_PROMO: {
            return {
                ...state,
                promo: state.promo.map(pro => pro.id === +action.payload.promocode_id ? { ...action.payload, id: +action.payload.promocode_id } : pro),
            };
        }
        default:
            return state;
    }
};

export default promoReducer;
