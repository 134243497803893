import { SET_MENU_POS, } from "../constants";

const initialState = {
    pos_menu: []
};

const posMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_POS: {
            return {
                ...state,
                pos_menu: [...action.payload],
            };
        }
        default:
            return state;
    }
};

export default posMenuReducer;
