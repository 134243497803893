import React, { useEffect, useState } from "react";
import BasicCard from "../../components/BasicCard";
import styles from "./OrderHistory.module.css";
import {
  Button,
  Grid,
  IconButton,
  Modal as MaterialModal,
  Box,
} from "@mui/material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import * as jsPDF from "jspdf";
import * as autoTable from "jspdf-autotable";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import MaterialTable, { MTableToolbar } from "material-table";
import HistoryTableLocalization from "../../resources/HistoryTableLocalization";
import {
  totalCalculator_tax,
  totalRevenueCalculator,
  itemAmountCalculator,
} from "../../helperFunctions/TotalCalculator";
import { checkRoute } from "../../helperFunctions/routeHandler";
import Invoice from "./../../components/Invoice/Invoice";
import { getInvoice } from "../../redux/actions/invoice";
import CircularProgress from "@mui/material/CircularProgress";
import { delivery_type } from "../../constants/orderType";

import moment from "moment";

import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { getOrderHistory } from "../../redux/actions/orders";

import { useAlert } from "react-alert";
import { DELETE_INVOICE } from "../../redux/constants";

export const OrderHistory = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const alert = useAlert();

  const orderHistory = useSelector((state) => state.ordersReducer.orderHistory);
  console.log("orderHistory", orderHistory);
  const [order_history, setOrder_history] = useState(orderHistory);
  const [selected_order_data, setSelected_order_data] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const invoiceData = useSelector((state) => state.invoiceReducer.invoice);
  const businessConfig = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );

  // ---------------- Sort Orders card according to latest order on top --------
  function compareDate(a, b) {
    if (
      new Date(a.creation_time).getTime() > new Date(b.creation_time).getTime()
    ) {
      return -1;
    }
    if (
      new Date(a.creation_time).getTime() < new Date(b.creation_time).getTime()
    ) {
      return 1;
    }
    return 0;
  }

  function sortOrdersTotalAmount(ordersHistory) {
    ordersHistory.sort(compareDate);
    const sortedOrderHistory = ordersHistory.map((value) => {
      let totalAmount = 0;
      if (businessConfig?.is_taxable) {
        totalAmount = Math.ceil(Number(totalCalculator_tax(value)));
      } else {
        totalAmount = Math.ceil(Number(totalRevenueCalculator([value])));
      }
      return {
        ...value,
        total: totalAmount,
      };
    });
    setOrder_history(sortedOrderHistory);
  }

  useEffect(() => {
    sortOrdersTotalAmount(orderHistory);
  }, [orderHistory]);

  // console.log("OrderHistory: -------- ", orderHistory);

  const filterHandler = (criteria) => {
    setFilterCriteria(criteria);
    let today = new Date();
    let today_sec = today.getTime();

    if (criteria === "day") {
      let yersterday = new Date(today);
      yersterday.setDate(yersterday.getDate() - 1);

      let yesterday_sec = yersterday.getTime();

      let filtered_orders = orderHistory.filter((ord) => {
        // if (ord.modified_on === null) {
        return (
          new Date(ord.creation_time).getTime() > yesterday_sec &&
          new Date(ord.creation_time).getTime() <= today_sec
        );
        // } else {
        // return (
        // new Date(ord.modified_on).getTime() > yesterday_sec &&
        // new Date(ord.modified_on).getTime() <= today_sec
        // );
        // }
      });
      // console.log("Filtered Order on Day: ", filtered_orders);
      // setOrder_history(Array.from(filtered_orders));
      // setOrder_history(Array.from(sortOrdersTotalAmount(filtered_orders)));
      sortOrdersTotalAmount(Array.from(filtered_orders));
    } else if (criteria === "week") {
      let week = new Date(today);
      week.setDate(week.getDate() - 7);

      let week_sec = week.getTime();

      let filtered_orders = orderHistory.filter((ord) => {
        // if (ord.modified_on === null) {
        return (
          new Date(ord.creation_time).getTime() > week_sec &&
          new Date(ord.creation_time).getTime() <= today_sec
        );
        // } else {
        // return (
        // // new Date(ord.modified_on).getTime() > week_sec &&
        // new Date(ord.modified_on).getTime() <= today_sec
        // );
        // }
      });
      // console.log("Filtered Order on Week: ", Array.from(filtered_orders));
      // setOrder_history(Array.from(sortOrdersTotalAmount(filtered_orders)));
      sortOrdersTotalAmount(Array.from(filtered_orders));
    } else if (criteria === "month") {
      let month = new Date(today);
      month.setMonth(month.getMonth() - 1);

      let month_sec = month.getTime();

      let filtered_orders = orderHistory.filter((ord) => {
        if (ord.modified_on === null) {
          return (
            new Date(ord.creation_time).getTime() > month_sec &&
            new Date(ord.creation_time).getTime() <= today_sec
          );
        } else {
          return (
            new Date(ord.modified_on).getTime() > month_sec &&
            new Date(ord.modified_on).getTime() <= today_sec
          );
        }
      });
      // console.log("Filtered Order on Month: ", filtered_orders);
      // setOrder_history(Array.from(sortOrdersTotalAmount(filtered_orders)));
      sortOrdersTotalAmount(Array.from(filtered_orders));
    } else {
      // setOrder_history(Array.from(order_history));
      sortOrdersTotalAmount(Array.from(orderHistory));
    }
  };

  const openModalHandlder = (order_data) => {
    setSelected_order_data(order_data);
    setOpenModal(true);
  };

  const onCloseModalHandler = () => {
    setSelected_order_data(null);
    setOpenModal(false);
  };

  const getHistory = async () => {
    try {
      await dispatch(getOrderHistory());
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Something goes wrong while fetching data!", {
          type: "error",
        });
      }
    }
  };

  const generateInvoice = async (orderId) => {
    // console.log("Generate Invoice called!");
    if (!loading) {
      setLoading(true);
      try {
        await dispatch(getInvoice(orderId));
        setShowInvoice(true);
        // onCloseModalHandler();
        setOpenModal(false);
        setLoading(false);
      } catch (error) {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          // onClose();
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
          setLoading(false);
          setOpenModal(false);
        } else {
          // onClose();
          alert.show("Unable To Generate Invoice!", {
            type: "error",
          });
          setLoading(false);
          setOpenModal(false);
        }
      }
    }
  };

  useEffect(() => {
    getHistory();
    checkRoute(dispatch);
  }, []);

  function statusText(status) {
    return status.split("-")[0].trim();
  }

  function colorFunc(status) {
    switch (statusText(status).toLowerCase()) {
      case "rejected":
        return "red";
        break;
      case "pending":
        return "#d4c204";
        break;
      case "preparing":
        return "orange";
        break;
      default:
        break;
    }
  }

  return (
    <div style={{ padding: 50 }}>
      {openModal && (
        <Modal show={openModal} onHide={onCloseModalHandler} keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              Details For Order #{selected_order_data.order_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className={styles.flexRow}>
                <span className={styles.heading}>Order Creation Date:</span>
                <span>
                  {moment(selected_order_data.creation_time).format(
                    "D MMMM hh:mm A"
                  )}
                </span>
              </div>
              <div className={styles.flexRow}>
                <span className={styles.heading}>Customer Name:</span>
                <span>{selected_order_data.customer}</span>
              </div>
              <div className={styles.flexRow}>
                <span className={styles.heading}>Order Id:</span>
                <span>{selected_order_data.order_id}</span>
              </div>
              <div className={styles.flexRow}>
                <span className={styles.heading}>Order Type:</span>
                <span>{delivery_type[selected_order_data.order_type_id]}</span>
              </div>
              <div className={styles.flexRow}>
                <span className={styles.heading}>Order Status:</span>
                <span
                  className={styles.capsuaul}
                  style={{
                    backgroundColor: colorFunc(
                      selected_order_data.order_status
                    ),
                  }}
                >
                  {selected_order_data.order_status}
                </span>
              </div>
            </div>
            <hr className={styles.line} />
            <div>
              <div className={styles.flexRow}>
                <span className={styles.heading} style={{ flex: 1 }}>
                  Item Name
                </span>
                <span className={styles.heading} style={{ flex: 1 }}>
                  Item Quantity
                </span>
                <span className={styles.heading} style={{ flex: 1 }}>
                  Item Price
                </span>
              </div>
              {Object.values(selected_order_data.items).map((order, index) => (
                <div className={styles.flexRow} key={index}>
                  <span style={{ flex: 1, wordBreak: "break-all" }}>
                    {order.item}
                  </span>
                  <span style={{ flex: 1 }}>{order.item_qty}</span>
                  <span style={{ flex: 1 }}>{itemAmountCalculator(order)}</span>
                </div>
              ))}
            </div>
            <hr className={styles.line} />
            <div className={styles.flexRow}>
              <span className={styles.heading}>Order Total:</span>
              <span>
                {businessConfig?.is_taxable
                  ? Math.ceil(totalCalculator_tax(selected_order_data))
                  : Math.ceil(totalRevenueCalculator([selected_order_data]))}
              </span>
            </div>
            <div style={{ width: "100%", textAlign: "right" }}>
              {selected_order_data.order_status === "Delivered" ? (
                <Button
                  className="mt-4"
                  style={{
                    color: "green",
                    borderColor: "green",
                    padding: 8,
                    border: "1px solid green",
                    borderRadius: 5,
                  }}
                  variant="outlined"
                  onClick={() => {
                    generateInvoice(selected_order_data?.order_id);
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Generate Invoice"
                  )}
                </Button>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showInvoice && !!Object.values(invoiceData).length && (
        <MaterialModal
          open={showInvoice}
          onClose={() => {
            setShowInvoice(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            overflow: "scroll",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          keyboard={false}
        >
          <Box sx={modal_style}>
            <Invoice
              onClose_handler={() => {
                setShowInvoice(false);
              }}
            />
          </Box>
        </MaterialModal>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <BasicCard
            MainIcon={ViewInArIcon}
            num={order_history ? order_history.length : 0}
            text={t("orderHistory:totalOrders")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <BasicCard
            MainIcon={QuestionMarkIcon}
            num={
              order_history
                ? order_history.filter(
                    (order) => statusText(order.order_status) === "Pending"
                  ).length
                : 0
            }
            text={t("orderHistory:ordersPending")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <BasicCard
            MainIcon={DoneIcon}
            num={
              order_history
                ? order_history.filter(
                    (order) => statusText(order.order_status) === "Delivered"
                  ).length
                : 0
            }
            text={t("orderHistory:ordersDelivered")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <BasicCard
            MainIcon={CloseIcon}
            num={
              order_history
                ? order_history.filter(
                    (order) => statusText(order.order_status) === "Rejected"
                  ).length
                : 0
            }
            text={t("orderHistory:ordersRejected")}
          />
        </Grid>
      </Grid>

      <div className="mt-4">
        <MaterialTable
          style={{ boxShadow: "rgba(0,0,0,0.03) 1px 1px 10px" }}
          options={{
            search: true,
            emptyRowsWhenPaging: false,
            filtering: true,
            exportButton: {
              csv: true,
            },
            sorting: false,
            exportFileName: "Synevry",
            pageSize: 10,
            exportAllData: true,
            pageSizeOptions: [10, 20, 50],
            // paginationType:'stepped',
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f2f2f2" };
              }
            },
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "10px 10px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    className={styles.filter}
                  >
                    <select
                      value={filterCriteria}
                      onChange={(e) => {
                        filterHandler(e.target.value);
                      }}
                      style={{ color: "#A2291B" }}
                    >
                      <option value="">Show All Orders</option>
                      <option value="day">Last 24 Hours Orders</option>
                      <option value="week">Last 7 Days Orders</option>
                      <option value="month">Last 30 Days Orders</option>
                    </select>
                  </div>
                </div>
              </div>
            ),
          }}
          columns={[
            {
              title: t("orderHistory:orderId"),
              field: "order_id",
            },
            {
              title: t("orderHistory:name"),
              field: "customer",
            },
            {
              title: t("orderHistory:orderType"),
              field: "order_type_id",
              lookup: {
                1: "Delivery",
                2: "Take Away",
                3: "Dine In",
              },
              render: (rowData) => {
                return (
                  <div className="statusRow px-5 py-1">
                    {statusText(delivery_type[rowData.order_type_id])}
                  </div>
                );
              },
            },
            {
              title: t("orderHistory:status"),
              field: "status",
              lookup: {
                Pending: "Pending",
                Delivered: "Delivered",
                Preparing: "Preparing",
                Rejected: "Rejected",
              },
              render: (rowData) => (
                <div className="statusRow px-5 py-1">
                  {statusText(rowData.order_status)}
                </div>
              ),
            },
            {
              title: t("orderHistory:totalAmount"),
              field: "total",
              filtering: false,
              render: (rowData) => <div>{rowData.total}</div>,
            },
            {
              title: t("orderHistory:orderTime"),
              field: "creation_time",
              type: "date",
              render: (rowData) =>
                moment(rowData?.creation_time).format("DD-MM-YYYY"),
            },
            {
              title: t("orderHistory:actions"),
              field: "birthCity",
              filtering: false,
              align: "right",
              export: false,
              render: (rowData) => (
                <IconButton
                  aria-label="delete"
                  onClick={() => openModalHandlder(rowData)}
                >
                  <InfoOutlined />
                </IconButton>
              ),
            },
          ]}
          data={order_history.map((or, index) => {
            return {
              ...or,
              status: or?.order_status?.split("-")[0]?.trim(),
              // creation_time: moment(or?.creation_time).format("DD-MM-YYYY"),
              // creation_time: or?.creation_time,
            };
          })}
          title={t("orderHistory:orderReports")}
          localization={HistoryTableLocalization[i18n.language]}
        />
      </div>
    </div>
  );
};

const modal_style = {
  // position: "absolute",
  // top: "100%",
  // left: "50%",
  // marginTop: "35px",
  // transform: "translate(-50%, -50%)",
  marginTop: "auto",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
