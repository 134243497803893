/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import {
  HistoryOutlined,
  HomeOutlined,
  InsightsOutlined,
  ListAltOutlined,
  LogoutOutlined,
  RestaurantMenuOutlined,
  SettingsOutlined,
  LoyaltyOutlined,
  PointOfSaleOutlined,
  FastfoodOutlined,
} from "@mui/icons-material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import LogoDevIcon from "@mui/icons-material/LogoDev";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./NavigationBar.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function NavigationBar(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const selected_nav = useSelector(
    (state) => state.sidebarReducer.selected_nav
  );

  const { t } = useTranslation();
  const { innerWidth: width, innerHeight: height } = window;
  const [active, setActive] = useState(selected_nav);
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [isExpended, setIsExpended] = useState(sidebarCollapsed ? false : true);

  useEffect(() => {
    setActive(selected_nav);
  }, [selected_nav]);

  useEffect(() => {
    window.addEventListener("beforeunload", defaultSidebarState);
    return () => {
      window.removeEventListener("beforeunload", defaultSidebarState);
    };
  }, []);
  const defaultSidebarState = (e) => {
    e.preventDefault();
    localStorage.removeItem("sidebar-collapsed");
  };

  const handleToggler = () => {
    if (isExpended) {
      props.setBodyWidth("52px");
      setIsExpended(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    props.setBodyWidth("200px");
    setIsExpended(true);
    localStorage.removeItem("sidebar-collapsed");
  };
  const handleActive = (a) => {
    setActive(a);
    dispatch({
      type: "SET_NAV",
      payload: a,
    });
  };

  const [sideMenuList, setSideMenuList] = useState([
    {
      name: "SideBar:home",
      icon: <HomeOutlined sx={{ color: "white" }} fontSize="medium" />,
      path: "/dashboard",
      selected: active === "SideBar:home" ? true : false,
    },
    {
      name: "SideBar:orders",
      icon: <ListAltOutlined sx={{ color: "white" }} fontSize="medium" />,
      selected: active === "SideBar:orders" ? true : false,
      path: "/dashboard/orders",
    },
    {
      name: "SideBar:orderHistory",
      icon: <HistoryOutlined sx={{ color: "white" }} fontSize="medium" />,
      selected: active === "SideBar:orderHistory" ? true : false,
      path: "/dashboard/orderHistory",
    },
    {
      name: "SideBar:menu",
      icon: (
        <RestaurantMenuOutlined sx={{ color: "white" }} fontSize="medium" />
      ),
      selected: active === "SideBar:menu" ? true : false,
      path: "/dashboard/menu",
    },
    {
      name: "SideBar:Tax",
      icon: (
        <PlagiarismOutlinedIcon sx={{ color: "white" }} fontSize="medium" />
      ),
      selected: active === "SideBar:tax" ? true : false,
      path: "/dashboard/tax",
    },
    // {
    //   name: 'SideBar:Promo',
    //   icon: (
    //     <LoyaltyOutlined sx={{ color: 'white' }} fontSize="medium" />
    //   ),

    //   path: '/dashboard/promo',
    // },
    // {
    //   name: "SideBar:statistics",
    //   icon: <InsightsOutlined sx={{ color: "white" }} fontSize="medium" />,
    //   selected: active === "SideBar:statistics" ? true : false,
    //   path: "/dashboard/statistics",
    // },
    {
      name: "SideBar:settings",
      icon: <SettingsOutlined sx={{ color: "white" }} fontSize="medium" />,
      selected: active === "SideBar:settings" ? true : false,
      path: "/dashboard/settings",
    },
    {
      name: "SideBar:pos",
      icon: <PointOfSaleOutlined sx={{ color: "white" }} fontSize="medium" />,
      selected: active === "SideBar:pos" ? true : false,
      path: "/pos",
    },
  ]);
  return (
    <div
      className={isExpended ? "Sidebar" : "Sidebar collapsed"}
      style={{
        overflowY: "scroll",
      }}
    >
      {/* <div style={{ width: "100%" }}> */}
      <div
        className="sidebar-header"
        style={{
          // height: 50,
          // width: 50,
          // borderRadius: 25,
          // backgroundColor: "white",
          borderBottom: "1px solid rgb(206,134,136)",
          // transition: "borderBottom 0.6s ease-in-out",
          marginBottom: 10,
        }}
        // style={{ transition: "0.5s ease" }}
      >
        <FastfoodOutlined
          className="sidebar-icon"
          sx={{ color: "white" }}
          fontSize="medium"
          onClick={handleToggler}
        />

        <div className="sidebar-logo">{props.businessDetails?.name}</div>
      </div>
      {/* <hr /> */}
      {/* </div> */}
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="sidebar-items"
      >
        {sideMenuList.map((item, index) => {
          if (item.name === "SideBar:Tax") {
            if (props.businessDetails.is_taxable === 1) {
              return (
                <Link to={item.path} style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 15,
                      opacity: active === item.name ? 1 : 0.5,
                    }}
                    className="item"
                    onClick={() => handleActive(item.name)}
                  >
                    {item.icon}
                    <Typography
                      className="sidebar-text"
                      style={{
                        marginLeft: 10,
                        fontWeight: "bold",
                        color: "white",
                        fontSize: 16,
                        marginRight: 25,
                      }}
                    >
                      {t(item.name)}
                    </Typography>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          } else {
            return (
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 15,
                    opacity: active === item.name ? 1 : 0.5,
                  }}
                  className="item"
                  onClick={() => handleActive(item.name)}
                >
                  {item.icon}
                  <Typography
                    className="sidebar-text"
                    style={{
                      marginLeft: 10,
                      fontWeight: "bold",
                      color: "white",
                      fontSize: 16,
                      marginRight: 25,
                    }}
                  >
                    {t(item.name)}
                  </Typography>
                </div>
              </Link>
            );
          }
        })}
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        className="sidebar-items"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 15,
            opacity: 0.5,
          }}
          className="item"
          onClick={() => {
            dispatch({ type: "REMOVE_TOKEN" });
            navigate("/");
          }}
        >
          <LogoutOutlined sx={{ color: "white" }} fontSize="medium" />
          <Typography
            className="sidebar-text"
            style={{
              marginLeft: 10,
              fontWeight: "bold",
              color: "white",
              fontSize: 15,
              marginRight: 25,
            }}
          >
            {t("SideBar:logout")}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
