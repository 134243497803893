import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import OrderScreen2ndColumn from "../components/OrderScreen2ndColumn";
import OrderScreen1stColumn from "../components/OrderScreen1stColumn";
import { Grid, Typography, Box, Button, Modal } from "@mui/material";
import { Form } from "react-bootstrap";
import "./Orders.css";
import {
  AccessTimeFilledOutlined,
  AccessTimeOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { updateStatus } from "../redux/actions/orders";
import {
  totalCalculator_tax,
  totalRevenueCalculator,
  itemAmountCalculator,
} from "../helperFunctions/TotalCalculator";
import Invoice from "../components/Invoice/Invoice";
import { getInvoice } from "../redux/actions/invoice";
import { checkRoute } from "../helperFunctions/routeHandler";
import noImage from "./../assets/no-image.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useAlert } from "react-alert";
import { showPrice } from "../config/currency";

function Orders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const element = document.getElementById("order-page-top");
  const allOrdersList = useSelector((state) => state.ordersReducer.ordersList);
  const order_status_list = useSelector(
    (state) => state.ordersReducer.orderStatus
  );
  const businessConfig = useSelector(
    (state) => state.businessConfigReducer.businessConfig
  );
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedTab, setTab] = useState("pending");
  const [selectedOrder, setOrder] = useState({
    order_status: "Pending",
    items: {},
  });
  console.log("element123", selectedOrder);

  const [open, setOpen] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalPreparing, setTotalPreparing] = useState(0);
  const [totalNew, setTotalNew] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const alert = useAlert();
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (openInvoiceModal) {
      if (totalPreparing > 1) {
        if (selectedOrder.order_id === totalPreparing[0].order_id) {
          setOrder(totalPreparing[1]);
        } else {
          setOrder(totalPreparing[0]);
        }
      } else {
        setOrder({
          order_status: "Preparing",
          items: {},
        });
      }
    }
  }, [openInvoiceModal]);

  // ---------------- Sort Orders card according to latest order on top --------
  function compareDate(a, b) {
    if (
      new Date(a.creation_time).getTime() > new Date(b.creation_time).getTime()
    ) {
      return -1;
    }
    if (
      new Date(a.creation_time).getTime() < new Date(b.creation_time).getTime()
    ) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    allOrdersList.sort(compareDate);
  }, []);

  useEffect(() => {
    if (allOrdersList && allOrdersList.length) {
      allOrdersList.every((element) => {
        if (element.order_status.toLowerCase() === "pending") {
          setOrder(element);
          return false;
        } else {
          return true;
        }
      });
    } else {
      setOrder({ order_status: "Pending", items: {} });
    }
  }, []);

  useEffect(() => {
    checkRoute(dispatch);
  }, []);

  const OrderTotal = (ord) => {
    console.log("total is runing");
    if (businessConfig.is_taxable) {
      return totalCalculator_tax(ord);
    } else {
      return totalRevenueCalculator([ord]);
    }
  };

  const modalHandler = (id) => {
    setOpen(true);
  };

  const statusHandler = async () => {
    if (!rejectLoading) {
      if (selectedStatus !== "") {
        setRejectLoading(true);
        try {
          await dispatch(
            updateStatus({
              order_id: selectedOrder.order_id,
              order_status: selectedStatus,
            })
          );
          setOpen(false);
          setSelectedStatus("");
          alert.show("Order Rejected!", {
            type: "success",
          });
          setRejectLoading(false);
          if (totalNew.length > 1) {
            if (selectedOrder.order_id === totalNew[0].order_id) {
              setOrder(totalNew[1]);
            } else {
              setOrder(totalNew[0]);
            }
            // console.log("Rejected with assigning");
          } else {
            // console.log("Rejected With Empty");
            setOrder({
              order_status: "Pending",
              items: {},
            });
          }
        } catch (err) {
          let error =
            err?.message && err?.message.split(" ").slice(0, 2).join(" ");

          if (error && error === "connect ECONNREFUSED") {
            alert.show("Server is down please contact your server provider!", {
              type: "error",
            });
            setRejectLoading(false);
          } else {
            alert.show("Something went Wrong while updating status!", {
              type: "error",
            });
            setRejectLoading(false);
          }
        }
      } else {
        alert.show("Please select a status!", {
          type: "error",
        });
        setRejectLoading(false);
      }
    }
  };

  const prepareHandler = async (id) => {
    if (!loading) {
      setLoading(true);
      try {
        await dispatch(
          updateStatus({
            order_id: id,
            order_status: { id: 2, name: "Preparing" },
          })
        );
        setLoading(false);
        alert.show("Order Accepted!", {
          type: "success",
        });
        setSelectedStatus("");
        if (totalNew.length > 1) {
          if (selectedOrder.order_id === totalNew[0].order_id) {
            setOrder(totalNew[1]);
          } else {
            setOrder(totalNew[0]);
          }
          // console.log("Total New Assigning: ", totalNew[0]);
          // console.log("order ", totalNew);
        } else {
          // console.log("Total New Empty ");
          setOrder({
            order_status: "Pending",
            items: {},
          });
        }
      } catch (err) {
        let error =
          err?.message && err?.message.split(" ").slice(0, 2).join(" ");
        setLoading(false);
        if (error && error === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong while updating status!", {
            type: "error",
          });
        }
      }
    }
  };

  function statusText(status) {
    if (status) {
      return status.split("-")[0].trim();
    }
  }

  const onChangeHandler = (val) => {
    setSelectedStatus(JSON.parse(val.target.value));
  };

  const fetchInvoiceHandler = async (id) => {
    try {
      await dispatch(getInvoice(id));
      // setTimeout(() => {
      setOpenInvoiceModal(true);
      setLoading(false);
      // console.log("Set Time Out Called");
      // }, 1000);
    } catch (error) {
      let err =
        error?.message && error?.message.split(" ").slice(0, 2).join(" ");

      if (err && err === "connect ECONNREFUSED") {
        alert.show("Server is down please contact your server provider!", {
          type: "error",
        });
      } else {
        alert.show("Something went wrong while generarting Invoice!", {
          type: "error",
        });
      }
    }
  };

  const statusDeliverHandler = async (id) => {
    if (!loading) {
      setLoading(true);
      try {
        await dispatch(
          updateStatus({
            order_id: id,
            order_status: { id: 4, name: "Delivered" },
          })
        );
        await fetchInvoiceHandler(id);
        // console.log("Fetch Invoice Called");
        alert.show("Order Delivered Successfully!", {
          type: "success",
        });
        setLoading(false);
        setSelectedStatus("");
        if (totalPreparing.length > 1) {
          if (selectedOrder.order_id === totalPreparing[0].order_id) {
            setOrder(totalPreparing[1]);
          } else {
            setOrder(totalPreparing[0]);
          }
        } else {
          setOrder({
            order_status: "Preparing",
            items: {},
          });
        }
      } catch (err) {
        let error =
          err?.message && err?.message.split(" ").slice(0, 2).join(" ");
        setLoading(false);
        if (error && error === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went Wrong while updating status!", {
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    countOrders();
  }, [allOrdersList]);

  const countOrders = () => {
    if (allOrdersList.length > 0) {
      const resultPending = allOrdersList.filter(
        (order) => order.order_status === "Pending"
      );
      const resultPreparing = allOrdersList.filter(
        (order) => order.order_status === "Preparing"
      );
      setTotalNew(resultPending);
      setTotalPreparing(resultPreparing);
    }
    // console.log("Result Pending: ", resultPending);
    // console.log("Result Preparing: ", resultPreparing);
  };
  // console.log("Total new", totalNew);
  // console.log("total Preparing", totalPreparing);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const itemNameStyle = {
    margin: "0px",
    width: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
      id="order-page-top"
    >
      <Grid container style={{ overflow: "hidden" }}>
        <Grid
          item
          lg={4}
          md={5}
          sm={8}
          style={{
            backgroundColor: "rgb(248,248,248)",
            padding: 25,
            paddingTop: 35,
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
            maxHeight: "100vh",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              style={{
                fontWeight: "bolder",
                textTransform: "uppercase",
                color: "rgb(113,113,113)",
                fontSize: 40,
              }}
            >
              {t("Orders:orders")}
            </Typography>
          </div>

          <div
            id="categorySelection"
            style={{
              backgroundColor: "white",
              borderRadius: 8,
              height: 55,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              position: "relative",
              margin: 20,
            }}
          >
            <div
              style={{
                height: 50,
                width: "50%",
                backgroundColor: "#A2191B",
                borderRadius: 8,
                position: "absolute",
                transition: "left 0.5s",
                left: `${selectedCategory * 50}%`,
              }}
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 26,
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedCategory(0);
                setTab("pending");
                if (totalNew.length > 0) {
                  setOrder(totalNew[0]);
                } else {
                  setOrder({
                    order_status: "Pending",
                    items: {},
                  });
                }
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: selectedCategory === 0 ? "white" : "rgb(50,50,50)",
                  marginRight: "20px",
                }}
                className="categoryButtonText"
              >
                {t("Orders:new")}
              </Typography>
              <span
                style={{
                  // border: "1px solid black",
                  backgroundColor: selectedCategory === 0 ? "white" : "#A2191B",
                  color:
                    selectedCategory === 0 ? "#A2191B" : "rgb(255,255,255)",
                  borderRadius: "50%",
                  padding: "0 6px",
                  position: "relative",
                  zIndex: 1,
                  marginLeft: 2,
                }}
              >
                {totalNew.length}
              </span>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                padding: 26,
              }}
              onClick={() => {
                setSelectedCategory(1);
                setTab("preparing");
                if (totalPreparing.length > 0) {
                  setOrder(totalPreparing[0]);
                } else {
                  setOrder({
                    order_status: "Pending",
                    items: {},
                  });
                }
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: selectedCategory === 1 ? "white" : "rgb(50,50,50)",
                }}
                className="categoryButtonText"
              >
                {t("Orders:preparing")}
              </Typography>
              <span
                style={{
                  // border: "1px solid black",
                  backgroundColor: selectedCategory === 1 ? "white" : "#A2191B",
                  color:
                    selectedCategory === 1 ? "#A2191B" : "rgb(255,255,255)",
                  borderRadius: "50%",
                  padding: "0 6px",
                  marginLeft: 15,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                {totalPreparing.length}
              </span>
            </div>
          </div>
          {/* <div
              style={{ flex: 1, cursor: 'pointer' }}
              onClick={() => {
                setSelectedCategory(2);
                setTab('rejected');
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: selectedCategory === 2 ? 'white' : 'rgb(50,50,50)',
                }}
                className="categoryButtonText"
              >
                {t('Orders:cancel')}
              </Typography>
            </div> */}

          <div className="order_card_container">
            <div style={{ height: "100%", overflow: "auto" }}>
              {allOrdersList.length ? (
                allOrdersList.map((item, index) => {
                  if (
                    statusText(item.order_status).toLowerCase() ===
                    selectedTab.toLowerCase()
                  ) {
                    return (
                      <div
                        key={index}
                        style={{
                          cursor: "pointer",
                          padding: 15,
                          borderRadius: 15,
                          margin: 20,
                          boxShadow: "0px 0px 27px 11px rgba(0,0,0,0.04)",
                          paddingLeft: 25,
                          paddingRight: 25,
                          marginBottom: 40,
                          backgroundColor:
                            item?.order_id === selectedOrder?.order_id
                              ? "#A2191B"
                              : "white",
                          color:
                            item?.order_id === selectedOrder?.order_id
                              ? "#fff"
                              : "#000",
                        }}
                        onClick={() => {
                          setOrder(item);
                          element.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography variant="h6" style={{ opacity: 0.8 }}>
                            {t("Orders:order")} #{item.order_id}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {showPrice(OrderTotal(item))}
                          </Typography>
                        </div>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "500", opacity: 0.3 }}
                        >
                          <AccessTimeOutlined
                            sx={{ opacity: 0.3 }}
                            fontSize="small"
                          />
                          {moment(
                            statusText(item.order_status).toLowerCase() ===
                              "pending"
                              ? item.creation_time
                              : item.modified_on
                          ).fromNow()}
                        </Typography>
                      </div>
                    );
                  }
                })
              ) : (
                <h1>No Order Yet!</h1>
              )}
            </div>
          </div>
        </Grid>

        <Grid
          item
          lg={8}
          md={7}
          sm={6}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxHeight: "100vh",
            overflowY: "scroll",
          }}
          className="order-content"
        >
          {Object.values(selectedOrder?.items)?.length > 0 ? (
            <>
              <div style={{ padding: 20 }}>
                <div
                  style={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: 25,
                    paddingBottom: 15,
                    paddingLeft: 40,
                    borderRadius: 20,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "500" }}
                      >
                        {selectedOrder?.customer}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: "bold",
                          color: "rgb(120,120,120)",
                        }}
                      >
                        {selectedOrder?.contact_no}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: "bold",
                          color: "rgb(120,120,120)",
                        }}
                      >
                        {selectedOrder?.complete_address} {selectedOrder?.area}
                      </Typography>
                    </div>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "500", opacity: 0.3 }}
                    >
                      Order ID: {selectedOrder?.order_id}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: "500", opacity: 0.3 }}
                    >
                      {t("Home:orderPlaced")}{" "}
                      {moment(selectedOrder?.creation_time).format(
                        "DD MM YYYY hh:mm:ss A"
                      )}
                    </Typography>
                  </div>
                  {selectedOrder?.order_status === "Pending" && (
                    <div>
                      <div
                        style={{
                          height: 100,
                          width: 100,
                          borderRadius: 50,
                          backgroundColor: "rgb(209,209,209)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 84,
                            width: 84,
                            borderRadius: 42,
                            backgroundColor: "rgb(248,248,248)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "500",
                              textTransform: "uppercase",
                              color: "#A2191B",
                            }}
                          >
                            {t("Orders:new")}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 25,
                    marginRight: 25,
                    marginTop: 10,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      flex: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "roboto",
                    }}
                  >
                    Image
                  </p>
                  <p
                    style={{
                      flex: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "roboto",
                    }}
                  >
                    Name
                  </p>

                  <p
                    style={{
                      flex: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "roboto",
                    }}
                  >
                    Quantity
                  </p>

                  <p
                    style={{
                      flex: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "roboto",
                    }}
                  >
                    Option
                  </p>

                  <p
                    style={{
                      flex: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "roboto",
                    }}
                  >
                    Total
                  </p>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  flex: 1,
                  overflowY: "auto",
                  alignItems: "flex-start",
                }}
              >
                {Object.values(selectedOrder.items).map((element, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 25,
                          marginRight: 25,
                          marginTop: 10,
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* {console.log("111")} */}
                        <div style={{ height: 80, flex: 1 }}>
                          {/* {console.log("Item Image: ", element?.item_image)} */}
                          <img
                            src={
                              element.item_image ? element.item_image : noImage
                            }
                            style={{
                              height: 60,
                              borderRadius: "50%",
                              width: 60,
                            }}
                            alt="Order pic"
                          />
                        </div>
                        <p
                          style={{
                            ...itemNameStyle,
                            opacity: 0.8,
                            flex: 1,
                            fontWeight: "bold",
                          }}
                        >
                          {element.item}
                        </p>

                        <Typography
                          variant="p"
                          style={{ opacity: 0.8, flex: 1, paddingLeft: 10 }}
                        >
                          x {element.item_qty}
                        </Typography>

                        <Typography
                          variant="p"
                          style={{ opacity: 0.8, flex: 1, paddingLeft: 10 }}
                        >
                          {Object.values(element.options).length !== 0
                            ? Object.values(element.options).map((i, index) => {
                                return i.map((a, myIndex) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        key={myIndex}
                                        style={{
                                          fontSize: 11,
                                          fontWeight: "500",
                                          opacity: 0.46,
                                        }}
                                      >
                                        {a.answer}
                                        {index + 1 !== i.length && ", "}
                                      </Typography>
                                    </div>
                                  );
                                });
                              })
                            : "--"}
                          <Typography
                            style={{
                              fontSize: 10,
                              fontWeight: "500",
                              opacity: 0.46,
                              wordBreak: "break-word",
                              paddingRight: "10px",
                            }}
                          >
                            {element.instruction}
                          </Typography>
                        </Typography>

                        <Typography
                          variant="p"
                          style={{ opacity: 0.8, flex: 1 }}
                        >
                          {showPrice(itemAmountCalculator(element))}
                        </Typography>
                      </div>
                      <div
                        style={{
                          borderBottom: "solid rgba(200,200,200,0.2) 1px",
                          marginLeft: 35,
                          marginRight: 10,
                        }}
                      />
                    </div>
                  );
                })}
                <>
                  <div
                    style={{
                      padding: 30,
                      borderTop: "solid rgb(200,200,200) 1px",
                      paddingBottom: 40,
                      paddingTop: 40,
                    }}
                  >
                    {console.log("Selected Order: ", selectedOrder)}
                    {selectedOrder?.delivery_charges !== null ? (
                      <Typography
                        variant="h6"
                        style={{ opacity: 0.8, fontSize: 12 }}
                      >
                        Delivery Charges:{" "}
                        {showPrice(selectedOrder?.delivery_charges)}
                      </Typography>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" style={{ opacity: 0.8 }}>
                        {t("Orders:orderAmount")}:{" "}
                        {showPrice(OrderTotal(selectedOrder))}{" "}
                        {businessConfig?.is_taxable ? (
                          <span style={{ fontSize: 10 }}>(incl. Tax)</span>
                        ) : null}
                      </Typography>
                      <div style={{ display: "flex" }}>
                        {statusText(
                          selectedOrder.order_status
                        ).toLowerCase() === "pending" ? (
                          <>
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "rgb(248,248,248)",
                                padding: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 10,
                                fontWeight: "600",
                                textTransform: "uppercase",
                                color: "rgb(108,108,108)",
                              }}
                              onClick={() => {
                                if (
                                  Object.values(selectedOrder.items).length ===
                                  0
                                ) {
                                  alert.show(
                                    "No Selected Order to perform action!",
                                    {
                                      type: "error",
                                    }
                                  );
                                } else {
                                  modalHandler(selectedOrder.order_id);
                                }
                              }}
                            >
                              {t("Orders:declineOrder")}
                            </div>

                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#A2191B",
                                padding: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 10,
                                fontWeight: "600",
                                textTransform: "uppercase",
                                color: "white",
                              }}
                              onClick={() => {
                                if (
                                  Object.values(selectedOrder.items).length ===
                                  0
                                ) {
                                  alert.show(
                                    "No Selected Order to perform action!",
                                    {
                                      type: "error",
                                    }
                                  );
                                } else {
                                  prepareHandler(selectedOrder.order_id);
                                }
                              }}
                            >
                              {loading ? (
                                <CircularProgress size={18} color="inherit" />
                              ) : (
                                t("Orders:acceptOrder")
                              )}
                            </div>
                          </>
                        ) : statusText(
                            selectedOrder.order_status
                          ).toLowerCase() === "preparing" ? (
                          <Button
                            className="mt-4"
                            style={{ color: "green", borderColor: "green" }}
                            variant="outlined"
                            onClick={() => {
                              if (
                                Object.values(selectedOrder.items).length === 0
                              ) {
                                alert.show(
                                  "No Selected Order to perform action!",
                                  {
                                    type: "error",
                                  }
                                );
                              } else {
                                statusDeliverHandler(selectedOrder.order_id);
                              }
                            }}
                          >
                            {loading ? (
                              <CircularProgress size={18} color="inherit" />
                            ) : (
                              "Deliver"
                            )}
                          </Button>
                        ) : (
                          <Button
                            disabled={true}
                            className="mt-4"
                            style={{ color: "red", fontSize: "11px" }}
                          >
                            {selectedOrder.order_status}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
                <div
                  style={{
                    borderBottom: "solid rgba(200,200,200,0.2) 1px",
                    marginLeft: 35,
                    marginRight: 10,
                  }}
                />
              </div>
              {/* *********************************************** */}
              {/* Modal */}
              <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...style, width: 400 }}>
                  <h5 id="parent-modal-title" style={{ margin: "15px 5px" }}>
                    Please select a reason
                  </h5>
                  <Form>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    >
                      <option values="">Select Reason</option>
                      {order_status_list
                        .filter(
                          (status) =>
                            statusText(status.name).toLowerCase() === "rejected"
                        )
                        .map((status) => {
                          return (
                            <option value={JSON.stringify(status)}>
                              {status.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Button
                      className="mt-4"
                      style={{ color: "red", border: "1px solid" }}
                      onClick={() => statusHandler()}
                    >
                      {rejectLoading ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : (
                        "Reject"
                      )}
                    </Button>
                  </div>
                </Box>
              </Modal>
              {/*  */}

              {/* *********************************************** */}
            </>
          ) : (
            <div className="empty-order-container">
              <div className="empty-order-wrapper">
                <h4 className="empty-order-text">No orders to show.</h4>
              </div>
            </div>
          )}
        </Grid>
        {/* Modal For Invoice*/}
        <Modal
          open={openInvoiceModal}
          onClose={() => {
            setOpenInvoiceModal(false);
          }}
          aria-labelledby="invoice-modal"
          aria-describedby="parent-invoice-modal"
          style={{ overflow: "scroll" }}
        >
          <Box sx={{ ...style, width: 400, marginTop: "60px" }}>
            <Invoice
              onClose_handler={() => {
                setOpenInvoiceModal(false);
              }}
            />
          </Box>
        </Modal>
        {/* Modal End */}
      </Grid>
    </div>
  );
}

const styles = {};

export default Orders;
