import { Typography } from "@mui/material";
import React from "react";
import noImg from "./../assets/no-image.png";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { showPrice } from "../config/currency";
import "./POSItem.css";

function POSItem({ data, onClick, setInstruction, boxSize }) {
  return (
    <div
      style={{
        padding: 5,
        width: boxSize + 10,
        boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px 3px",
        margin: 15,
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        maxHeight: boxSize + 80,
        position: "relative",
      }}
      onClick={() => {
        onClick(data);
        setInstruction("");
      }}
    >
      <>
        {data?.discounted_price &&
        data?.discounted_price !== data?.item_price ? (
          <div
            style={{ position: "absolute", width: "90%", textAlign: "right" }}
          >
            <DiscountOutlinedIcon
              style={{
                color: "rgb(163, 24, 27)",
              }}
            />
          </div>
        ) : null}
        {data.image_link ? (
          <img
            src={data.image_link}
            style={{
              height: boxSize,
              width: boxSize,
              objectFit: "cover",
              borderRadius: 5,
            }}
            alt="item pic"
          />
        ) : (
          <div
            style={{
              height: boxSize,
              width: boxSize,
              borderRadius: 5,
              backgroundColor: "rgba(0,0,0,0.05)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={noImg}
              style={{
                width: "70%",
                height: "70%",
                borderRadius: 10,
                objectFit: "cover",
                opacity: 0.3,
              }}
              alt="item pic"
            />
          </div>
        )}

        <Typography
          noWrap
          variant="subtitle2"
          style={{ textAlign: "center", fontSize: 16 }}
        >
          {data.item_title}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            textAlign: "center",
          }}
        >
          {data?.discounted_price &&
          data?.discounted_price !== data?.item_price ? (
            <p className="price">
              {showPrice(data.discounted_price)}
              <strike>{showPrice(data.item_price)}</strike>{" "}
            </p>
          ) : (
            <p className="price"> {showPrice(data.item_price)}</p>
          )}
        </Typography>
      </>
    </div>
  );
}

export default POSItem;
