import React, { useEffect } from 'react'
import { Typography } from "@mui/material"
import SalesMonthChart from '../Charts/SalesMonthChart'
import PopularItems from '../Charts/PopularItems'
import SalesByItemsChart from '../Charts/SalesByItemsChart'
import SalesByCategory from '../Charts/SalesByCategory'
import "./statistics.css"
import { checkRoute } from "../helperFunctions/routeHandler"
import { useDispatch } from "react-redux"

function Statistics() {
    const dispatch = useDispatch()

    useEffect(() => {
        checkRoute(dispatch)
    }, [])

    return (
        <div style={{ padding: 50, paddingTop: 25 }}>
            <div>
                <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <Typography className='chart_heading'>
                        Most Popular Items
                    </Typography>
                </div>
                <div style={{ marginBottom: "50px" }}>
                    <PopularItems />
                </div>
            </div>
            <div>
                <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <Typography className='chart_heading'>
                        Sales By Month
                    </Typography>
                </div>
                <div>
                    <SalesMonthChart />
                </div>
            </div>
            <div>
                <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <Typography className='chart_heading'>
                        Sales By Items
                    </Typography>
                </div>
                <div>
                    <SalesByItemsChart />
                </div>
            </div>
            <div>
                <div style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <Typography className='chart_heading'>
                        Sales By Category
                    </Typography>
                </div>
                <div style={{ width: "500px", margin: '0 auto' }}>
                    <SalesByCategory />
                </div>
            </div>
        </div>
    )
}

export default Statistics
