import axios from "axios";
import { serverUrl } from "../../config";
import { SET_MENU_POS } from "../constants";

export const fetchPOSMenu = () => async (dispatch) => {
    const token = window.localStorage.getItem('access_token')
    console.warn(token)
    return await axios({
        url: "api/vendor/menu",
        method: "GET",
        baseURL: serverUrl,
        headers: { "x-auth-token": token },
    }).then((res) => {
        dispatch({
            type: SET_MENU_POS,
            payload: res.data.payload,
        });
        console.warn(res.data)

        return res;
    })
        .catch((err) => {
            // console.log('error:', err);
            console.log("error:", err.response);
            throw err.response;
        });


};