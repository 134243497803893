import { Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import useWindowSize from "../helperFunctions/useWindowSize";
import "./Custom.css";

function OpenOrders({
  localId,
  order,
  setOrderHandler,
  deleteOrderHandler,
  isSelected,
  mode,
  cancelEdit,
}) {
  const size = useWindowSize();
  const boxSize = size.width / 11;

  return (
    <div
      style={{
        padding: 5,
        margin: 5,
        backgroundColor: isSelected ? "#A2191B" : "white",
        borderRadius: 5,
        cursor: "pointer",
        width: boxSize + 60,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        zIndex: 1,
      }}
    >
      <div
        className="ed-del-icons"
        onClick={() =>
          mode === localId ? cancelEdit() : setOrderHandler(order, localId)
        }
      >
        <span>
          {mode === localId ? (
            <CancelIcon
              className="cancel-icon"
              style={{ marginRight: 6, color: isSelected ? "#fff" : "#000" }}
              onClick={() => {
                cancelEdit();
              }}
            />
          ) : (
            <EditIcon
              className="edit-icon"
              style={{ marginRight: 6, color: isSelected ? "#fff" : "#000" }}
              onClick={() => setOrderHandler(order, localId)}
            />
          )}
        </span>
        <Typography
          variant="subtitle2"
          style={{
            textAlign: "center",
            fontSize: 14,
            color: isSelected ? "#fff" : "#000",
            marginLeft: 20,
          }}
        >
          #{order[0].identifier}
        </Typography>
      </div>
      <div className="edit-delete-icons">
        <span style={{ position: "relative", zIndex: 999999 }}>
          <DeleteIcon
            className="delete-icon"
            onClick={() => {
              deleteOrderHandler(localId);
            }}
            style={{
              color: isSelected ? "#fff" : "#000",
              position: "relative",
              zIndex: 12345,
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default OpenOrders;
