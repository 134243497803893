import { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography,
  Modal,
  Fade,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
} from "@mui/material";
import { Modal as BootstrapModal } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { CreateTax, deleteTaxes, editTaxes } from "../redux/actions/tax";
import { editCategoriesTax, getCategories } from "../redux/actions/category";
import { useTranslation } from "react-i18next";
import "./Tax.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "50%",
  minWidth: "40%",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  paddingRight: 8,
  maxHeight: "80%",
  overflowY: "auto",
};

export default function Tax() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const taxesTypes = useSelector((state) => state.taxReducer.tax);
  const reduxCategories = useSelector(
    (state) => state.categoryReducer.categories
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState(null);
  const [selectedTaxId, setSelectedTaxId] = useState(null);
  const [categories, setCategories] = useState(null);
  const [update, setUpdate] = useState(false);
  const [reduxUpdated, setReduxUpdated] = useState(false);
  // console.log(
  //   "USer selector: ",
  //   useSelector((state) => state.taxReducer)
  // );
  // console.log("Catgegories: ", categories);

  const handleCheckBox = (e, value) => {
    // let catArray = [];
    // console.log("Check Box Value Change", e.target.value, " ;;;;", value);
    let catArray = categories.map((data) =>
      value === data.id ? { ...data, check: !data.check } : { ...data }
    );
    setCategories(catArray);
  };

  const handleCategories = async () => {
    setLoading(true);
    let categoryToUpdate = [];
    categories.forEach((data) => {
      if (data.check) {
        categoryToUpdate.push(data.id);
      }
    });
    if (categoryToUpdate.length > 0) {
      const data = {
        tax_id: selectedTaxId,
        cat_ids: categoryToUpdate,
      };
      dispatch(editCategoriesTax(data));
      setUpdate(!update);
      setLoading(false);
      setSelectedTaxId(null);
      handleClose();

      alert.show("Categoryies tax updated", {
        type: "success",
      });
    } else {
      setLoading(false);
      alert.show("Please select atleast one category to add tax on it", {
        type: "error",
      });
    }
  };

  useEffect(() => {
    setTaxes(taxesTypes);
    // console.log("Taxes Types: ", taxesTypes);
  }, [reduxUpdated]);

  useEffect(() => {
    // console.log("first");
    if (selectedTaxId !== null) {
      const d = reduxCategories.map((value) =>
        value.tax_id === selectedTaxId
          ? { ...value, check: true, defaultCheck: true }
          : { ...value, check: false, defaultCheck: false }
      );
      setCategories(d);
    }
  }, [selectedTaxId, update]);

  useEffect(() => {
    // console.log("Database Hit");
    dispatch(getCategories()).then((res) =>
      setCategories([...res?.data?.categories])
    );
    // return () => {
    //   return 1;
    // };
  }, [update]);

  const columns = [
    { title: t("tax:id"), field: "id", editable: false },
    { title: t("tax:name"), field: "name" },
    {
      title: t("tax:rate"),
      field: "rate",
      editComponent: (props) => (
        <Input
          // defaultValue={props.value}
          value={props.value}
          min={0}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            e.target.value >= 0
              ? props.onChange(e.target.value)
              : props.onChange(0)
          }
          type="number"
        />
      ),
    },
  ];
  //   console.log("BusinessConfig in tax.js 74: ", tax);
  // console.log("Taxes variable: ", taxes);

  const addTax = (newRow) => {
    // console.log("new Row", Object.values(newRow).length);
    if (Object.values(newRow).length === 2) {
      dispatch(CreateTax(newRow)).then((res) => {
        // console.log("Response");
        setTaxes([...taxes, res.data.payload]);
        alert.show("Tax Added Successfully", {
          type: "success",
        });
      });
    } else {
      alert.show("Name and Rate Cannot be empty", {
        type: "error",
      });
    }

    // setReduxUpdated(!reduxUpdated);
  };
  // const deleteTax = (rowIdToBeDeleted) => {
  //   dispatch(deleteTaxes(rowIdToBeDeleted));
  // };
  const editTax = (edittedRow) => {
    if (edittedRow.name !== "" && edittedRow.rate !== "") {
      const edittedObject = {
        id: edittedRow.id,
        name: edittedRow.name,
        rate: Number(edittedRow.rate),
      };
      dispatch(editTaxes(edittedObject)).then((res) =>
        setReduxUpdated(!reduxUpdated)
      );
      alert.show("Tax Editted Successfully", {
        type: "success",
      });
    } else {
      alert.show("name and rate cannot be empty", {
        type: "error",
      });
    }
    // .then((res) => {
    // let editArray = [...taxes];
    // editArray.map((taxes) =>
    //   taxes.id === edittedRow.id
    //     ? { ...edittedRow, business_id: taxes.business_id }
    //     : taxes
    // );
    // console.log("EditArray: ", [...editArray]);
    // console.log("Editted Row: ", edittedRow.id);
    // setTaxes([...editArray]);
    // });
    // setReduxUpdated(!reduxUpdated);
  };

  return (
    <div className="tax-page">
      <h4 className="tax-heading">{t("tax:tax")}</h4>

      <MaterialTable
        className="tax-table"
        title={t("tax:taxes")}
        data={taxes}
        columns={columns}
        editable={{
          onRowAdd: (newRow) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                addTax(newRow);
                resolve();
              }, 2000);
            }),
          // onRowDelete: (selectedRow) =>
          //   new Promise((resolve, reject) => {
          //     const rowToDelete = selectedRow.id;
          //     //   const updatedRows = [...taxes];
          //     //   updatedRows.splice(index, 1);
          //     setTimeout(() => {
          //       deleteTax(rowToDelete);
          //       resolve();
          //     }, 2000);
          //   }),
          onRowUpdate: (updatedRow, oldRow) =>
            new Promise((resolve, reject) => {
              const rowToUpdate = {
                id: updatedRow.id,
                name: updatedRow.name,
                rate: updatedRow.rate,
              };
              //   updatedRows[index] = updatedRow;
              setTimeout(() => {
                editTax(rowToUpdate);
                resolve();
              }, 2000);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: "first",
        }}
        onRowClick={(event, rowData) => {
          // console.log("Raw Data: ", rowData);
          setSelectedTaxId(rowData.id);
          handleOpen();
        }}
      />
      {
        selectedTaxId && (
          <BootstrapModal show={open} onHide={handleClose} keyboard={false}>
            <BootstrapModal.Header closeButton>
              <BootstrapModal.Title>
                <Typography style={{ fontSize: 21, fontWeight: "500" }}>
                  {/* {t('Menu:addNewCategory')} */} Add Tax To Categories
                </Typography>
              </BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
              {categories?.length && (
                <ul class="list-group categories-list">
                  {categories.map((value) => {
                    // let key = 0;
                    // console.log(
                    //   "VAlue: 164:  ",
                    //   value.tax_id.toString() + (++key)
                    // );
                    // console.log(
                    //   "DefaulCheck: ",
                    //   value.defaultCheck,
                    //   " Check: ",
                    //   value.check
                    // );
                    return (
                      <li
                        class={`list-group-item ${
                          value.defaultCheck && "disabled"
                        }`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={value.name}
                      >
                        <span key={value.name}>{value.name}</span>
                        <span key={value.defaultCheck}>
                          <Checkbox
                            // {value.tax_id === selectedTaxId}
                            disabled={value.defaultCheck}
                            checked={value.check}
                            onChange={(e) => handleCheckBox(e, value.id)}
                          />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
              <div
                className="addButton noselect"
                onClick={handleCategories}
                style={{
                  width: "50%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  margin: "20px auto",
                  marginBottom: 0,
                  textAlign: "right",
                }}
                // onClick={addNewCategory}
              >
                {/* {t('Menu:createCategory')} */}
                {loading ? (
                  <div class="spinner-border text-danger" role="status" />
                ) : (
                  "Add"
                )}
              </div>
            </BootstrapModal.Body>
          </BootstrapModal>
        )
        // (
        // <Modal
        //   aria-labelledby="transition-modal-title"
        //   aria-describedby="transition-modal-description"
        //   open={open}
        //   onClose={handleClose}
        //   closeAfterTransition
        //   BackdropComponent={Backdrop}
        //   BackdropProps={{
        //     timeout: 500,
        //   }}
        // >
        //   <Fade in={open}>
        //     <Box sx={style}>
        //       <div style={{ marginBottom: 15 }}>
        //         <Typography style={{ fontSize: 21, fontWeight: "500" }}>
        //           {/* {t('Menu:addNewCategory')} */} Add Tax To Categories
        //         </Typography>
        //       </div>

        //       {categories?.length && (
        //         <ul class="list-group categories-list">
        //           {categories.map((value) => {
        //             // console.log("VAlue: 164:  ", value);
        //             return (
        //               <li
        //                 class={`list-group-item ${
        //                   value.defaultCheck && "disabled"
        //                 }`}
        //                 style={{
        //                   display: "flex",
        //                   justifyContent: "space-between",
        //                 }}
        //               >
        //                 <span>{value.name}</span>
        //                 <span>
        //                   <Checkbox
        //                     // {value.tax_id === selectedTaxId}
        //                     disabled={value.defaultCheck}
        //                     checked={value.check}
        //                     onChange={(e) => handleCheckBox(e, value.id)}
        //                   />
        //                 </span>
        //               </li>
        //             );
        //           })}
        //         </ul>
        //       )}
        //       <div
        //         className="addButton noselect"
        //         onClick={handleCategories}
        //         style={{
        //           width: "50%",
        //           paddingTop: 20,
        //           paddingBottom: 20,
        //           margin: "20px auto",
        //           marginBottom: 0,
        //           textAlign: "right",
        //         }}
        //         // onClick={addNewCategory}
        //       >
        //         {/* {t('Menu:createCategory')} */}
        //         {loading ? (
        //           <div class="spinner-border text-danger" role="status" />
        //         ) : (
        //           "Add"
        //         )}
        //       </div>
        //     </Box>
        //   </Fade>
        // </Modal>
        // )
      }
    </div>
  );
}
