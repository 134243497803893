/* eslint-disable import/no-anonymous-default-export */
export default {
  Login: {
    startNow: 'START NOW',
    loginToAccount: 'Login to your account',
    emailAddress: 'Email Address',
    password: 'Password',
    continueToDashboard: 'Continue To Dashboard',
    forgetPassword: 'Forgot your password?',
    recoverNow: 'Recover Now',
  },
  Home: {
    restaurantName: 'Restaurant Name',
    addressHere: 'Address Here, Karachi, Pakistan.',
    ordersDelivered: 'Orders Delivered',
    revenueGenerated: 'Revenue Generated',
    orderList: 'Order List',
    Order: 'Order',
    orderPlaced: 'Order placed at',
    contact: 'Contact No: ',
    orderModified: 'Order modified at',
    itemName: 'Item Name',
    extraCheese: 'Extra Cheese',
    Items: 'Items',
    orderPending: 'Orders Pending',
    orderPreparing: 'Orders Preparing',
  },
  Orders: {
    orders: 'Orders',
    new: 'New',
    preparing: 'Preparing',
    delivery: 'Delivery',
    cancel: 'Cancel',
    order: 'Order',
    orderAmount: 'Order Amount',
    declineOrder: 'Decline Order',
    acceptOrder: 'Accept Order',
  },
  orderHistory: {
    totalOrders: 'Total Orders',
    ordersPending: 'Orders Pending',
    ordersDelivered: 'Orders Delivered',
    ordersRejected: 'Orders Rejected',
    orderReports: 'Order Reports',
    recentOrders: 'Recent Orders',
    name: 'Name',
    orderId: 'Order Id',
    status: 'Status',
    totalAmount: 'Total Amount',
    orderTime: 'Order Date',
    actions: 'Actions',
    orderType: 'Order Type',
  },
  Menu: {
    menuItems: 'Menu Items',
    errorMsg: "You haven't added any item yet, why not start by adding one?",
    addNewCategory: 'Add New Category',
    deleteCategory: 'Delete Category',
    categoryTitle: 'Category Title',
    categoryDescription: 'Category Description',
    createCategory: 'Create Category',
    addNew: 'Add New',
    item: 'Item',
    itemName: 'Item Name',
    itemDescription: 'Item Description',
    itemImage: 'Item Image',
    itemCost: 'Item Cost',
    enableDiscountedRates: 'Enable Discounted Rates?',
    discountedItemCost: 'Discounted Item Cost',
    questions: 'Questions',
    addNewQuestion: 'Add New Question',
    question: 'Question',
    addNewOption: 'Add New Option',
    questionType: 'Question Type',
    option: 'Option',
    PriceOptional: 'Price (Optional)',
    linkedWith: 'Linked with',
    clickHereToUnlink: 'Click here to unlink',
    similarNameError: 'We found a similar item with the name',
    clickHere: 'Click here',
    toLinkOptions: 'to link the item with this option',
    createItem: 'Create Item',
    alertError1: 'Error! Please enter category title',
    alertError2: 'Error! Email address is badly formatted.',
    alertError3: 'Error! Please enter category title',
    alertError4: 'Error! Please enter Item Name',
    selectTaxType: 'Select Tax Type',
    taxesType: 'Taxes Type',
    productImage: 'Product Image',
  },
  Settings: {
    settings: 'Settings',
    businessName: 'Business Name',
    businessUsername: 'Business Username',
    contactNumber: 'Contact Number',
    deliveryCharges: 'Delivery Charges',
    minOrderAmount: 'Minimum Order Amount',
    colorScheme: 'Color Scheme',
    primaryColor: 'Primary Color',
    secondaryColor: 'Secondary Color',
    businessDescription: 'Business Description',
    coverImages: 'Cover Images',
    updateCoverImages: 'Update Cover Images',
    updateLogoImages: 'Update Logo Images',
    logoImage: 'Logo Image',
    businessLocation: 'Business Location',
    locations: 'Locations',
    addLocation: 'Add Location',
    locationTitle: 'Location Title',
    locationDeliveryRadius: 'Location Delivery Radius',
    operatingTimes: 'Operating Times',
    startTime: 'Start Time',
    endTime: 'End Time',
    addAnotherSlot: 'Add Another Slot',
    setForAllDays: 'Set for all days',
    updateDetails: 'Update Details',
    updateAreas: 'Update Areas',
    AreasManager: 'Areas Managment',
  },
  SideBar: {
    home: 'Home',
    orders: 'Orders',
    orderHistory: 'Order History',
    menu: 'Menu',
    statistics: 'Statistics',
    settings: 'Settings',
    logout: 'Logout',
    pos: 'POS',
  },
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  pos: {
    searchCategory: 'Search Category',
    searchOpenOrder: 'Search Open Order',
    searchItems: 'Search Items',
    stayOpen: 'Stay Open',
    takeAway: 'Take Away',
    generatingOrder: 'Generating Order...',
    dineIn: 'Dine In',
    itemName: 'Item Name',
    quantity: 'Qty',
    price: 'Price',
    subtotal: 'Subtotal',
    orderTotal: 'Order Total',
    backToAdmin: 'Back To Admin',
    logout: 'Logout',
    all: 'All',
    homeDelivery: 'Home Delivery',
  },
  editItemModal: {
    price: 'Item Price',
    discountedPrice: 'Discounted Price',
    discountPos: 'Discount On POS',
    discountOnline: 'Discount On Online',
    startDate: 'Start Date',
    isVisble: 'Is Visible',
  },
  tax: {
    tax: 'Tax',
    taxes: 'Taxes',
    id: 'Id',
    name: 'Name',
    rate: 'Rate %',
    actions: 'Actions',
  },
};
