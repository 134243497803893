/* eslint-disable import/prefer-default-export */

// export const serverUrl = 'http://localhost:3002';
export const serverUrl = 'https://server.synevry.bitrupt.co/';
// export const serverUrl = "https://api.cravings.synevry.com";

// export const serverUrl = "http://192.168.100.43:3002";
// export const serverUrl = 'https://munchapi.travoir.com/';
// export const serverUrl = "https://server.houseoffries.synevry.com/";
// export const serverUrl = "https://test.munch.travoir.com/";

// export const serverUrl =
//   "https://cors-anywhere.herokuapp.com/https://test.munch.travoir.com/";
// export const serverUrl = "https://test.munch.travoir.com/";
// export const serverUrl = "https://server.test.synevry.com/";
// export const serverUrl = "http://192.168.18.29:3002/";

// export const serverUrl = "http://192.168.100.77:3002";

// export const serverUrl =
//   'http://ec2-3-137-136-218.us-east-2.compute.amazonaws.com:3002/';
