export function checkRoute(dispatch) {

    let route = window.location.href.split('/');
    route = route[route.length - 1]
    if (route === 'dashboard') {
        dispatch({
            type: 'SET_NAV',
            payload: 'SideBar:home'
        })
    } else {
        dispatch({
            type: 'SET_NAV',
            payload: `SideBar:${route}`
        })
    }
}