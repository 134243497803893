import React from "react";
import { Modal } from "react-bootstrap";
import {
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "./CustomerDetailModal.module.css";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const CustomerDetailModal = ({
  show,
  onClose,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  contact,
  setContact,
  city,
  setCity,
  email,
  setEmail,
  completeAddress,
  setCompleteAddress,
  onSubmit,
  area,
  setArea,
  loading,
}) => {
  const areas = useSelector((state) => state.addressReducer.areas);
  let groupedAreas = areas.reduce(
    (h, obj) =>
      Object.assign(h, { [obj.city]: (h[obj.city] || []).concat(obj) }),
    {}
  );
  return (
    <>
      <Modal show={show} onHide={onClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <div className={styles.inputDiv}>
              <div>
                <TextField
                  className={styles.input}
                  label="First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <TextField
                className={styles.input}
                label="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className={styles.inputDiv}>
              <TextField
                className={styles.input}
                label="Contact"
                type="number"
                value={contact}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  e.target.value >= 0
                    ? setContact(e.target.value)
                    : setContact(0)
                }
              />
              <TextField
                className={styles.input}
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <FormControl className={styles.inputStyle}>
              <InputLabel id="select_city">Select Your City</InputLabel>
              <Select
                labelId="select_city"
                label="Select Your City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                {Object.keys(groupedAreas).map((cityIns) => (
                  <MenuItem value={cityIns}>{cityIns}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={styles.inputStyle}>
              <InputLabel id="select_city">Select Your Area</InputLabel>
              <Select
                labelId="select_area"
                disabled={!city}
                label="Select Your City"
                value={area}
                onChange={(e) => {
                  setArea(e.target.value);
                  if (e.target.value) {
                    let selectedArea = groupedAreas[city].find(
                      (val) => val.area === e.target.value
                    );

                    localStorage.setItem(
                      "deliveryCharges",
                      JSON.stringify(selectedArea.delivery_charges)
                    );
                  }
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: "30vh" } } }}
              >
                {Object.values(groupedAreas).length &&
                  groupedAreas[city]?.map((area) => (
                    <MenuItem value={area.area}>{area.area}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              // className={styles.input}
              label="Your Complete Address"
              type="text"
              className={styles.inputStyle}
              value={completeAddress}
              onChange={(e) => setCompleteAddress(e.target.value)}
            />
            <button
              className={styles.buttonStyle}
              type="submit"
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Place Order"
              )}
            </button>
          </FormGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerDetailModal;
