import { Typography } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import "./POSCartItem.css";

function POSCartItem({ data, removeItem, index, increase_qty, decrease_qty }) {
  let subTotal = data.item_quantity * data.discounted_price;
  let itemTotal = data.item_price;
  // console.log("Instruction ", data.instruction);
  data.options.forEach((op) => {
    subTotal += parseInt(data.item_quantity) * parseFloat(op.option_price);
  });
  data.options.forEach((op) => {
    itemTotal += Math.ceil(op.option_price);
  });

  return (
    <div
      style={{
        borderBottom: "solid rgba(0,0,0,0.05) 1px",
      }}
    >
      <div
        style={{
          padding: 10,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            flex: 2.5,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            width: 190,
          }}
        >
          <div
            style={{ marginRight: "8px", cursor: "pointer" }}
            onClick={() => {
              removeItem(index);
            }}
          >
            <CancelIcon />
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <p className="item-title" style={{ fontWeight: "bold" }}>
              {data?.item_title}
            </p>
            {/* <Typography variant="body2" style={{ overflowWrap: "break-word" }}>
              {data?.item_description?.split(",")?.join(", ")}
            </Typography> */}
          </div>
        </div>
        <div style={{ flex: 1.5 }}>
          <div style={{ display: "flex", alignItems: "center", marginLeft: 6 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0 10px",
                marginRight: "15px",
                paddingLeft: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="qty_button"
                onClick={() =>
                  data.item_quantity === 1
                    ? removeItem(index)
                    : decrease_qty(index)
                }
              >
                -
              </button>
              <Typography
                variant="body2"
                style={{ flex: 1, padding: 5, paddingTop: 15 }}
              >
                {data.item_quantity}
              </Typography>

              <button
                className="qty_button"
                onClick={() => increase_qty(index)}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant="body2" style={{ flex: 1 }}>
            {data?.discounted_price &&
            data?.discounted_price < data?.item_price ? (
              <span>
                {data.discounted_price} <strike>{data.item_price}</strike>{" "}
              </span>
            ) : (
              <span>{itemTotal}</span>
            )}
          </Typography>
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant="body2" style={{ flex: 1 }}>
            <span>{subTotal}</span>
          </Typography>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          paddingLeft: 38,
        }}
      >
        <Typography variant="body2">
          {Object.values(data.options).length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                paddingRight: 30,
              }}
            >
              {Object.values(data.options).map((i, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: 11,
                      fontWeight: "500",
                      opacity: 0.46,
                      wordBreak: "break-word",
                    }}
                  >
                    {i.option}
                    {index + 1 !== i.length && ", "}
                  </Typography>
                );
              })}
            </div>
          )}
        </Typography>
      </div>
      <div
        style={{
          flex: 1,
          paddingLeft: 38,
          paddingRight: 30,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: 11,
            fontWeight: "500",
            opacity: 0.46,
            wordBreak: "break-word",
          }}
        >
          {data?.instruction}
        </Typography>
      </div>{" "}
    </div>
  );
}

export default POSCartItem;
