/* eslint-disable import/no-anonymous-default-export */
export default {
  Login: {
    startNow: "JETZT ANFANGEN",
    loginToAccount: "Melde dich in deinem Konto an",
    emailAddress: "E-Mail-Addresse",
    password: "Passwort",
    continueToDashboard: "Weiter zum Dashboard",
    forgetPassword: "Passwort vergessen?",
    recoverNow: "Jetzt erholen",
  },
  Home: {
    restaurantName: "Restaurantname",
    addressHere: "Adresse hier, Karachi, Pakistan.",
    ordersDelivered: "Gelieferte Bestellungen",
    revenueGenerated: "Erwirtschafteter Umsatz ",
    orderList: "Bestellliste",
    Order: "Befehl",
    orderPlaced: "Bestellung geändert am",
    orderModified: "Order modified at",
    itemName: "Artikelname",
    extraCheese: "Extra Käse",
    Items: "Produkte",
    orderPending: "Bestellung anstehend",
    orderPreparing: "Auftragsvorbereitung",
  },

  Orders: {
    orders: "Aufträge",
    new: "Neu",
    preparing: "Vorbereitung",
    delivery: "Die Zustellung",
    cancel: "Abbrechen",
    order: "Befehl",
    orderAmount: "Bestellbetrag",
    declineOrder: "Bestellung ablehnen",
    acceptOrder: "Bestellung annehmen",
  },
  orderHistory: {
    totalOrders: "Gesamtbestellungen",
    ordersPending: "Bestellungen ausstehend",
    ordersDelivered: "Gelieferte Bestellungen",
    ordersCancelled: "Bestellungen storniert",
    orderReports: "Bestellberichte",
    recentOrders: "letzte Bestellungen",
    name: "Name",
    orderId: "Bestellnummer",
    status: "Status",
    totalAmount: "Gesamtbetrag",
    orderTime: "Bestellzeitpunkt",
    actions: "Aktionen",
    orderType: "Art der Bestellung",
  },
  Menu: {
    menuItems: "Menüpunkte",
    errorMsg:
      "Sie haben noch keinen Artikel hinzugefügt, warum nicht gleich einen hinzufügen?",
    addNewCategory: "neue Kategorie hinzufügen",
    deleteCategory: "Kategorie löschen",
    categoryTitle: "Kategorietitel",
    categoryDescription: "Kategorie beschreibung",
    createCategory: "Kategorie erstellen",
    addNew: "Neue hinzufügen",
    item: "Artikel",
    itemName: "Artikelname",
    itemDescription: "Artikelbeschreibung",
    itemImage: "Artikelbild",
    itemCost: "Gegenstandskosten",
    enableDiscountedRates: "Ermäßigte Preise aktivieren?",
    discountedItemCost: "Ermäßigte Artikelkosten",
    questions: "Fragen",
    addNewQuestion: "Neue Frage hinzufügen",
    question: "Frage",
    addNewOption: "Neue Option hinzufügen",
    questionType: "Fragetyp",
    option: "Möglichkeit",
    PriceOptional: "Preis (optional)",
    linkedWith: "Verbunden mit",
    clickHereToUnlink: "Klicken Sie hier, um die Verknüpfung aufzuheben",
    similarNameError:
      "Wir haben einen ähnlichen Artikel mit dem Namen gefunden",
    clickHere: "Hier klicken",
    toLinkOptions: "um den Artikel mit dieser Option zu verknüpfen",
    createItem: "Artikel erstellen",
    alertError1: "Fehler! Bitte Kategorietitel eingeben",
    alertError2: "Fehler! Die E-Mail-Adresse ist falsch formatiert.",
    alertError3: "Fehler! Bitte Kategorietitel eingeben",
    alertError4: "Fehler! Bitte Artikelbezeichnung eingeben",
    selectTaxType: "Wählen Sie den Steuertyp aus",
    taxesType: "SteuernArt",
    productImage: "Produktbild",
  },
  Settings: {
    settings: "Einstellungen",
    businessName: "Firmenname",
    businessUsername: "Geschäftlicher Benutzername",
    contactNumber: "Kontakt Nummer",
    deliveryCharges: "Versandkosten",
    minOrderAmount: "Mindestbestellwert",
    colorScheme: "Farbschema",
    primaryColor: "Primärfarbe",
    secondaryColor: "Sekundäre Farbe",
    businessDescription: "Geschäftsbeschreibung",
    coverImages: "Titelbilder",
    updateCoverImages: "Titelbilder aktualisieren",
    updateLogoImages: "Logo-Bilder aktualisieren",
    logoImage: "Logo-Bild",
    businessLocation: "Geschäftsstandort",
    locations: "Standorte",
    addLocation: "Ort hinzufügen",
    locationTitle: "Standorttitel",
    locationDeliveryRadius: "Standort Lieferradius",
    operatingTimes: "Betriebszeiten",
    startTime: "Startzeit",
    endTime: "Endzeit",
    addAnotherSlot: "Einen weiteren Slot hinzufügen",
    setForAllDays: "Set für alle Tage",
    updateDetails: "Details aktualisieren",
    updateAreas: "Bereiche aktualisieren",
    AreasManager: "Areas Managment",
  },
  SideBar: {
    home: "Hauptseite",
    orders: "Aufträge",
    orderHistory: "Bestellverlauf",
    menu: "Speisekarte",
    statistics: "Statistiken",
    settings: "Einstellungen",
    logout: "Ausloggen",
    pos: "POS",
  },
  days: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
  },
  pos: {
    searchCategory: "Kategorie suchen",
    searchOpenOrder: "Offene Bestellung suchen",
    searchItems: "Artikel suchen",
    stayOpen: "Offen bleiben",
    takeAway: "Wegbringen",
    generatingOrder: "Ordnung erzeugen...",
    dineIn: "Speisen in",
    itemName: "Artikelname",
    quantity: "Menge",
    price: "Preis",
    subtotal: "Zwischensumme",
    orderTotal: "Auftragssumme",
    backToAdmin: "Zurück zum Administrator",
    logout: "Ausloggen",
    all: "alle",
    homeDelivery: "Hauslieferung",
  },
  editItemModal: {
    price: "Stückpreis",
    discountedPrice: "Herabgesetzter Preis",
    discountPos: "Rabatt auf POS",
    discountOnline: "Rabatt auf Online",
    startDate: "Startdatum",
    isVisble: "istSichtbar",
  },
  tax: {
    tax: "Steuer",
    taxes: "Steuern",
    id: "Id",
    name: "Name",
    rate: "Satz %",
    actions: "Aktionen",
  },
};
