import axios from "axios";
import { serverUrl } from "../../config";
import { GET_TAXES, ADD_TAXES, EDIT_TAXES, DELETE_TAXES } from "../constants";

export const getTax = () => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  await axios({
    url: "api/vendor/taxes",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
  })
    .then((res) => {
      dispatch({
        type: GET_TAXES,
        payload: res.data.payload,
      });
      console.log(res.data);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const CreateTax = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  return await axios({
    url: "api/vendor/tax",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      dispatch({
        type: ADD_TAXES,
        payload: res.data.payload,
      });
      // console.log(res.data);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err);
      throw err.response;
    });
};

export const editTaxes = (data) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");

  console.warn(token);
  return await axios({
    url: "api/vendor/tax",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: data },
  })
    .then((res) => {
      dispatch({
        type: EDIT_TAXES,
        payload: data,
      });
      // console.log(res);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err);
      throw err.response;
    });
};

export const deleteTaxes = (id) => async (dispatch) => {
  const token = window.localStorage.getItem("access_token");
  console.warn(token);
  await axios({
    url: "api/vendor/tax",
    method: "DELETE",
    baseURL: serverUrl,
    headers: { "x-auth-token": token },
    data: { data: id },
  })
    .then((res) => {
      dispatch({
        type: DELETE_TAXES,
        payload: res.data.payload,
      });
      // console.log(res.data);

      return res;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err);
      throw err.response;
    });
};
