import { SET_BUSINESS_CONFIG, SET_CURRENCY } from "../constants";

const initialState = {
  businessConfig: {},
  currencyData: {},
};

const businessConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS_CONFIG: {
      return {
        ...state,
        businessConfig: action.payload,
      };
    }
    case SET_CURRENCY: {
      return {
        ...state,
        currencyData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default businessConfigReducer;
