import React, { useEffect, useState } from "react";
import { Button, Modal as BootstrapModal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import { getItemPrices } from "../helperFunctions/DataHandlers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

function EditItemPirceModal({
  showModal,
  ModalHandler,
  loader,
  data,
  itemCostHandler,
  itemCostDiscountHandler,
  discOnPOSHandler,
  discOnOnlineHandler,
  startDateHandler,
  onSubmitHandler,
  itemId,
  itemName,
  itemPrices,
  setItemPrices,
  priceDateError,
  setPriceDateError,
  addDiscount,
  setAddDiscount,
}) {
  const { t } = useTranslation();
  // const [itemPrices, setItemPrices] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [inputDate, setInputDate] = useState("");
  const newDate = new Date();
  const dateToRestrict = newDate.toISOString().split("T");

  // ------------ Handle Input Date Change -----------
  const handleStartDate = (e) => {
    if (new Date(e.target.value) >= new Date(dateToRestrict[0])) {
      startDateHandler(e.target.value);
      setInputDate(e.target.value);
    }
  };

  // ------------ Columns For Material Table -----------
  const columns = [
    { title: t("menu:price"), field: "price", editable: false },
    {
      title: t("menu:discount"),
      field: "discounted_price",
      editable: false,
    },
    {
      title: t("menu:pos"),
      field: "discount_pos",
      editable: false,
      render: (rowData) => <Checkbox disabled checked={rowData.discount_pos} />,
    },
    {
      title: t("menu:online"),
      field: "discount_online",
      editable: false,
      render: (rowData) => (
        <Checkbox disabled checked={rowData.discount_online} />
      ),
    },
    {
      title: t("menu:StartDate"),
      field: "start_date",
      editable: false,
      render: (rowData) => <div>{rowData.start_date.split("T")[0]}</div>,
    },
  ];

  // ------------ Submit Edit Price -----------------
  const validateAndSubmit = () => {
    if (data?.itemCost === 0) {
      alert("Item cost should be greater than 0");
    } else if (addDiscount && data?.itemDiscountedCost === 0) {
      alert("Discount cost should be greater than 0");
    } else if (addDiscount) {
      if (!data?.discountOnOnline && !data?.discountOnPOS) {
        alert("kindly select atleast one checkbox");
      } else if (data?.itemDiscountedCost > data?.itemCost) {
        alert("Discount cost should be less than item cost");
      } else {
        if (!loader) {
          onSubmitHandler();
        }
      }
    } else {
      if (!loader) {
        onSubmitHandler();
      }
    }
  };

  // -------- Get Previous Item Prices From DB ---------
  useEffect(() => {
    getItemPrices(itemId)
      .then((res) => {
        // console.log("Res on fetching item prices: ", res);
        setItemPrices(res?.data?.payload);
      })
      .catch((error) => {
        let err =
          error?.message && error?.message.split(" ").slice(0, 2).join(" ");

        if (err && err === "connect ECONNREFUSED") {
          alert.show("Server is down please contact your server provider!", {
            type: "error",
          });
        } else {
          alert.show("Something went wrong", {
            type: "error",
          });
        }
      });
  }, []);

  useEffect(() => {
    setInputDate(dateToRestrict[0]);
    startDateHandler(dateToRestrict[0]);
  }, []);

  // ------ Dismiss Alert After some time ---------
  useEffect(() => {
    if (priceDateError) {
      setTimeout(() => {
        setPriceDateError(false);
      }, 4000);
    }
  }, [priceDateError]);

  return (
    <BootstrapModal show={showModal} onHide={ModalHandler} keyboard={false}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title
          style={{
            width: "92%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {itemName}
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {priceDateError ? (
          <>
            <Alert
              severity="error"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 111,
                marginLeft: 8,
                backgroundColor: "#FDEDED",
              }}
            >
              Item Price on Defined Date is already Exist
            </Alert>
          </>
        ) : null}
        <Form autoComplete="off">
          <Form.Group className="mb-3">
            <Form.Label>{t("editItemModal:price")}</Form.Label>
            <Form.Control
              type="number"
              value={data?.itemCost || ""}
              onWheel={(e) => e.target.blur()}
              min={1}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  itemCostHandler(0);
                } else if (Number(e.target.value) >= 1) {
                  itemCostHandler(Number(e.target.value));
                }
              }}
            />
          </Form.Group>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={addDiscount}
                  onChange={(e) => {
                    setAddDiscount(e.target.checked);
                  }}
                />
              }
              label="Add Discount"
            />
          </FormGroup>
          {addDiscount ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>{t("editItemModal:discountedPrice")}</Form.Label>
                <Form.Control
                  type="number"
                  value={data?.itemDiscountedCost || ""}
                  onWheel={(e) => e.target.blur()}
                  min={1}
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      itemCostDiscountHandler(0);
                    } else if (Number(e.target.value) >= 1) {
                      itemCostDiscountHandler(Number(e.target.value));
                    }
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label={t("editItemModal:discountPos")}
                  checked={data?.discountOnPOS || false}
                  onChange={() => discOnPOSHandler(!data?.discountOnPOS)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label={t("editItemModal:discountOnline")}
                  checked={data?.discountOnOnline || false}
                  onChange={() => discOnOnlineHandler(!data?.discountOnOnline)}
                />
              </Form.Group>
            </>
          ) : null}
          <Form.Group className="mb-3 d-flex flex-column">
            <Form.Label>{t("editItemModal:startDate")}</Form.Label>
            <input
              type="date"
              style={{ borderColoe: "grey", padding: 10 }}
              value={inputDate}
              onChange={handleStartDate}
              min={dateToRestrict[0]}
            />
          </Form.Group>
          <div
            className="toggle-table-display"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              displayTable ? setDisplayTable(false) : setDisplayTable(true)
            }
          >
            <span className="h5">Show Price Details</span>
            <span className="">
              {!displayTable ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </span>
          </div>
          {displayTable ? (
            <div
              className="price-history"
              style={{ width: "100%", overflow: "auto" }}
            >
              <MaterialTable
                className="Price-table"
                title={t(" ")}
                data={itemPrices}
                columns={columns}
                options={{
                  search: false,
                  selection: false,
                  sorting: true,
                  paging: false,
                  tableLayout: "auto",
                  headerStyle: {
                    fontSize: "small",
                  },
                }}
              />
            </div>
          ) : (
            ""
          )}

          <Button
            className="btn-lg btn-block mt-4"
            variant="primary"
            type="submit"
            style={{
              backgroundColor: "#A2191B",
              borderColor: "#A2191B",
              width: "100%",
            }}
            onClick={(e) => {
              e.preventDefault();
              validateAndSubmit();
            }}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Edit Item Price"
            )}
          </Button>
        </Form>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}

export default EditItemPirceModal;
