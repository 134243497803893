import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { serverUrl } from "../../config";
import {
  GET_ALL_ORDERS,
  GET_ORDER_HISTORY,
  EDIT_STATUS,
  SET_ORDER_STATUS,
  GET_ORDER_BY_ID,
} from "../constants";

export const getAllOrders = (data) => async (dispatch) => {
  // console.log('token:', localStorage.getItem('access_token'));
  return axios({
    url: "/api/vendor/menu/orders",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
  })
    .then((res) => {
      // console.log('orders response: ', res);
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const getOrderById = (id) => async (dispatch) => {
  // console.log('token:', localStorage.getItem('access_token'));
  return axios({
    url: `/api/user/menu/order?id=${id}`,
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
  })
    .then((res) => {
      console.log("orders response 44: ", res);
      dispatch({
        type: GET_ORDER_BY_ID,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const getOrderHistory = (data) => async (dispatch) => {
  // console.log('token:', localStorage.getItem('access_token'));
  return axios({
    url: "/api/vendor/menu/orders",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
  })
    .then((res) => {
      // console.log('history response: ', res);
      dispatch({
        type: GET_ORDER_HISTORY,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const updateStatus = (data) => async (dispatch) => {
  return axios({
    url: "/api/vendor/menu/orderStatus",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
    data: { data: { ...data, order_status: data.order_status.id } },
  })
    .then((res) => {
      // console.log('data', data)
      // console.log('orders response: ', res);
      dispatch({
        type: EDIT_STATUS,
        payload: data,
      });
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const createOrder = (data) => async (dispatch) => {
  return axios({
    url: "/api/user/menu/createOrder",
    method: "POST",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
    data: { data: data },
  })
    .then((res) => {
      // console.log('data', data)
      // console.log('Create Orders Response: ', res);
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const editOpenOrder = (data) => async (dispatch) => {
  return axios({
    url: "/api/vendor/menu/order",
    method: "PUT",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
    data: { data: data },
  })
    .then((res) => {
      // console.log('data', data)
      // console.log('Edit Open Order: ', res);
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const getStatusCodes = () => async (dispatch) => {
  return axios({
    url: "/api/vendor/menu/orderStatus",
    method: "GET",
    baseURL: serverUrl,
    headers: { "x-auth-token": localStorage.getItem("access_token") },
  })
    .then((res) => {
      // console.log('orders status response: ', res);
      dispatch({
        type: SET_ORDER_STATUS,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const updateOrder = (query, data) => async (dispatch, getState) => {
  let orderStatuses = getState().ordersReducer.orderStatus;

  if (data.order_status > 0) {
    if (orderStatuses && orderStatuses.length > 0) {
      for (let i = 0; i < orderStatuses.length; i++) {
        if (orderStatuses[i].id === data.order_status) {
          data.order_status = orderStatuses[i].name;
        }
      }
    } else {
      return;
    }
  }

  const ordersList = JSON.parse(
    JSON.stringify(getState().ordersReducer.ordersList)
  );
  let queryEntries = Object.entries(query);
  for (let i = 0; i < ordersList.length; i++) {
    let valueMatched = 0;
    for (let j = 0; j < queryEntries.length; j++) {
      if (ordersList[i][queryEntries[j][0]] === queryEntries[j][1]) {
        valueMatched++;
      }
    }
    if (queryEntries.length === valueMatched) {
      console.warn(ordersList[i]);
      ordersList[i] = { ...ordersList[i], ...data };
      console.warn(ordersList[i]);
    }
  }

  dispatch({
    type: GET_ALL_ORDERS,
    payload: ordersList,
  });
};
