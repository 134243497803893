import { SET_MENU, SET_QUESTION_TYPE, } from "../constants";

const initialState = {
    questionOptions:[]
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTION_TYPE: {
      return {
        ...state,
        questionOptions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default configReducer;
