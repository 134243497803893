import { GET_TAXES, ADD_TAXES, EDIT_TAXES, DELETE_TAXES } from "../constants";

const initialState = {
  tax: [],
};

const taxReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAXES: {
      // console.log("Set Taxes: ", action.payload);
      return {
        ...state,
        tax: action.payload,
      };
    }
    case ADD_TAXES: {
      // console.log("payload ---- updated: ", [...state.tax, action.payload]);
      return {
        ...state,
        tax: [...state.tax, action.payload],
      };
    }
    case EDIT_TAXES: {
      // console.log("Action Payload : ", action.payload);
      //   console.log("State: ----!!! ", { ...state });
      const updatedArray = state.tax.map((taxes) =>
        taxes.id === action.payload.id
          ? {
              ...action.payload,
              business_id: taxes.business_id,
            }
          : taxes
      );
      // console.log("Updated Array on reducer: ", updatedArray);

      return {
        ...state,
        tax: [...updatedArray],
      };
    }
    case DELETE_TAXES: {
      return {
        ...state,
        tax: state.tax.filter((tax) => tax.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

export default taxReducer;
