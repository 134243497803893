import React, { useState } from 'react'


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Qty Per Item Sale',
        },
    },
};

const labels = ['Pizza', 'Burger', 'Biryani', "karhai", "Soup", 'Tikka', 'Chinese Rice', 'Club Sandwhich', 'pulao'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Qty Per Item Sale',
            data: [110, 23, 56, 34, 65, 84, 23, 122, 60],
            backgroundColor: '#A2191B',
        }
    ],
};

function SalesByItemsChart() {
    return (
        <div>
            <Bar options={options} data={data} />
        </div>)
}

export default SalesByItemsChart



