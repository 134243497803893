import { serverUrl } from '../config';

const { io } = require('socket.io-client');

const socket = io(serverUrl, {
  cors: {
    origin: 'https://admin.synevry.bitrupt.co',
    credentials: true,
  },
  transports: ['websocket'],
});

export default socket;
