import axios from "axios";
import { serverUrl } from "../../config";
import { SET_AREAS } from "../constants";

export const fetchAreas = (data) => async (dispatch) => {
  return await axios({
    url: "api/user/menu/areas?restaurant=" + data,
    method: "GET",
    baseURL: serverUrl,
  })
    .then((res) => {
      dispatch({
        type: SET_AREAS,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setAreas = (data, payload) => async (dispatch) => {
  return await axios({
    url: "api/user/menu/areas?restaurant=" + data,
    method: "PUT",
    baseURL: serverUrl,
    data: { data: payload },
  })
    .then((res) => {
      dispatch({
        type: SET_AREAS,
        payload: payload,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAreasAll = (data) => async (dispatch) => {
  return await axios({
    url: "api/user/menu/areas-all?restaurant=" + data,
    method: "GET",
    baseURL: serverUrl,
  })
    .then((res) => {
      dispatch({
        type: SET_AREAS,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
