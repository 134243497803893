import { Add } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import "./../App.css";

function AddMenuItem(props) {
  return (
    <div style={{ marginTop: 10, marginLeft: 24 }}>
      <div
        onClick={() => {
          if (typeof props.onClick === "function") props.onClick();
        }}
        className="noselect"
        style={{
          backgroundColor: "white",
          width: 300,
          minHeight: 300,
          borderRadius: 15,
          boxShadow: "0px 0px 27px 11px rgba(0,0,0,0.04)",
          marginBottom: 60,
          marginleft: 5,
          marginRight: 5,
          fontSize: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          flexDirection: "column",
        }}
      >
        <Add sx={{ color: "#A2191B" }} fontSize="inherit" />
        <Typography style={{ fontSize: 21, fontWeight: "500" }}>
          Add New Item
        </Typography>
      </div>
    </div>
  );
}

export default AddMenuItem;
