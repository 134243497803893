import React from 'react'
import { Button } from '@mui/material';
import { CircularProgress } from '@material-ui/core';

const CustomButton = (props) => {

  const { disabled, onClick, loading, value, style } = props;
  return (
    <Button variant="contained"
      fullWidth
      onClick={onClick}
      disabled={disabled}
      style={{
        backgroundColor: disabled ? 'rgba(37, 36, 34,0.6)' : '#252422',
        color: disabled ? 'rgba(255,255,255,0.6)' : 'white',
        padding: 8,
        borderRadius: 10, height: 45,
        ...style
      }}
    >
      {loading ? <CircularProgress size={15} color="white" /> : value}

    </Button>
  )
}

export default CustomButton