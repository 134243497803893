import axios from "axios";
import { serverUrl } from "../../config";
import { SET_TOKEN, SET_USER } from "../constants";

export const login = (data) => async (dispatch) => {
  return axios({
    url: "api/vendor/auth/login",
    method: "POST",
    baseURL: serverUrl,
    data,
  })
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data.payload,
      });

      window.localStorage.setItem("access_token", res.headers["x-auth-token"]);
      dispatch({
        type: SET_TOKEN,
        payload: res.headers["x-auth-token"],
      });

      return res;
    })
    .catch((err) => {
      console.log("error:", err);
      console.log("error:", err.response);
      throw err.response;
    });
};

export const signup = (data) => async (dispatch) => {
  await axios({
    url: "api/vendor/auth/signup",
    method: "POST",
    data,
  })
    .then((res) => {
      // console.log('Signup response: ', res);
      // dispatch({
      //   type: CHANGE_EMAIL,
      //   payload: data.email,
      // });
      // return res.data;
    })
    .catch((err) => {
      // console.log('error:', err);
      console.log("error:", err.response);
      throw err.response;
    });
};
