import React from 'react'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['Chinese', 'Italian', 'Rissian', 'American', 'Indian', 'Pakistani'],
    datasets: [
        {
            label: '# of Category',
            data: [23, 32, 56, 76, 43, 43],
            backgroundColor: [
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(255, 206, 86)',
                'rgba(75, 192, 192)',
                'rgba(153, 102, 255)',
                '#A2191B',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgb(168,2,4, 1)',
            ],
            borderWidth: 2,
        },
    ],
};
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Qty Per Item Sale',
        },
    },
};



function SalesByCategory() {
    return <Pie data={data} options={options} />;
}

export default SalesByCategory


