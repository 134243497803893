import { SET_AREAS } from '../constants';

const initialState = {
  areas: [],
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };

    default:
      return state;
  }
};

export default addressReducer;
